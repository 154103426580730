.card-Staff {
    border: 1px solid #ddd;
    border-radius: 8px;
}

.card-body-Staff img {
    width: 100%;
    height: 193px;
    object-fit: cover;
}

.card-Staff-Staff {
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 300px;
}

.custom-file-input {
    display: none;
    /* Hide the default input */
}

.customs-file {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.custom-file:hover {
    background-color: #0056b3;
    /* Darken the button on hover */
}