.article-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-container {
    position: relative;
}

.image-container img {
    width: 100%;
    height: auto;
}

.image-caption {
    font-size: 14px;
    color: #666;
    position: absolute;
    bottom: 5px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 5px;
}

.article-content {
    padding: 20px;
}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.publish-date {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    margin-bottom: 15px;
    color: #333;
}