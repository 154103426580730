.tab-button.active {
    background-color: #007bff;
    color: white;
}

.tab-button {
    padding: 10px;
    margin-right: 5px;
    cursor: pointer;
}

.tab-content {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
}

.tabs-blog {
    width: 600px;
    margin: auto;
}

.card-blog {
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}

.card-body {
    padding: 20px;
}

.card-text {
    color: #6c757d;
}

.read-more {
    color: #dc3545;
    font-weight: bold;
}

.read-more:hover {
    text-decoration: underline;
}