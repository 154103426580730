@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff
}

[tabindex="-1"]:focus {
  outline: 0 !important
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0
}

dt {
  font-weight: 700
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

blockquote {
  margin: 0 0 1rem
}

b,
strong {
  font-weight: bolder
}

small {
  font-size: 80%
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent
}

a:hover {
  color: #0056b3;
  text-decoration: underline
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none
}

a:not([href]):not([tabindex]):focus {
  outline: 0
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto
}

figure {
  margin: 0 0 1rem
}

img {
  vertical-align: middle;
  border-style: none
}

svg {
  overflow: hidden;
  vertical-align: middle
}

table {
  border-collapse: collapse
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom
}

th {
  text-align: inherit
}

label {
  display: inline-block;
  margin-bottom: .5rem
}

button {
  border-radius: 0
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

select {
  word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
  -webkit-appearance: listbox
}

textarea {
  overflow: auto;
  resize: vertical
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal
}

progress {
  vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

output {
  display: inline-block
}

summary {
  display: list-item;
  cursor: pointer
}

template {
  display: none
}

[hidden] {
  display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2
}

.h1,
h1 {
  font-size: 2.5rem
}

.h2,
h2 {
  font-size: 2rem
}

.h3,
h3 {
  font-size: 1.75rem
}

.h4,
h4 {
  font-size: 1.5rem
}

.h5,
h5 {
  font-size: 1.25rem
}

.h6,
h6 {
  font-size: 1rem
}

.lead {
  font-size: 1.25rem;
  font-weight: 300
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1)
}

.small,
small {
  font-size: 80%;
  font-weight: 400
}

.mark,
mark {
  padding: .2em;
  background-color: #fcf8e3
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none
}

.list-inline-item {
  display: inline-block
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem
}

.initialism {
  font-size: 90%;
  text-transform: uppercase
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d
}

.blockquote-footer::before {
  content: "\2014\00A0"
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto
}

.figure {
  display: inline-block
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1
}

.figure-caption {
  font-size: 90%;
  color: #6c757d
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word
}

a>code {
  color: inherit
}

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.no-gutters {
  margin-right: 0;
  margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.order-first {
  -ms-flex-order: -1;
  order: -1
}

.order-last {
  -ms-flex-order: 13;
  order: 13
}

.order-0 {
  -ms-flex-order: 0;
  order: 0
}

.order-1 {
  -ms-flex-order: 1;
  order: 1
}

.order-2 {
  -ms-flex-order: 2;
  order: 2
}

.order-3 {
  -ms-flex-order: 3;
  order: 3
}

.order-4 {
  -ms-flex-order: 4;
  order: 4
}

.order-5 {
  -ms-flex-order: 5;
  order: 5
}

.order-6 {
  -ms-flex-order: 6;
  order: 6
}

.order-7 {
  -ms-flex-order: 7;
  order: 7
}

.order-8 {
  -ms-flex-order: 8;
  order: 8
}

.order-9 {
  -ms-flex-order: 9;
  order: 9
}

.order-10 {
  -ms-flex-order: 10;
  order: 10
}

.order-11 {
  -ms-flex-order: 11;
  order: 11
}

.order-12 {
  -ms-flex-order: 12;
  order: 12
}

.offset-1 {
  margin-left: 8.333333%
}

.offset-2 {
  margin-left: 16.666667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.333333%
}

.offset-5 {
  margin-left: 41.666667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.333333%
}

.offset-8 {
  margin-left: 66.666667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.333333%
}

.offset-11 {
  margin-left: 91.666667%
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-1 {
    margin-left: 8.333333%
  }

  .offset-sm-2 {
    margin-left: 16.666667%
  }

  .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-4 {
    margin-left: 33.333333%
  }

  .offset-sm-5 {
    margin-left: 41.666667%
  }

  .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-7 {
    margin-left: 58.333333%
  }

  .offset-sm-8 {
    margin-left: 66.666667%
  }

  .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-10 {
    margin-left: 83.333333%
  }

  .offset-sm-11 {
    margin-left: 91.666667%
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.333333%
  }

  .offset-md-2 {
    margin-left: 16.666667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.333333%
  }

  .offset-md-5 {
    margin-left: 41.666667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.333333%
  }

  .offset-md-8 {
    margin-left: 66.666667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.333333%
  }

  .offset-md-11 {
    margin-left: 91.666667%
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.333333%
  }

  .offset-lg-2 {
    margin-left: 16.666667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.333333%
  }

  .offset-lg-5 {
    margin-left: 41.666667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.333333%
  }

  .offset-lg-8 {
    margin-left: 66.666667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.333333%
  }

  .offset-lg-11 {
    margin-left: 91.666667%
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-1 {
    margin-left: 8.333333%
  }

  .offset-xl-2 {
    margin-left: 16.666667%
  }

  .offset-xl-3 {
    margin-left: 25%
  }

  .offset-xl-4 {
    margin-left: 33.333333%
  }

  .offset-xl-5 {
    margin-left: 41.666667%
  }

  .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-7 {
    margin-left: 58.333333%
  }

  .offset-xl-8 {
    margin-left: 66.666667%
  }

  .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-10 {
    margin-left: 83.333333%
  }

  .offset-xl-11 {
    margin-left: 91.666667%
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6
}

.table-sm td,
.table-sm th {
  padding: .3rem
}

.table-bordered {
  border: 1px solid #dee2e6
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05)
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075)
}

.table-primary,
.table-primary>td,
.table-primary>th {
  background-color: #b8daff
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff
}

.table-hover .table-primary:hover {
  background-color: #9fcdff
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #9fcdff
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
  background-color: #d6d8db
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf
}

.table-success,
.table-success>td,
.table-success>th {
  background-color: #c3e6cb
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e
}

.table-hover .table-success:hover {
  background-color: #b1dfbb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb
}

.table-info,
.table-info>td,
.table-info>th {
  background-color: #bee5eb
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda
}

.table-hover .table-info:hover {
  background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5
}

.table-warning,
.table-warning>td,
.table-warning>th {
  background-color: #ffeeba
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1
}

.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: #f5c6cb
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7
}

.table-light,
.table-light>td,
.table-light>th {
  background-color: #fdfdfe
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc
}

.table-hover .table-light:hover {
  background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6
}

.table-dark,
.table-dark>td,
.table-dark>th {
  background-color: #c6c8ca
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe
}

.table-active,
.table-active>td,
.table-active>th {
  background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075)
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.table-dark {
  color: #fff;
  background-color: #343a40
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55
}

.table-dark.table-bordered {
  border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05)
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075)
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-sm>.table-bordered {
    border: 0
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-md>.table-bordered {
    border: 0
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-lg>.table-bordered {
    border: 0
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-xl>.table-bordered {
    border: 0
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
  border: 0
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: .375rem;
  padding-bottom: .375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

select.form-control[multiple],
select.form-control[size] {
  height: auto
}

textarea.form-control {
  height: auto
}

.form-group {
  margin-bottom: 1rem
}

.form-text {
  display: block;
  margin-top: .25rem
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
  color: #6c757d
}

.form-check-label {
  margin-bottom: 0
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: .25rem
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
  display: block
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc((1em + .75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem)
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip {
  display: block
}

.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
  display: block
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #28a745
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
  display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
  color: #28a745
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
  border-color: #28a745
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
  display: block
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #28a745
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
  border-color: #28a745
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
  display: block
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: .25rem
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}

.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
  display: block
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc((1em + .75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem)
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip {
  display: block
}

.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
  display: block
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
  color: #dc3545
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
  display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
  color: #dc3545
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
  border-color: #dc3545
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
  display: block
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: #dc3545
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
  border-color: #dc3545
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
  display: block
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center
}

.form-inline .form-check {
  width: 100%
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0
  }

  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle
  }

  .form-inline .form-control-plaintext {
    display: inline-block
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0
  }

  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .form-inline .custom-control-label {
    margin-bottom: 0
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.btn.disabled,
.btn:disabled {
  opacity: .65
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5)
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5)
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5)
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5)
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5)
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5)
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5)
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5)
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5)
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5)
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5)
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5)
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5)
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5)
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5)
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5)
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5)
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5)
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5)
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block+.btn-block {
  margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%
}

.fade {
  transition: opacity .15s linear
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}

.collapse:not(.show) {
  display: none
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0px;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropright .dropdown-toggle::after {
  vertical-align: 0
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""
}

.dropleft .dropdown-toggle::after {
  display: none
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent
}

.dropdown-menu.show {
  display: block
}

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap
}

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
  z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
  z-index: 1
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.btn-toolbar .input-group {
  width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
  margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
  margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
  margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
  z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.input-group>.custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
  margin-left: -1px
}

.input-group-prepend {
  margin-right: -1px
}

.input-group-append {
  margin-left: -1px
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
  margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
  height: calc(1.5em + .5rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 1.75rem
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #80bdff
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff
}

.custom-control-input:disabled~.custom-control-label {
  color: #6c757d
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px
}

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5)
}

.custom-radio .custom-control-label::before {
  border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5)
}

.custom-switch {
  padding-left: 2.25rem
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem
}

.custom-switch .custom-control-label::after {
  top: calc(.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5)
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef
}

.custom-select::-ms-expand {
  display: none
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  opacity: 0
}

.custom-file-input:focus~.custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.custom-file-input:disabled~.custom-file-label {
  background-color: #e9ecef
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse)
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0
}

.custom-range {
  width: 100%;
  height: calc(1rem + .4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.custom-range:focus {
  outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.custom-range::-moz-focus-outer {
  border: 0
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -moz-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .2rem;
  margin-left: .2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd
}

.custom-range:disabled::-moz-range-track {
  cursor: default
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.nav-link {
  display: block;
  padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
  margin-bottom: -1px
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav-pills .nav-link {
  border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center
}

.tab-content>.tab-pane {
  display: none
}

.tab-content>.active {
  display: block
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem
}

.navbar>.container,
.navbar>.container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%
}

@media (max-width: 575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-sm .navbar-toggler {
    display: none
  }
}

@media (max-width: 767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-md .navbar-toggler {
    display: none
  }
}

@media (max-width: 991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-lg .navbar-toggler {
    display: none
  }
}

@media (max-width: 1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-xl .navbar-toggler {
    display: none
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  padding-right: 0;
  padding-left: 0
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto
}

.navbar-expand .navbar-toggler {
  display: none
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7)
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1)
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9)
}

.navbar-dark .navbar-brand {
  color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #fff
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1)
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-text a {
  color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem
}

.card>hr {
  margin-right: 0;
  margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem
}

.card-title {
  margin-bottom: .75rem
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0
}

.card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link+.card-link {
  margin-left: 1.25rem
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125)
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125)
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0
}

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem
}

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px)
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.card-deck .card {
  margin-bottom: 15px
}

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px
  }

  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.card-group>.card {
  margin-bottom: 15px
}

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
  }

  .card-group>.card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-header,
  .card-group>.card:not(:last-child) .card-img-top {
    border-top-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-footer,
  .card-group>.card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-header,
  .card-group>.card:not(:first-child) .card-img-top {
    border-top-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-footer,
  .card-group>.card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0
  }
}

.card-columns .card {
  margin-bottom: .75rem
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1
  }

  .card-columns .card {
    display: inline-block;
    width: 100%
  }
}

.accordion>.card {
  overflow: hidden
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
  border-radius: 0
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0
}

.accordion>.card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.accordion>.card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.accordion>.card .card-header {
  margin-bottom: -1px
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none
}

.breadcrumb-item.active {
  color: #6c757d
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem
}

.badge-primary {
  color: #fff;
  background-color: #007bff
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5)
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5)
}

.badge-success {
  color: #fff;
  background-color: #28a745
}

a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5)
}

.badge-info {
  color: #fff;
  background-color: #17a2b8
}

a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5)
}

.badge-warning {
  color: #212529;
  background-color: #ffc107
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5)
}

.badge-danger {
  color: #fff;
  background-color: #dc3545
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5)
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa
}

a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5)
}

.badge-dark {
  color: #fff;
  background-color: #343a40
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5)
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem
}

.alert-heading {
  color: inherit
}

.alert-link {
  font-weight: 700
}

.alert-dismissible {
  padding-right: 4rem
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff
}

.alert-primary hr {
  border-top-color: #9fcdff
}

.alert-primary .alert-link {
  color: #002752
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db
}

.alert-secondary hr {
  border-top-color: #c8cbcf
}

.alert-secondary .alert-link {
  color: #202326
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb
}

.alert-success hr {
  border-top-color: #b1dfbb
}

.alert-success .alert-link {
  color: #0b2e13
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb
}

.alert-info hr {
  border-top-color: #abdde5
}

.alert-info .alert-link {
  color: #062c33
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba
}

.alert-warning hr {
  border-top-color: #ffe8a1
}

.alert-warning .alert-link {
  color: #533f03
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb
}

.alert-danger hr {
  border-top-color: #f1b0b7
}

.alert-danger .alert-link {
  color: #491217
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe
}

.alert-light hr {
  border-top-color: #ececf6
}

.alert-light .alert-link {
  color: #686868
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca
}

.alert-dark hr {
  border-top-color: #b9bbbe
}

.alert-dark .alert-link {
  color: #040505
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0
  }

  to {
    background-position: 0 0
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0
  }

  to {
    background-position: 0 0
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width .6s ease
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start
}

.media-body {
  -ms-flex: 1;
  flex: 1
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125)
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: 0
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5
}

.close:hover {
  color: #000;
  text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: .75
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

a.close.disabled {
  pointer-events: none
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: .875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: .25rem
}

.toast:not(:last-child) {
  margin-bottom: .75rem
}

.toast.showing {
  opacity: 1
}

.toast.show {
  display: block;
  opacity: 1
}

.toast.hide {
  display: none
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.toast-body {
  padding: .75rem
}

.modal-open {
  overflow: hidden
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px)
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .5
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem
}

.modal-footer>:not(:first-child) {
  margin-left: .25rem
}

.modal-footer>:not(:last-child) {
  margin-right: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem)
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem)
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem)
  }

  .modal-sm {
    max-width: 300px
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0
}

.tooltip.show {
  opacity: .9
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
  margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
  bottom: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=top]>.arrow::before,
.bs-popover-top>.arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25)
}

.bs-popover-auto[x-placement^=top]>.arrow::after,
.bs-popover-top>.arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
  margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,
.bs-popover-right>.arrow {
  left: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow::before,
.bs-popover-right>.arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25)
}

.bs-popover-auto[x-placement^=right]>.arrow::after,
.bs-popover-right>.arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
  margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
  top: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::before,
.bs-popover-bottom>.arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-bottom>.arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
  margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,
.bs-popover-left>.arrow {
  right: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow::before,
.bs-popover-left>.arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, 0.25)
}

.bs-popover-auto[x-placement^=left]>.arrow::after,
.bs-popover-left>.arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
  display: none
}

.popover-body {
  padding: .5rem .75rem;
  color: #212529
}

.carousel {
  position: relative
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%)
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s .6s opacity
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  transition: opacity .15s ease
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    transition: none
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9
}

.carousel-control-prev {
  left: 0
}

.carousel-control-next {
  right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  50% {
    opacity: 1
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  50% {
    opacity: 1
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.bg-primary {
  background-color: #007bff !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important
}

.bg-secondary {
  background-color: #6c757d !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important
}

.bg-success {
  background-color: #28a745 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important
}

.bg-info {
  background-color: #17a2b8 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important
}

.bg-warning {
  background-color: #ffc107 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important
}

.bg-danger {
  background-color: #dc3545 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important
}

.bg-light {
  background-color: #f8f9fa !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important
}

.bg-dark {
  background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important
}

.bg-white {
  background-color: #fff !important
}

.bg-transparent {
  background-color: transparent !important
}

.border {
  border: 1px solid #dee2e6 !important
}

.border-top {
  border-top: 1px solid #dee2e6 !important
}

.border-right {
  border-right: 1px solid #dee2e6 !important
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important
}

.border-left {
  border-left: 1px solid #dee2e6 !important
}

.border-0 {
  border: 0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #007bff !important
}

.border-secondary {
  border-color: #6c757d !important
}

.border-success {
  border-color: #28a745 !important
}

.border-info {
  border-color: #17a2b8 !important
}

.border-warning {
  border-color: #ffc107 !important
}

.border-danger {
  border-color: #dc3545 !important
}

.border-light {
  border-color: #f8f9fa !important
}

.border-dark {
  border-color: #343a40 !important
}

.border-white {
  border-color: #fff !important
}

.rounded-sm {
  border-radius: 0.2rem !important
}

.rounded {
  border-radius: 0.25rem !important
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important
}

.rounded-lg {
  border-radius: 0.3rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-pill {
  border-radius: 50rem !important
}

.rounded-0 {
  border-radius: 0 !important
}

.clearfix::after {
  display: block;
  clear: both;
  content: ""
}

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important
  }

  .d-sm-inline {
    display: inline !important
  }

  .d-sm-inline-block {
    display: inline-block !important
  }

  .d-sm-block {
    display: block !important
  }

  .d-sm-table {
    display: table !important
  }

  .d-sm-table-row {
    display: table-row !important
  }

  .d-sm-table-cell {
    display: table-cell !important
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important
  }

  .d-md-inline {
    display: inline !important
  }

  .d-md-inline-block {
    display: inline-block !important
  }

  .d-md-block {
    display: block !important
  }

  .d-md-table {
    display: table !important
  }

  .d-md-table-row {
    display: table-row !important
  }

  .d-md-table-cell {
    display: table-cell !important
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important
  }

  .d-lg-inline {
    display: inline !important
  }

  .d-lg-inline-block {
    display: inline-block !important
  }

  .d-lg-block {
    display: block !important
  }

  .d-lg-table {
    display: table !important
  }

  .d-lg-table-row {
    display: table-row !important
  }

  .d-lg-table-cell {
    display: table-cell !important
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important
  }

  .d-xl-inline {
    display: inline !important
  }

  .d-xl-inline-block {
    display: inline-block !important
  }

  .d-xl-block {
    display: block !important
  }

  .d-xl-table {
    display: table !important
  }

  .d-xl-table-row {
    display: table-row !important
  }

  .d-xl-table-cell {
    display: table-cell !important
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media print {
  .d-print-none {
    display: none !important
  }

  .d-print-inline {
    display: inline !important
  }

  .d-print-inline-block {
    display: inline-block !important
  }

  .d-print-block {
    display: block !important
  }

  .d-print-table {
    display: table !important
  }

  .d-print-table-row {
    display: table-row !important
  }

  .d-print-table-cell {
    display: table-cell !important
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden
}

.embed-responsive::before {
  display: block;
  content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%
}

.embed-responsive-16by9::before {
  padding-top: 56.25%
}

.embed-responsive-4by3::before {
  padding-top: 75%
}

.embed-responsive-1by1::before {
  padding-top: 100%
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.float-none {
  float: none !important
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important
  }

  .float-sm-right {
    float: right !important
  }

  .float-sm-none {
    float: none !important
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important
  }

  .float-md-right {
    float: right !important
  }

  .float-md-none {
    float: none !important
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important
  }

  .float-lg-right {
    float: right !important
  }

  .float-lg-none {
    float: none !important
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important
  }

  .float-xl-right {
    float: right !important
  }

  .float-xl-none {
    float: none !important
  }
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important
}

.shadow-none {
  box-shadow: none !important
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.w-auto {
  width: auto !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.h-auto {
  height: auto !important
}

.mw-100 {
  max-width: 100% !important
}

.mh-100 {
  max-height: 100% !important
}

.min-vw-100 {
  min-width: 100vw !important
}

.min-vh-100 {
  min-height: 100vh !important
}

.vw-100 {
  width: 100vw !important
}

.vh-100 {
  height: 100vh !important
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent
}

.m-0 {
  margin: 0 !important
}

.mt-0,
.my-0 {
  margin-top: 0 !important
}

.mr-0,
.mx-0 {
  margin-right: 0 !important
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
  margin-left: 0 !important
}

.m-1 {
  margin: 0.25rem !important
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important
}

.m-2 {
  margin: 0.5rem !important
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important
}

.m-3 {
  margin: 1rem !important
}

.mt-3,
.my-3 {
  margin-top: 1rem !important
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important
}

.m-5 {
  margin: 3rem !important
}

.mt-5,
.my-5 {
  margin-top: 3rem !important
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important
}

.p-0 {
  padding: 0 !important
}

.pt-0,
.py-0 {
  padding-top: 0 !important
}

.pr-0,
.px-0 {
  padding-right: 0 !important
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important
}

.pl-0,
.px-0 {
  padding-left: 0 !important
}

.p-1 {
  padding: 0.25rem !important
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important
}

.p-2 {
  padding: 0.5rem !important
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important
}

.p-3 {
  padding: 1rem !important
}

.pt-3,
.py-3 {
  padding-top: 1rem !important
}

.pr-3,
.px-3 {
  padding-right: 1rem !important
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
  padding-left: 1rem !important
}

.p-4 {
  padding: 1.5rem !important
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important
}

.p-5 {
  padding: 3rem !important
}

.pt-5,
.py-5 {
  padding-top: 3rem !important
}

.pr-5,
.px-5 {
  padding-right: 3rem !important
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
  padding-left: 3rem !important
}

.m-n1 {
  margin: -.25rem !important
}

.mt-n1,
.my-n1 {
  margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
  margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
  margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
  margin-left: -.25rem !important
}

.m-n2 {
  margin: -.5rem !important
}

.mt-n2,
.my-n2 {
  margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
  margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
  margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
  margin-left: -.5rem !important
}

.m-n3 {
  margin: -1rem !important
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important
}

.m-n4 {
  margin: -1.5rem !important
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important
}

.m-n5 {
  margin: -3rem !important
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important
}

.m-auto {
  margin: auto !important
}

.mt-auto,
.my-auto {
  margin-top: auto !important
}

.mr-auto,
.mx-auto {
  margin-right: auto !important
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
  margin-left: auto !important
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important
  }

  .m-sm-1 {
    margin: 0.25rem !important
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important
  }

  .m-sm-2 {
    margin: 0.5rem !important
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important
  }

  .m-sm-3 {
    margin: 1rem !important
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important
  }

  .m-sm-4 {
    margin: 1.5rem !important
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important
  }

  .m-sm-5 {
    margin: 3rem !important
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important
  }

  .p-sm-1 {
    padding: 0.25rem !important
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important
  }

  .p-sm-2 {
    padding: 0.5rem !important
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important
  }

  .p-sm-3 {
    padding: 1rem !important
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important
  }

  .p-sm-4 {
    padding: 1.5rem !important
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important
  }

  .p-sm-5 {
    padding: 3rem !important
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important
  }

  .m-sm-n1 {
    margin: -.25rem !important
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -.25rem !important
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -.25rem !important
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -.25rem !important
  }

  .m-sm-n2 {
    margin: -.5rem !important
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -.5rem !important
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -.5rem !important
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -.5rem !important
  }

  .m-sm-n3 {
    margin: -1rem !important
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important
  }

  .m-sm-n4 {
    margin: -1.5rem !important
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important
  }

  .m-sm-n5 {
    margin: -3rem !important
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important
  }

  .m-sm-auto {
    margin: auto !important
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important
  }

  .m-md-1 {
    margin: 0.25rem !important
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important
  }

  .m-md-2 {
    margin: 0.5rem !important
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important
  }

  .m-md-3 {
    margin: 1rem !important
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important
  }

  .m-md-4 {
    margin: 1.5rem !important
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important
  }

  .m-md-5 {
    margin: 3rem !important
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important
  }

  .p-md-1 {
    padding: 0.25rem !important
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important
  }

  .p-md-2 {
    padding: 0.5rem !important
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important
  }

  .p-md-3 {
    padding: 1rem !important
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important
  }

  .p-md-4 {
    padding: 1.5rem !important
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important
  }

  .p-md-5 {
    padding: 3rem !important
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important
  }

  .m-md-n1 {
    margin: -.25rem !important
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -.25rem !important
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -.25rem !important
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -.25rem !important
  }

  .m-md-n2 {
    margin: -.5rem !important
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -.5rem !important
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -.5rem !important
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -.5rem !important
  }

  .m-md-n3 {
    margin: -1rem !important
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important
  }

  .m-md-n4 {
    margin: -1.5rem !important
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important
  }

  .m-md-n5 {
    margin: -3rem !important
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important
  }

  .m-md-auto {
    margin: auto !important
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important
  }

  .m-lg-1 {
    margin: 0.25rem !important
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important
  }

  .m-lg-2 {
    margin: 0.5rem !important
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important
  }

  .m-lg-3 {
    margin: 1rem !important
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important
  }

  .m-lg-4 {
    margin: 1.5rem !important
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important
  }

  .m-lg-5 {
    margin: 3rem !important
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important
  }

  .p-lg-1 {
    padding: 0.25rem !important
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important
  }

  .p-lg-2 {
    padding: 0.5rem !important
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important
  }

  .p-lg-3 {
    padding: 1rem !important
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important
  }

  .p-lg-4 {
    padding: 1.5rem !important
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important
  }

  .p-lg-5 {
    padding: 3rem !important
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important
  }

  .m-lg-n1 {
    margin: -.25rem !important
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -.25rem !important
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -.25rem !important
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -.25rem !important
  }

  .m-lg-n2 {
    margin: -.5rem !important
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -.5rem !important
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -.5rem !important
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -.5rem !important
  }

  .m-lg-n3 {
    margin: -1rem !important
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important
  }

  .m-lg-n4 {
    margin: -1.5rem !important
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important
  }

  .m-lg-n5 {
    margin: -3rem !important
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important
  }

  .m-lg-auto {
    margin: auto !important
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important
  }

  .m-xl-1 {
    margin: 0.25rem !important
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important
  }

  .m-xl-2 {
    margin: 0.5rem !important
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important
  }

  .m-xl-3 {
    margin: 1rem !important
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important
  }

  .m-xl-4 {
    margin: 1.5rem !important
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important
  }

  .m-xl-5 {
    margin: 3rem !important
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important
  }

  .p-xl-1 {
    padding: 0.25rem !important
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important
  }

  .p-xl-2 {
    padding: 0.5rem !important
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important
  }

  .p-xl-3 {
    padding: 1rem !important
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important
  }

  .p-xl-4 {
    padding: 1.5rem !important
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important
  }

  .p-xl-5 {
    padding: 3rem !important
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important
  }

  .m-xl-n1 {
    margin: -.25rem !important
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -.25rem !important
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -.25rem !important
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -.25rem !important
  }

  .m-xl-n2 {
    margin: -.5rem !important
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -.5rem !important
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -.5rem !important
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -.5rem !important
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -.5rem !important
  }

  .m-xl-n3 {
    margin: -1rem !important
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important
  }

  .m-xl-n4 {
    margin: -1.5rem !important
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important
  }

  .m-xl-n5 {
    margin: -3rem !important
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important
  }

  .m-xl-auto {
    margin: auto !important
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
  text-align: justify !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important
  }

  .text-sm-right {
    text-align: right !important
  }

  .text-sm-center {
    text-align: center !important
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important
  }

  .text-md-right {
    text-align: right !important
  }

  .text-md-center {
    text-align: center !important
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important
  }

  .text-lg-right {
    text-align: right !important
  }

  .text-lg-center {
    text-align: center !important
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important
  }

  .text-xl-right {
    text-align: right !important
  }

  .text-xl-center {
    text-align: center !important
  }
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.font-weight-light {
  font-weight: 300 !important
}

.font-weight-lighter {
  font-weight: lighter !important
}

.font-weight-normal {
  font-weight: 400 !important
}

.font-weight-bold {
  font-weight: 700 !important
}

.font-weight-bolder {
  font-weight: bolder !important
}

.font-italic {
  font-style: italic !important
}

.text-white {
  color: #fff !important
}

.text-primary {
  color: #007bff !important
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important
}

.text-secondary {
  color: #6c757d !important
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important
}

.text-success {
  color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c !important
}

.text-info {
  color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important
}

.text-warning {
  color: #ffc107 !important
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important
}

.text-danger {
  color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important
}

.text-light {
  color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important
}

.text-dark {
  color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important
}

.text-body {
  color: #212529 !important
}

.text-muted {
  color: #6c757d !important
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.text-decoration-none {
  text-decoration: none !important
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important
}

.text-reset {
  color: inherit !important
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

@media print {

  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important
  }

  a:not(.btn) {
    text-decoration: underline
  }

  abbr[title]::after {
    content: " (" attr(title) ")"
  }

  pre {
    white-space: pre-wrap !important
  }

  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid
  }

  thead {
    display: table-header-group
  }

  img,
  tr {
    page-break-inside: avoid
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3
  }

  h2,
  h3 {
    page-break-after: avoid
  }

  @page {
    size: a3
  }

  body {
    min-width: 992px !important
  }

  .container {
    min-width: 992px !important
  }

  .navbar {
    display: none
  }

  .badge {
    border: 1px solid #000
  }

  .table {
    border-collapse: collapse !important
  }

  .table td,
  .table th {
    background-color: #fff !important
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important
  }

  .table-dark {
    color: inherit
  }

  .table-dark tbody+tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6
  }
}

.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block
}

.owl-carousel.owl-hidden {
  opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab
}

.owl-carousel.owl-rtl {
  direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
  float: right
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
  z-index: 0
}

.owl-carousel .owl-animated-out {
  z-index: 1
}

.owl-carousel .fadeOut {
  animation-name: fadeOut
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.owl-height {
  transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(https://www.masterpay.pro/owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%
}

.uk-divider-small {
  line-height: 0
}

.uk-divider-small::after {
  content: "";
  display: inline-block;
  width: 100px;
  max-width: 100%;
  border-top: 1px solid #e5e5e5;
  vertical-align: top
}

.uk-divider-vertical {
  width: 1px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #e5e5e5
}

.uk-list {
  padding: 0;
  list-style: none
}

.uk-list>*>:last-child {
  margin-bottom: 0
}

.uk-list>:nth-child(n+2),
.uk-list>*>ul {
  margin-top: 10px
}

.uk-list-disc>*,
.uk-list-circle>*,
.uk-list-square>*,
.uk-list-decimal>*,
.uk-list-hyphen>* {
  padding-left: 30px
}

.uk-list-decimal {
  counter-reset: decimal
}

.uk-list-decimal>* {
  counter-increment: decimal
}

[class*='uk-list']>::before {
  content: '';
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  display: list-item;
  list-style-position: inside;
  text-align: right
}

.uk-list-disc>::before {
  list-style-type: disc
}

.uk-list-circle>::before {
  list-style-type: circle
}

.uk-list-square>::before {
  list-style-type: square
}

.uk-list-decimal>::before {
  content: counter(decimal, decimal) " . "
}

.uk-list-hyphen>::before {
  content: 'ΓÇô\00A0\00A0'
}

.uk-list-muted>::before {
  color: #999 !important
}

.uk-list-emphasis>::before {
  color: #333 !important
}

.uk-list-primary>::before {
  color: #1e87f0 !important
}

.uk-list-secondary>::before {
  color: #222 !important
}

.uk-list-bullet>* {
  padding-left: 30px
}

.uk-list-bullet>::before {
  content: "";
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%
}

.uk-list-divider>:nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5
}

.uk-list-striped>* {
  padding: 10px 10px
}

.uk-list-striped>*:nth-of-type(odd) {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5
}

.uk-list-striped>:nth-of-type(odd) {
  background: #f8f8f8
}

.uk-list-striped>:nth-child(n+2) {
  margin-top: 0
}

.uk-list-large>:nth-child(n+2),
.uk-list-large>*>ul {
  margin-top: 20px
}

.uk-list-collapse>:nth-child(n+2),
.uk-list-collapse>*>ul {
  margin-top: 0
}

.uk-list-large.uk-list-divider>:nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px
}

.uk-list-collapse.uk-list-divider>:nth-child(n+2) {
  margin-top: 0;
  padding-top: 0
}

.uk-list-large.uk-list-striped>* {
  padding: 20px 10px
}

.uk-list-collapse.uk-list-striped>* {
  padding-top: 0;
  padding-bottom: 0
}

.uk-list-large.uk-list-striped>:nth-child(n+2),
.uk-list-collapse.uk-list-striped>:nth-child(n+2) {
  margin-top: 0
}

.uk-description-list>dt {
  color: #333;
  font-size: 0.875rem;
  font-weight: normal;
  text-transform: uppercase
}

.uk-description-list>dt:nth-child(n+2) {
  margin-top: 20px
}

.uk-description-list-divider>dt:nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5
}

.uk-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 20px
}

*+.uk-table {
  margin-top: 20px
}

.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  font-size: 0.875rem;
  font-weight: normal;
  color: #999;
  text-transform: uppercase
}

.uk-table td {
  padding: 16px 12px;
  vertical-align: top
}

.uk-table td>:last-child {
  margin-bottom: 0
}

.uk-table tfoot {
  font-size: 0.875rem
}

.uk-table caption {
  font-size: 0.875rem;
  text-align: left;
  color: #999
}

.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important
}

.uk-table-divider>tr:not(:first-child),
.uk-table-divider>:not(:first-child)>tr,
.uk-table-divider>:first-child>tr:not(:first-child) {
  border-top: 1px solid #e5e5e5
}

.uk-table-striped>tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5
}

.uk-table-hover>tr:hover,
.uk-table-hover tbody tr:hover {
  background: #ffd
}

.uk-table>tr.uk-active,
.uk-table tbody tr.uk-active {
  background: #ffd
}

.uk-table-small th,
.uk-table-small td {
  padding: 10px 12px
}

.uk-table-large th,
.uk-table-large td {
  padding: 22px 12px
}

.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0
}

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0
}

.uk-table-shrink {
  width: 1px
}

.uk-table-expand {
  min-width: 150px
}

.uk-table-link {
  padding: 0 !important
}

.uk-table-link>a {
  display: block;
  padding: 16px 12px
}

.uk-table-small .uk-table-link>a {
  padding: 10px 12px
}

@media (max-width: 959px) {

  .uk-table-responsive,
  .uk-table-responsive tbody,
  .uk-table-responsive th,
  .uk-table-responsive td,
  .uk-table-responsive tr {
    display: block
  }

  .uk-table-responsive thead {
    display: none
  }

  .uk-table-responsive th,
  .uk-table-responsive td {
    width: auto !important;
    max-width: none !important;
    min-width: 0 !important;
    overflow: visible !important;
    white-space: normal !important
  }

  .uk-table-responsive th:not(:first-child):not(.uk-table-link),
  .uk-table-responsive td:not(:first-child):not(.uk-table-link),
  .uk-table-responsive .uk-table-link:not(:first-child)>a {
    padding-top: 5px !important
  }

  .uk-table-responsive th:not(:last-child):not(.uk-table-link),
  .uk-table-responsive td:not(:last-child):not(.uk-table-link),
  .uk-table-responsive .uk-table-link:not(:last-child)>a {
    padding-bottom: 5px !important
  }

  .uk-table-justify.uk-table-responsive th,
  .uk-table-justify.uk-table-responsive td {
    padding-left: 0;
    padding-right: 0
  }
}

.uk-table tbody tr {
  transition: background-color 0.1s linear
}

.uk-icon {
  margin: 0;
  border: none;
  border-radius: 0;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  fill: currentcolor;
  line-height: 0
}

button.uk-icon:not(:disabled) {
  cursor: pointer
}

.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0
}

.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor
}

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor
}

.uk-icon>* {
  transform: translate(0, 0)
}

.uk-icon-image {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle
}

.uk-icon-link {
  color: #999
}

.uk-icon-link:hover,
.uk-icon-link:focus {
  color: #666;
  outline: none
}

.uk-icon-link:active,
.uk-active>.uk-icon-link {
  color: #595959
}

.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color
}

.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: #ebebeb;
  color: #666;
  outline: none
}

.uk-icon-button:active,
.uk-active>.uk-icon-button {
  background-color: #dfdfdf;
  color: #666
}

.uk-range {
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  padding: 0
}

.uk-range:focus {
  outline: none
}

.uk-range::-moz-focus-outer {
  border: none
}

.uk-range::-ms-track {
  height: 15px;
  background: transparent;
  border-color: transparent;
  color: transparent
}

.uk-range:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer
}

.uk-range:not(:disabled)::-moz-range-thumb {
  cursor: pointer
}

.uk-range:not(:disabled)::-ms-thumb {
  cursor: pointer
}

.uk-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -7px;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #fff;
  border: 1px solid #cccccc
}

.uk-range::-moz-range-thumb {
  border: none;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #fff;
  border: 1px solid #cccccc
}

.uk-range::-ms-thumb {
  margin-top: 0
}

.uk-range::-ms-thumb {
  border: none;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #fff;
  border: 1px solid #cccccc
}

.uk-range::-ms-tooltip {
  display: none
}

.uk-range::-webkit-slider-runnable-track {
  height: 3px;
  background: #ebebeb;
  border-radius: 500px
}

.uk-range:focus::-webkit-slider-runnable-track,
.uk-range:active::-webkit-slider-runnable-track {
  background: #d2d2d2
}

.uk-range::-moz-range-track {
  height: 3px;
  background: #ebebeb;
  border-radius: 500px
}

.uk-range:focus::-moz-range-track {
  background: #d2d2d2
}

.uk-range::-ms-fill-lower,
.uk-range::-ms-fill-upper {
  height: 3px;
  background: #ebebeb;
  border-radius: 500px
}

.uk-range:focus::-ms-fill-lower,
.uk-range:focus::-ms-fill-upper {
  background: #d2d2d2
}

.uk-input,
.uk-select,
.uk-textarea,
.uk-radio,
.uk-checkbox {
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit
}

.uk-input {
  overflow: visible
}

.uk-select {
  text-transform: none
}

.uk-select optgroup {
  font: inherit;
  font-weight: bold
}

.uk-textarea {
  overflow: auto
}

.uk-input[type="search"]::-webkit-search-cancel-button,
.uk-input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

.uk-input[type="number"]::-webkit-inner-spin-button,
.uk-input[type="number"]::-webkit-outer-spin-button {
  height: auto
}

.uk-input::-moz-placeholder,
.uk-textarea::-moz-placeholder {
  opacity: 1
}

.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled) {
  cursor: pointer
}

.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0
}

.uk-input,
.uk-textarea {
  -webkit-appearance: none
}

.uk-input,
.uk-select,
.uk-textarea {
  max-width: 100%;
  width: 100%;
  border: 0 none;
  padding: 0 10px;
  background: #fff;
  color: #666;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border
}

.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 40px;
  vertical-align: middle;
  display: inline-block
}

.uk-input:not(input),
.uk-select:not(select) {
  line-height: 38px
}

.uk-select[multiple],
.uk-select[size],
.uk-textarea {
  padding-top: 4px;
  padding-bottom: 4px;
  vertical-align: top
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #fff;
  color: #666;
  border-color: #1e87f0
}

.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
  background-color: #f8f8f8;
  color: #999;
  border-color: #e5e5e5
}

.uk-input::-ms-input-placeholder {
  color: #999 !important
}

.uk-input::placeholder {
  color: #999
}

.uk-textarea::-ms-input-placeholder {
  color: #999 !important
}

.uk-textarea::placeholder {
  color: #999
}

.uk-form-small {
  font-size: 0.875rem
}

.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px
}

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 28px
}

.uk-form-large {
  font-size: 1.25rem
}

.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px
}

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 53px
}

.uk-form-danger,
.uk-form-danger:focus {
  color: #f0506e;
  border-color: #f0506e
}

.uk-form-success,
.uk-form-success:focus {
  color: #32d296;
  border-color: #32d296
}

.uk-form-blank {
  background: none;
  border-color: transparent
}

.uk-form-blank:focus {
  border-color: #e5e5e5;
  border-style: dashed
}

input.uk-form-width-xsmall {
  width: 50px
}

select.uk-form-width-xsmall {
  width: 75px
}

.uk-form-width-small {
  width: 130px
}

.uk-form-width-medium {
  width: 200px
}

.uk-form-width-large {
  width: 500px
}

.uk-select:not([multiple]):not([size]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%
}

.uk-select:not([multiple]):not([size])::-ms-expand {
  display: none
}

.uk-select:not([multiple]):not([size]) option {
  color: #444
}

.uk-select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-input[list] {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%
}

.uk-input[list]:hover,
.uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-input[list]::-webkit-calendar-picker-indicator {
  display: none
}

.uk-radio,
.uk-checkbox {
  display: inline-block;
  height: 16px;
  width: 16px;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 1px solid #cccccc;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border
}

.uk-radio {
  border-radius: 50%
}

.uk-radio:focus,
.uk-checkbox:focus {
  outline: none;
  border-color: #1e87f0
}

.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #1e87f0;
  border-color: transparent
}

.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #0e6dcd
}

.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-radio:disabled,
.uk-checkbox:disabled {
  background-color: #f8f8f8;
  border-color: #e5e5e5
}

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-checkbox:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-checkbox:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-legend {
  width: 100%;
  color: inherit;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1.4
}

.uk-form-custom {
  display: inline-block;
  position: relative;
  max-width: 100%;
  vertical-align: middle
}

.uk-form-custom select,
.uk-form-custom input[type="file"] {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-appearance: none;
  opacity: 0;
  cursor: pointer
}

.uk-form-custom input[type="file"] {
  font-size: 500px;
  overflow: hidden
}

.uk-form-label {
  color: #333;
  font-size: 0.875rem
}

.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 5px
}

@media (max-width: 959px) {
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 5px
  }
}

@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px
  }

  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px
  }
}

.uk-form-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #999
}

.uk-form-icon:hover {
  color: #666
}

.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none
}

.uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
  padding-left: 40px !important
}

.uk-form-icon-flip {
  right: 0;
  left: auto
}

.uk-form-icon-flip~.uk-input {
  padding-right: 40px !important
}

.uk-button {
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  -webkit-appearance: none;
  border-radius: 0;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color, border-color
}

.uk-button:not(:disabled) {
  cursor: pointer
}

.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0
}

.uk-button:hover {
  text-decoration: none
}

.uk-button:focus {
  outline: none
}

.uk-button-default {
  background-color: transparent;
  color: #333;
  border: 1px solid #e5e5e5
}

.uk-button-default:hover,
.uk-button-default:focus {
  background-color: transparent;
  color: #333;
  border-color: #b2b2b2
}

.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: transparent;
  color: #333;
  border-color: #999999
}

.uk-button-primary {
  background-color: #1e87f0;
  color: #fff;
  border: 1px solid transparent
}

.uk-button-primary:hover,
.uk-button-primary:focus {
  background-color: #0f7ae5;
  color: #fff
}

.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #0e6dcd;
  color: #fff
}

.uk-button-secondary {
  background-color: #222;
  color: #fff;
  border: 1px solid transparent
}

.uk-button-secondary:hover,
.uk-button-secondary:focus {
  background-color: #151515;
  color: #fff
}

.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #080808;
  color: #fff
}

.uk-button-danger {
  background-color: #f0506e;
  color: #fff;
  border: 1px solid transparent
}

.uk-button-danger:hover,
.uk-button-danger:focus {
  background-color: #ee395b;
  color: #fff
}

.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #ec2147;
  color: #fff
}

.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: transparent;
  color: #999;
  border-color: #e5e5e5
}

.uk-button-small {
  padding: 0 15px;
  line-height: 28px;
  font-size: 0.875rem
}

.uk-button-large {
  padding: 0 40px;
  line-height: 53px;
  font-size: 0.875rem
}

.uk-button-text {
  padding: 0;
  line-height: 1.5;
  background: none;
  color: #333;
  position: relative
}

.uk-button-text::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 100%;
  border-bottom: 1px solid #333;
  transition: right 0.3s ease-out
}

.uk-button-text:hover,
.uk-button-text:focus {
  color: #333
}

.uk-button-text:hover::before,
.uk-button-text:focus::before {
  right: 0
}

.uk-button-text:disabled {
  color: #999
}

.uk-button-text:disabled::before {
  display: none
}

.uk-button-link {
  padding: 0;
  line-height: 1.5;
  background: none;
  color: #1e87f0
}

.uk-button-link:hover,
.uk-button-link:focus {
  color: #0f6ecd;
  text-decoration: underline
}

.uk-button-link:disabled {
  color: #999;
  text-decoration: none
}

.uk-button-group {
  display: inline-flex;
  vertical-align: middle;
  position: relative
}

.uk-button-group>.uk-button:nth-child(n+2),
.uk-button-group>div:nth-child(n+2) .uk-button {
  margin-left: -1px
}

.uk-button-group .uk-button:hover,
.uk-button-group .uk-button:focus,
.uk-button-group .uk-button:active,
.uk-button-group .uk-button.uk-active {
  position: relative;
  z-index: 1
}

.uk-progress {
  vertical-align: baseline;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  width: 100%;
  border: 0;
  background-color: #f8f8f8;
  margin-bottom: 20px;
  height: 15px;
  border-radius: 500px;
  overflow: hidden
}

*+.uk-progress {
  margin-top: 20px
}

.uk-progress:indeterminate {
  color: transparent
}

.uk-progress::-webkit-progress-bar {
  background-color: #f8f8f8;
  border-radius: 500px;
  overflow: hidden
}

.uk-progress:indeterminate::-moz-progress-bar {
  width: 0
}

.uk-progress::-webkit-progress-value {
  background-color: #1e87f0;
  transition: width 0.6s ease
}

.uk-progress::-moz-progress-bar {
  background-color: #1e87f0
}

.uk-progress::-ms-fill {
  background-color: #1e87f0;
  transition: width 0.6s ease;
  border: 0
}

.uk-section {
  display: flow-root;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 40px
}

@media (min-width: 960px) {
  .uk-section {
    padding-top: 70px;
    padding-bottom: 70px
  }
}

.uk-section>:last-child {
  margin-bottom: 0
}

.uk-section-xsmall {
  padding-top: 20px;
  padding-bottom: 20px
}

.uk-section-small {
  padding-top: 40px;
  padding-bottom: 40px
}

.uk-section-large {
  padding-top: 70px;
  padding-bottom: 70px
}

@media (min-width: 960px) {
  .uk-section-large {
    padding-top: 140px;
    padding-bottom: 140px
  }
}

.uk-section-xlarge {
  padding-top: 140px;
  padding-bottom: 140px
}

@media (min-width: 960px) {
  .uk-section-xlarge {
    padding-top: 210px;
    padding-bottom: 210px
  }
}

.uk-section-default {
  background: #fff
}

.uk-section-muted {
  background: #f8f8f8
}

.uk-section-primary {
  background: #1e87f0
}

.uk-section-secondary {
  background: #222
}

.uk-container {
  display: flow-root;
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px
}

@media (min-width: 640px) {
  .uk-container {
    padding-left: 30px;
    padding-right: 30px
  }
}

@media (min-width: 960px) {
  .uk-container {
    padding-left: 40px;
    padding-right: 40px
  }
}

.uk-container>:last-child {
  margin-bottom: 0
}

.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0
}

.uk-container-xsmall {
  max-width: 750px
}

.uk-container-small {
  max-width: 900px
}

.uk-container-large {
  max-width: 1400px
}

.uk-container-xlarge {
  max-width: 1600px
}

.uk-container-expand {
  max-width: none
}

.uk-container-expand-left {
  margin-left: 0
}

.uk-container-expand-right {
  margin-right: 0
}

@media (min-width: 640px) {

  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px)
  }

  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 30px)
  }
}

@media (min-width: 960px) {

  .uk-container-expand-left,
  .uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px)
  }

  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px)
  }

  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 40px)
  }

  .uk-container-expand-left.uk-container-large,
  .uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1400px / 2) - 40px)
  }

  .uk-container-expand-left.uk-container-xlarge,
  .uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 40px)
  }
}

.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px)
}

.uk-container-item-padding-remove-left {
  margin-left: -15px
}

.uk-container-item-padding-remove-right {
  margin-right: -15px
}

@media (min-width: 640px) {

  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 30px)
  }

  .uk-container-item-padding-remove-left {
    margin-left: -30px
  }

  .uk-container-item-padding-remove-right {
    margin-right: -30px
  }
}

@media (min-width: 960px) {

  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 40px)
  }

  .uk-container-item-padding-remove-left {
    margin-left: -40px
  }

  .uk-container-item-padding-remove-right {
    margin-right: -40px
  }
}

.uk-tile {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 40px
}

@media (min-width: 640px) {
  .uk-tile {
    padding-left: 30px;
    padding-right: 30px
  }
}

@media (min-width: 960px) {
  .uk-tile {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 70px;
    padding-bottom: 70px
  }
}

.uk-tile>:last-child {
  margin-bottom: 0
}

.uk-tile-xsmall {
  padding-top: 20px;
  padding-bottom: 20px
}

.uk-tile-small {
  padding-top: 40px;
  padding-bottom: 40px
}

.uk-tile-large {
  padding-top: 70px;
  padding-bottom: 70px
}

@media (min-width: 960px) {
  .uk-tile-large {
    padding-top: 140px;
    padding-bottom: 140px
  }
}

.uk-tile-xlarge {
  padding-top: 140px;
  padding-bottom: 140px
}

@media (min-width: 960px) {
  .uk-tile-xlarge {
    padding-top: 210px;
    padding-bottom: 210px
  }
}

.uk-tile-default {
  background: #fff
}

.uk-tile-muted {
  background: #f8f8f8
}

.uk-tile-primary {
  background: #1e87f0
}

.uk-tile-secondary {
  background: #222
}

.uk-card {
  position: relative;
  box-sizing: border-box;
  transition: box-shadow 0.1s ease-in-out
}

.uk-card-body {
  display: flow-root;
  padding: 30px 30px
}

.uk-card-header {
  display: flow-root;
  padding: 15px 30px
}

.uk-card-footer {
  display: flow-root;
  padding: 15px 30px
}

@media (min-width: 1200px) {
  .uk-card-body {
    padding: 40px 40px
  }

  .uk-card-header {
    padding: 20px 40px
  }

  .uk-card-footer {
    padding: 20px 40px
  }
}

.uk-card-body>:last-child,
.uk-card-header>:last-child,
.uk-card-footer>:last-child {
  margin-bottom: 0
}

.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4
}

.uk-card-badge {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1
}

.uk-card-badge:first-child+* {
  margin-top: 0
}

.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background: #fff;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16)
}

.uk-card-default {
  background: #fff;
  color: #666;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.uk-card-default .uk-card-title {
  color: #333
}

.uk-card-default.uk-card-hover:hover {
  background-color: #fff;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16)
}

.uk-card-default .uk-card-header {
  border-bottom: 1px solid #e5e5e5
}

.uk-card-default .uk-card-footer {
  border-top: 1px solid #e5e5e5
}

.uk-card-primary {
  background: #1e87f0;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.uk-card-primary .uk-card-title {
  color: #fff
}

.uk-card-primary.uk-card-hover:hover {
  background-color: #1e87f0;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16)
}

.uk-card-secondary {
  background: #222;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.uk-card-secondary .uk-card-title {
  color: #fff
}

.uk-card-secondary.uk-card-hover:hover {
  background-color: #222;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16)
}

.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px
}

.uk-card-small .uk-card-header {
  padding: 13px 20px
}

.uk-card-small .uk-card-footer {
  padding: 13px 20px
}

@media (min-width: 1200px) {

  .uk-card-large.uk-card-body,
  .uk-card-large .uk-card-body {
    padding: 70px 70px
  }

  .uk-card-large .uk-card-header {
    padding: 35px 70px
  }

  .uk-card-large .uk-card-footer {
    padding: 35px 70px
  }
}

.uk-card-body>.uk-nav-default {
  margin-left: -30px;
  margin-right: -30px
}

.uk-card-body>.uk-nav-default:only-child {
  margin-top: -15px;
  margin-bottom: -15px
}

.uk-card-body .uk-nav-default>li>a,
.uk-card-body .uk-nav-default .uk-nav-header,
.uk-card-body .uk-nav-default .uk-nav-divider {
  padding-left: 30px;
  padding-right: 30px
}

.uk-card-body .uk-nav-default .uk-nav-sub {
  padding-left: 45px
}

@media (min-width: 1200px) {
  .uk-card-body>.uk-nav-default {
    margin-left: -40px;
    margin-right: -40px
  }

  .uk-card-body>.uk-nav-default:only-child {
    margin-top: -25px;
    margin-bottom: -25px
  }

  .uk-card-body .uk-nav-default>li>a,
  .uk-card-body .uk-nav-default .uk-nav-header,
  .uk-card-body .uk-nav-default .uk-nav-divider {
    padding-left: 40px;
    padding-right: 40px
  }

  .uk-card-body .uk-nav-default .uk-nav-sub {
    padding-left: 55px
  }
}

.uk-card-small>.uk-nav-default {
  margin-left: -20px;
  margin-right: -20px
}

.uk-card-small>.uk-nav-default:only-child {
  margin-top: -5px;
  margin-bottom: -5px
}

.uk-card-small .uk-nav-default>li>a,
.uk-card-small .uk-nav-default .uk-nav-header,
.uk-card-small .uk-nav-default .uk-nav-divider {
  padding-left: 20px;
  padding-right: 20px
}

.uk-card-small .uk-nav-default .uk-nav-sub {
  padding-left: 35px
}

@media (min-width: 1200px) {
  .uk-card-large>.uk-nav-default {
    margin: 0
  }

  .uk-card-large>.uk-nav-default:only-child {
    margin: 0
  }

  .uk-card-large .uk-nav-default>li>a,
  .uk-card-large .uk-nav-default .uk-nav-header,
  .uk-card-large .uk-nav-default .uk-nav-divider {
    padding-left: 0;
    padding-right: 0
  }

  .uk-card-large .uk-nav-default .uk-nav-sub {
    padding-left: 15px
  }
}

.uk-close {
  color: #999;
  transition: 0.1s ease-in-out;
  transition-property: color, opacity
}

.uk-close:hover,
.uk-close:focus {
  color: #666;
  outline: none
}

.uk-spinner>* {
  animation: uk-spinner-rotate 1.4s linear infinite
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(270deg)
  }
}

.uk-spinner>*>* {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px
  }

  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg)
  }

  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg)
  }
}

.uk-totop {
  padding: 5px;
  color: #999;
  transition: color 0.1s ease-in-out
}

.uk-totop:hover,
.uk-totop:focus {
  color: #666;
  outline: none
}

.uk-totop:active {
  color: #333
}

.uk-marker {
  padding: 5px;
  background: #222;
  color: #fff;
  border-radius: 500px
}

.uk-marker:hover,
.uk-marker:focus {
  color: #fff;
  outline: none
}

.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666
}

*+.uk-alert {
  margin-top: 20px
}

.uk-alert>:last-child {
  margin-bottom: 0
}

.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px;
  color: inherit;
  opacity: 0.4
}

.uk-alert-close:first-child+* {
  margin-top: 0
}

.uk-alert-close:hover,
.uk-alert-close:focus {
  color: inherit;
  opacity: 0.8
}

.uk-alert-primary {
  background: #d8eafc;
  color: #1e87f0
}

.uk-alert-success {
  background: #edfbf6;
  color: #32d296
}

.uk-alert-warning {
  background: #fff6ee;
  color: #faa05a
}

.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e
}

.uk-alert h1,
.uk-alert h2,
.uk-alert h3,
.uk-alert h4,
.uk-alert h5,
.uk-alert h6 {
  color: inherit
}

.uk-alert a:not([class]) {
  color: inherit;
  text-decoration: underline
}

.uk-alert a:not([class]):hover {
  color: inherit;
  text-decoration: underline
}

.uk-placeholder {
  margin-bottom: 20px;
  padding: 30px 30px;
  background: transparent;
  border: 1px dashed #e5e5e5
}

*+.uk-placeholder {
  margin-top: 20px
}

.uk-placeholder>:last-child {
  margin-bottom: 0
}

.uk-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  background: #1e87f0;
  color: #fff;
  font-size: 0.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center
}

.uk-badge:hover,
.uk-badge:focus {
  color: #fff;
  text-decoration: none;
  outline: none
}

.uk-label {
  display: inline-block;
  padding: 0 10px;
  background: #1e87f0;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 2px;
  text-transform: uppercase
}

.uk-label-success {
  background-color: #32d296;
  color: #fff
}

.uk-label-warning {
  background-color: #faa05a;
  color: #fff
}

.uk-label-danger {
  background-color: #f0506e;
  color: #fff
}

.uk-overlay {
  padding: 30px 30px
}

.uk-overlay>:last-child {
  margin-bottom: 0
}

.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8)
}

.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.8)
}

.uk-article {
  display: flow-root
}

.uk-article>:last-child {
  margin-bottom: 0
}

.uk-article+.uk-article {
  margin-top: 70px
}

.uk-article-title {
  font-size: 2.23125rem;
  line-height: 1.2
}

@media (min-width: 960px) {
  .uk-article-title {
    font-size: 2.625rem
  }
}

.uk-article-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999
}

.uk-article-meta a {
  color: #999
}

.uk-article-meta a:hover {
  color: #666;
  text-decoration: none
}

.uk-comment-body {
  display: flow-root;
  overflow-wrap: break-word;
  word-wrap: break-word
}

.uk-comment-header {
  display: flow-root;
  margin-bottom: 20px
}

.uk-comment-body>:last-child,
.uk-comment-header>:last-child {
  margin-bottom: 0
}

.uk-comment-title {
  font-size: 1.25rem;
  line-height: 1.4
}

.uk-comment-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999
}

.uk-comment-list {
  padding: 0;
  list-style: none
}

.uk-comment-list>:nth-child(n+2) {
  margin-top: 70px
}

.uk-comment-list .uk-comment~ul {
  margin: 70px 0 0 0;
  padding-left: 30px;
  list-style: none
}

@media (min-width: 960px) {
  .uk-comment-list .uk-comment~ul {
    padding-left: 100px
  }
}

.uk-comment-list .uk-comment~ul>:nth-child(n+2) {
  margin-top: 70px
}

.uk-comment-primary {
  padding: 30px;
  background-color: #f8f8f8
}

.uk-search {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0
}

.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
  -webkit-appearance: none
}

.uk-search-input::-moz-placeholder {
  opacity: 1
}

.uk-search-input {
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit;
  overflow: visible;
  -webkit-appearance: none;
  vertical-align: middle;
  width: 100%;
  border: none;
  color: #666
}

.uk-search-input:focus {
  outline: none
}

.uk-search-input:-ms-input-placeholder {
  color: #999 !important
}

.uk-search-input::placeholder {
  color: #999
}

.uk-search-icon:focus {
  outline: none
}

.uk-search .uk-search-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #999
}

.uk-search .uk-search-icon:hover {
  color: #999
}

.uk-search .uk-search-icon:not(a):not(button):not(input) {
  pointer-events: none
}

.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto
}

.uk-search-default {
  width: 180px
}

.uk-search-default .uk-search-input {
  height: 40px;
  padding-left: 6px;
  padding-right: 6px;
  background: transparent;
  border: 1px solid #e5e5e5
}

.uk-search-default .uk-search-input:focus {
  background-color: transparent
}

.uk-search-default .uk-search-icon {
  width: 40px
}

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip)~.uk-search-input {
  padding-left: 40px
}

.uk-search-default .uk-search-icon-flip~.uk-search-input {
  padding-right: 40px
}

.uk-search-navbar {
  width: 400px
}

.uk-search-navbar .uk-search-input {
  height: 40px;
  background: transparent;
  font-size: 1.5rem
}

.uk-search-navbar .uk-search-icon {
  width: 40px
}

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip)~.uk-search-input {
  padding-left: 40px
}

.uk-search-navbar .uk-search-icon-flip~.uk-search-input {
  padding-right: 40px
}

.uk-search-large {
  width: 500px
}

.uk-search-large .uk-search-input {
  height: 80px;
  background: transparent;
  font-size: 2.625rem
}

.uk-search-large .uk-search-icon {
  width: 80px
}

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip)~.uk-search-input {
  padding-left: 80px
}

.uk-search-large .uk-search-icon-flip~.uk-search-input {
  padding-right: 80px
}

.uk-search-toggle {
  color: #999
}

.uk-search-toggle:hover,
.uk-search-toggle:focus {
  color: #666
}

.uk-accordion {
  padding: 0;
  list-style: none
}

.uk-accordion>:nth-child(n+2) {
  margin-top: 20px
}

.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333;
  overflow: hidden
}

.uk-accordion-title::before {
  content: "";
  width: 1.4em;
  height: 1.4em;
  margin-left: 10px;
  float: right;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%
}

.uk-open>.uk-accordion-title::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-accordion-title:hover,
.uk-accordion-title:focus {
  color: #666;
  text-decoration: none;
  outline: none
}

.uk-accordion-content {
  display: flow-root;
  margin-top: 20px
}

.uk-accordion-content>:last-child {
  margin-bottom: 0
}

.uk-drop {
  display: none;
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  width: 300px
}

.uk-drop.uk-open {
  display: block
}

[class*='uk-drop-top'] {
  margin-top: -20px
}

[class*='uk-drop-bottom'] {
  margin-top: 20px
}

[class*='uk-drop-left'] {
  margin-left: -20px
}

[class*='uk-drop-right'] {
  margin-left: 20px
}

.uk-drop-stack .uk-drop-grid>* {
  width: 100% !important
}

.uk-dropdown {
  display: none;
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  min-width: 200px;
  padding: 25px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15)
}

.uk-dropdown.uk-open {
  display: block
}

.uk-dropdown-nav {
  white-space: nowrap;
  font-size: 0.875rem
}

.uk-dropdown-nav>li>a {
  color: #999
}

.uk-dropdown-nav>li>a:hover,
.uk-dropdown-nav>li>a:focus,
.uk-dropdown-nav>li.uk-active>a {
  color: #666
}

.uk-dropdown-nav .uk-nav-header {
  color: #333
}

.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5
}

.uk-dropdown-nav .uk-nav-sub a {
  color: #999
}

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub a:focus,
.uk-dropdown-nav .uk-nav-sub li.uk-active>a {
  color: #666
}

[class*='uk-dropdown-top'] {
  margin-top: -10px
}

[class*='uk-dropdown-bottom'] {
  margin-top: 10px
}

[class*='uk-dropdown-left'] {
  margin-left: -10px
}

[class*='uk-dropdown-right'] {
  margin-left: 10px
}

.uk-dropdown-stack .uk-dropdown-grid>* {
  width: 100% !important
}

.uk-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 15px 15px;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.15s linear
}

@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px
  }
}

@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px
  }
}

.uk-modal.uk-open {
  opacity: 1
}

.uk-modal-page {
  overflow: hidden
}

.uk-modal-dialog {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  max-width: calc(100% - 0.01px) !important;
  background: #fff;
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform
}

.uk-open>.uk-modal-dialog {
  opacity: 1;
  transform: translateY(0)
}

.uk-modal-container .uk-modal-dialog {
  width: 1200px
}

.uk-modal-full {
  padding: 0;
  background: none
}

.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0)
}

.uk-modal-body {
  display: flow-root;
  padding: 30px 30px
}

.uk-modal-header {
  display: flow-root;
  padding: 15px 30px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5
}

.uk-modal-footer {
  display: flow-root;
  padding: 15px 30px;
  background: #fff;
  border-top: 1px solid #e5e5e5
}

.uk-modal-body>:last-child,
.uk-modal-header>:last-child,
.uk-modal-footer>:last-child {
  margin-bottom: 0
}

.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3
}

[class*='uk-modal-close-'] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px
}

[class*='uk-modal-close-']:first-child+* {
  margin-top: 0
}

.uk-modal-close-outside {
  top: 0;
  right: -5px;
  transform: translate(0, -100%);
  color: #ffffff
}

.uk-modal-close-outside:hover {
  color: #fff
}

@media (min-width: 960px) {
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%)
  }
}

.uk-modal-close-full {
  top: 0;
  right: 0;
  padding: 20px;
  background: #fff
}

.uk-lightbox {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  background: #000;
  opacity: 0;
  transition: opacity 0.15s linear;
  touch-action: pinch-zoom
}

.uk-lightbox.uk-open {
  display: block;
  opacity: 1
}

.uk-lightbox-page {
  overflow: hidden
}

.uk-lightbox-items>* {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  will-change: transform, opacity
}

.uk-lightbox-items>*>* {
  max-width: 100vw;
  max-height: 100vh
}

.uk-lightbox-items>:focus {
  outline: none
}

.uk-lightbox-items>*>:not(iframe) {
  width: auto;
  height: auto
}

.uk-lightbox-items>.uk-active {
  display: flex
}

.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7)
}

.uk-lightbox-toolbar>* {
  color: rgba(255, 255, 255, 0.7)
}

.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7)
}

.uk-lightbox-toolbar-icon:hover {
  color: #fff
}

.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  display: inline-flex;
  justify-content: center;
  align-items: center
}

.uk-lightbox-button:hover {
  color: #fff
}

.uk-lightbox-caption:empty {
  display: none
}

.uk-lightbox-iframe {
  width: 80%;
  height: 80%
}

.uk-slideshow {
  -webkit-tap-highlight-color: transparent
}

.uk-slideshow-items {
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  -webkit-touch-callout: none
}

.uk-slideshow-items>* {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  will-change: transform, opacity;
  touch-action: pan-y
}

.uk-slideshow-items>:focus {
  outline: none
}

.uk-slideshow-items>:not(.uk-active) {
  display: none
}

.uk-slider {
  -webkit-tap-highlight-color: transparent
}

.uk-slider-container {
  overflow: hidden
}

.uk-slider-items {
  will-change: transform;
  position: relative
}

.uk-slider-items:not(.uk-grid) {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-touch-callout: none
}

.uk-slider-items.uk-grid {
  flex-wrap: nowrap
}

.uk-slider-items>* {
  flex: none;
  position: relative;
  touch-action: pan-y
}

.uk-slider-items>:focus {
  outline: none
}

.uk-sticky-fixed {
  z-index: 980;
  box-sizing: border-box;
  margin: 0 !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s
}

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s
}

.uk-offcanvas {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000
}

.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto
}

.uk-offcanvas-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #222;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch
}

@media (min-width: 960px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 40px 40px
  }
}

.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px
}

@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px
  }
}

.uk-open>.uk-offcanvas-bar {
  left: 0
}

.uk-offcanvas-flip .uk-open>.uk-offcanvas-bar {
  left: auto;
  right: 0
}

.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right
}

.uk-offcanvas-reveal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease-out
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
  left: 0
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  left: auto;
  right: 0
}

.uk-open>.uk-offcanvas-reveal {
  width: 270px
}

@media (min-width: 960px) {
  .uk-open>.uk-offcanvas-reveal {
    width: 350px
  }
}

.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto
}

.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 20px;
  padding: 5px
}

.uk-offcanvas-overlay {
  width: 100vw;
  touch-action: none
}

.uk-offcanvas-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.15s linear
}

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1
}

.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden
}

.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  box-sizing: border-box;
  width: 100%
}

:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px
}

@media (min-width: 960px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px
  }

  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px
  }
}

.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none
}

.uk-switcher>:not(.uk-active) {
  display: none
}

.uk-switcher>*>:last-child {
  margin-bottom: 0
}

.uk-leader {
  overflow: hidden
}

.uk-leader-fill::after {
  display: inline-block;
  margin-left: 15px;
  width: 0;
  content: attr(data-fill);
  white-space: nowrap
}

.uk-leader-fill.uk-leader-hide::after {
  display: none
}

.uk-leader-fill-content::before {
  content: '.'
}

:root {
  --uk-leader-fill-content: .
}

.uk-notification {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  box-sizing: border-box;
  width: 350px
}

.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px
}

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px
}

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px
}

@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0
  }
}

.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer
}

*+.uk-notification-message {
  margin-top: 10px
}

.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px
}

.uk-notification-message:hover .uk-notification-close {
  display: block
}

.uk-notification-message-primary {
  color: #1e87f0
}

.uk-notification-message-success {
  color: #32d296
}

.uk-notification-message-warning {
  color: #faa05a
}

.uk-notification-message-danger {
  color: #f0506e
}

.uk-tooltip {
  display: none;
  position: absolute;
  z-index: 1030;
  top: 0;
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  background: #666;
  border-radius: 2px;
  color: #fff;
  font-size: 12px
}

.uk-tooltip.uk-active {
  display: block
}

[class*='uk-tooltip-top'] {
  margin-top: -10px
}

[class*='uk-tooltip-bottom'] {
  margin-top: 10px
}

[class*='uk-tooltip-left'] {
  margin-left: -10px
}

[class*='uk-tooltip-right'] {
  margin-left: 10px
}

.uk-sortable {
  position: relative
}

.uk-sortable>:last-child {
  margin-bottom: 0
}

.uk-sortable-drag {
  position: fixed !important;
  z-index: 1050 !important;
  pointer-events: none
}

.uk-sortable-placeholder {
  opacity: 0;
  pointer-events: none
}

.uk-sortable-empty {
  min-height: 50px
}

.uk-sortable-handle:hover {
  cursor: move
}

.uk-countdown-number {
  font-variant-numeric: tabular-nums;
  font-size: 2rem;
  line-height: 0.8
}

@media (min-width: 640px) {
  .uk-countdown-number {
    font-size: 4rem
  }
}

@media (min-width: 960px) {
  .uk-countdown-number {
    font-size: 6rem
  }
}

.uk-countdown-separator {
  font-size: 1rem;
  line-height: 1.6
}

@media (min-width: 640px) {
  .uk-countdown-separator {
    font-size: 2rem
  }
}

@media (min-width: 960px) {
  .uk-countdown-separator {
    font-size: 3rem
  }
}

.uk-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none
}

.uk-grid>* {
  margin: 0
}

.uk-grid>*>:last-child {
  margin-bottom: 0
}

.uk-grid {
  margin-left: -30px
}

.uk-grid>* {
  padding-left: 30px
}

.uk-grid+.uk-grid,
.uk-grid>.uk-grid-margin,
*+.uk-grid-margin {
  margin-top: 30px
}

@media (min-width: 1200px) {
  .uk-grid {
    margin-left: -40px
  }

  .uk-grid>* {
    padding-left: 40px
  }

  .uk-grid+.uk-grid,
  .uk-grid>.uk-grid-margin,
  *+.uk-grid-margin {
    margin-top: 40px
  }
}

.uk-grid-small,
.uk-grid-column-small {
  margin-left: -15px
}

.uk-grid-small>*,
.uk-grid-column-small>* {
  padding-left: 15px
}

.uk-grid+.uk-grid-small,
.uk-grid+.uk-grid-row-small,
.uk-grid-small>.uk-grid-margin,
.uk-grid-row-small>.uk-grid-margin,
*+.uk-grid-margin-small {
  margin-top: 15px
}

.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -30px
}

.uk-grid-medium>*,
.uk-grid-column-medium>* {
  padding-left: 30px
}

.uk-grid+.uk-grid-medium,
.uk-grid+.uk-grid-row-medium,
.uk-grid-medium>.uk-grid-margin,
.uk-grid-row-medium>.uk-grid-margin,
*+.uk-grid-margin-medium {
  margin-top: 30px
}

.uk-grid-large,
.uk-grid-column-large {
  margin-left: -40px
}

.uk-grid-large>*,
.uk-grid-column-large>* {
  padding-left: 40px
}

.uk-grid+.uk-grid-large,
.uk-grid+.uk-grid-row-large,
.uk-grid-large>.uk-grid-margin,
.uk-grid-row-large>.uk-grid-margin,
*+.uk-grid-margin-large {
  margin-top: 40px
}

@media (min-width: 1200px) {

  .uk-grid-large,
  .uk-grid-column-large {
    margin-left: -70px
  }

  .uk-grid-large>*,
  .uk-grid-column-large>* {
    padding-left: 70px
  }

  .uk-grid+.uk-grid-large,
  .uk-grid+.uk-grid-row-large,
  .uk-grid-large>.uk-grid-margin,
  .uk-grid-row-large>.uk-grid-margin,
  *+.uk-grid-margin-large {
    margin-top: 70px
  }
}

.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0
}

.uk-grid-collapse>*,
.uk-grid-column-collapse>* {
  padding-left: 0
}

.uk-grid+.uk-grid-collapse,
.uk-grid+.uk-grid-row-collapse,
.uk-grid-collapse>.uk-grid-margin,
.uk-grid-row-collapse>.uk-grid-margin {
  margin-top: 0
}

.uk-grid-divider>* {
  position: relative
}

.uk-grid-divider>:not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5
}

.uk-grid-divider.uk-grid-stack>.uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5
}

.uk-grid-divider {
  margin-left: -60px
}

.uk-grid-divider>* {
  padding-left: 60px
}

.uk-grid-divider>:not(.uk-first-column)::before {
  left: 30px
}

.uk-grid-divider.uk-grid-stack>.uk-grid-margin {
  margin-top: 60px
}

.uk-grid-divider.uk-grid-stack>.uk-grid-margin::before {
  top: -30px;
  left: 60px
}

@media (min-width: 1200px) {
  .uk-grid-divider {
    margin-left: -80px
  }

  .uk-grid-divider>* {
    padding-left: 80px
  }

  .uk-grid-divider>:not(.uk-first-column)::before {
    left: 40px
  }

  .uk-grid-divider.uk-grid-stack>.uk-grid-margin {
    margin-top: 80px
  }

  .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before {
    top: -40px;
    left: 80px
  }
}

.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -30px
}

.uk-grid-divider.uk-grid-small>*,
.uk-grid-divider.uk-grid-column-small>* {
  padding-left: 30px
}

.uk-grid-divider.uk-grid-small>:not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small>:not(.uk-first-column)::before {
  left: 15px
}

.uk-grid-divider.uk-grid-small.uk-grid-stack>.uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack>.uk-grid-margin {
  margin-top: 30px
}

.uk-grid-divider.uk-grid-small.uk-grid-stack>.uk-grid-margin::before {
  top: -15px;
  left: 30px
}

.uk-grid-divider.uk-grid-row-small.uk-grid-stack>.uk-grid-margin::before {
  top: -15px
}

.uk-grid-divider.uk-grid-column-small.uk-grid-stack>.uk-grid-margin::before {
  left: 30px
}

.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -60px
}

.uk-grid-divider.uk-grid-medium>*,
.uk-grid-divider.uk-grid-column-medium>* {
  padding-left: 60px
}

.uk-grid-divider.uk-grid-medium>:not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium>:not(.uk-first-column)::before {
  left: 30px
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack>.uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack>.uk-grid-margin {
  margin-top: 60px
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack>.uk-grid-margin::before {
  top: -30px;
  left: 60px
}

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack>.uk-grid-margin::before {
  top: -30px
}

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack>.uk-grid-margin::before {
  left: 60px
}

.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -80px
}

.uk-grid-divider.uk-grid-large>*,
.uk-grid-divider.uk-grid-column-large>* {
  padding-left: 80px
}

.uk-grid-divider.uk-grid-large>:not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large>:not(.uk-first-column)::before {
  left: 40px
}

.uk-grid-divider.uk-grid-large.uk-grid-stack>.uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack>.uk-grid-margin {
  margin-top: 80px
}

.uk-grid-divider.uk-grid-large.uk-grid-stack>.uk-grid-margin::before {
  top: -40px;
  left: 80px
}

.uk-grid-divider.uk-grid-row-large.uk-grid-stack>.uk-grid-margin::before {
  top: -40px
}

.uk-grid-divider.uk-grid-column-large.uk-grid-stack>.uk-grid-margin::before {
  left: 80px
}

@media (min-width: 1200px) {

  .uk-grid-divider.uk-grid-large,
  .uk-grid-divider.uk-grid-column-large {
    margin-left: -140px
  }

  .uk-grid-divider.uk-grid-large>*,
  .uk-grid-divider.uk-grid-column-large>* {
    padding-left: 140px
  }

  .uk-grid-divider.uk-grid-large>:not(.uk-first-column)::before,
  .uk-grid-divider.uk-grid-column-large>:not(.uk-first-column)::before {
    left: 70px
  }

  .uk-grid-divider.uk-grid-large.uk-grid-stack>.uk-grid-margin,
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack>.uk-grid-margin {
    margin-top: 140px
  }

  .uk-grid-divider.uk-grid-large.uk-grid-stack>.uk-grid-margin::before {
    top: -70px;
    left: 140px
  }

  .uk-grid-divider.uk-grid-row-large.uk-grid-stack>.uk-grid-margin::before {
    top: -70px
  }

  .uk-grid-divider.uk-grid-column-large.uk-grid-stack>.uk-grid-margin::before {
    left: 140px
  }
}

.uk-grid-match>*,
.uk-grid-item-match {
  display: flex;
  flex-wrap: wrap
}

.uk-grid-match>*>:not([class*='uk-width']),
.uk-grid-item-match>:not([class*='uk-width']) {
  box-sizing: border-box;
  width: 100%;
  flex: auto
}

.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none
}

.uk-nav li>a {
  display: block;
  text-decoration: none
}

.uk-nav li>a:focus {
  outline: none
}

.uk-nav>li>a {
  padding: 5px 0
}

ul.uk-nav-sub {
  padding: 5px 0 5px 15px
}

.uk-nav-sub ul {
  padding-left: 15px
}

.uk-nav-sub a {
  padding: 2px 0
}

.uk-nav-parent-icon>.uk-parent>a::after {
  content: "";
  width: 1.5em;
  height: 1.5em;
  float: right;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%
}

.uk-nav-parent-icon>.uk-parent.uk-open>a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-nav-header {
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 0.875rem
}

.uk-nav-header:not(:first-child) {
  margin-top: 20px
}

.uk-nav-divider {
  margin: 5px 0
}

.uk-nav-default {
  font-size: 0.875rem
}

.uk-nav-default>li>a {
  color: #999
}

.uk-nav-default>li>a:hover,
.uk-nav-default>li>a:focus {
  color: #666
}

.uk-nav-default>li.uk-active>a {
  color: #333
}

.uk-nav-default .uk-nav-header {
  color: #333
}

.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #e5e5e5
}

.uk-nav-default .uk-nav-sub a {
  color: #999
}

.uk-nav-default .uk-nav-sub a:hover,
.uk-nav-default .uk-nav-sub a:focus {
  color: #666
}

.uk-nav-default .uk-nav-sub li.uk-active>a {
  color: #333
}

.uk-nav-primary>li>a {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #999
}

.uk-nav-primary>li>a:hover,
.uk-nav-primary>li>a:focus {
  color: #666
}

.uk-nav-primary>li.uk-active>a {
  color: #333
}

.uk-nav-primary .uk-nav-header {
  color: #333
}

.uk-nav-primary .uk-nav-divider {
  border-top: 1px solid #e5e5e5
}

.uk-nav-primary .uk-nav-sub a {
  color: #999
}

.uk-nav-primary .uk-nav-sub a:hover,
.uk-nav-primary .uk-nav-sub a:focus {
  color: #666
}

.uk-nav-primary .uk-nav-sub li.uk-active>a {
  color: #333
}

.uk-nav-center {
  text-align: center
}

.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
  padding-left: 0
}

.uk-nav-center.uk-nav-parent-icon>.uk-parent>a::after {
  position: absolute
}

.uk-navbar {
  display: flex;
  position: relative
}

.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #f8f8f8
}

.uk-navbar-container>::before,
.uk-navbar-container>::after {
  display: none !important
}

.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center,
.uk-navbar-center-left>*,
.uk-navbar-center-right>* {
  display: flex;
  align-items: center
}

.uk-navbar-right {
  margin-left: auto
}

.uk-navbar-center:only-child {
  margin-left: auto;
  margin-right: auto;
  position: relative
}

.uk-navbar-center:not(:only-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  box-sizing: border-box;
  z-index: 990
}

.uk-navbar-center-left,
.uk-navbar-center-right {
  position: absolute;
  top: 0
}

.uk-navbar-center-left {
  right: 100%
}

.uk-navbar-center-right {
  left: 100%
}

[class*='uk-navbar-center-'] {
  width: max-content;
  box-sizing: border-box
}

.uk-navbar-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none
}

.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center:only-child {
  flex-wrap: wrap
}

.uk-navbar-nav>li>a,
.uk-navbar-item,
.uk-navbar-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 80px;
  padding: 0 15px;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-decoration: none
}

.uk-navbar-nav>li>a {
  color: #999;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color
}

.uk-navbar-nav>li:hover>a,
.uk-navbar-nav>li>a:focus,
.uk-navbar-nav>li>a.uk-open {
  color: #666;
  outline: none
}

.uk-navbar-nav>li>a:active {
  color: #333
}

.uk-navbar-nav>li.uk-active>a {
  color: #333
}

.uk-navbar-item {
  color: #666
}

.uk-navbar-toggle {
  color: #999
}

.uk-navbar-toggle:hover,
.uk-navbar-toggle:focus,
.uk-navbar-toggle.uk-open {
  color: #666;
  outline: none;
  text-decoration: none
}

.uk-navbar-subtitle {
  font-size: 0.875rem
}

.uk-navbar-dropdown {
  display: none;
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  width: 200px;
  padding: 25px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15)
}

.uk-navbar-dropdown.uk-open {
  display: block
}

[class*='uk-navbar-dropdown-top'] {
  margin-top: -15px
}

[class*='uk-navbar-dropdown-bottom'] {
  margin-top: 15px
}

[class*='uk-navbar-dropdown-left'] {
  margin-left: -15px
}

[class*='uk-navbar-dropdown-right'] {
  margin-left: 15px
}

.uk-navbar-dropdown-grid {
  margin-left: -50px
}

.uk-navbar-dropdown-grid>* {
  padding-left: 50px
}

.uk-navbar-dropdown-grid>.uk-grid-margin {
  margin-top: 50px
}

.uk-navbar-dropdown-stack .uk-navbar-dropdown-grid>* {
  width: 100% !important
}

.uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
  width: 400px
}

.uk-navbar-dropdown-width-3:not(.uk-navbar-dropdown-stack) {
  width: 600px
}

.uk-navbar-dropdown-width-4:not(.uk-navbar-dropdown-stack) {
  width: 800px
}

.uk-navbar-dropdown-width-5:not(.uk-navbar-dropdown-stack) {
  width: 1000px
}

.uk-navbar-dropdown-dropbar {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: none
}

.uk-navbar-dropdown-nav {
  font-size: 0.875rem
}

.uk-navbar-dropdown-nav>li>a {
  color: #999
}

.uk-navbar-dropdown-nav>li>a:hover,
.uk-navbar-dropdown-nav>li>a:focus {
  color: #666
}

.uk-navbar-dropdown-nav>li.uk-active>a {
  color: #333
}

.uk-navbar-dropdown-nav .uk-nav-header {
  color: #333
}

.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5
}

.uk-navbar-dropdown-nav .uk-nav-sub a {
  color: #999
}

.uk-navbar-dropdown-nav .uk-nav-sub a:hover,
.uk-navbar-dropdown-nav .uk-nav-sub a:focus {
  color: #666
}

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active>a {
  color: #333
}

.uk-navbar-dropbar {
  background: #fff
}

.uk-navbar-dropbar-slide {
  position: absolute;
  z-index: 980;
  left: 0;
  right: 0;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.05)
}

.uk-navbar-container>.uk-container .uk-navbar-left {
  margin-left: -15px;
  margin-right: -15px
}

.uk-navbar-container>.uk-container .uk-navbar-right {
  margin-right: -15px
}

.uk-navbar-dropdown-grid>* {
  position: relative
}

.uk-navbar-dropdown-grid>:not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 25px;
  border-left: 1px solid #e5e5e5
}

.uk-navbar-dropdown-grid.uk-grid-stack>.uk-grid-margin::before {
  content: "";
  position: absolute;
  top: -25px;
  left: 50px;
  right: 0;
  border-top: 1px solid #e5e5e5
}

.uk-subnav {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding: 0;
  list-style: none
}

.uk-subnav>* {
  flex: none;
  padding-left: 20px;
  position: relative
}

.uk-subnav>*>:first-child {
  display: block;
  color: #999;
  font-size: 0.875rem;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color
}

.uk-subnav>*>a:hover,
.uk-subnav>*>a:focus {
  color: #666;
  text-decoration: none;
  outline: none
}

.uk-subnav>.uk-active>a {
  color: #333
}

.uk-subnav-divider {
  margin-left: -41px
}

.uk-subnav-divider>* {
  display: flex;
  align-items: center
}

.uk-subnav-divider>::before {
  content: "";
  height: 1.5em;
  margin-left: 0px;
  margin-right: 20px;
  border-left: 1px solid transparent
}

.uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: #e5e5e5
}

.uk-subnav-pill>*>:first-child {
  padding: 5px 10px;
  background: transparent;
  color: #999
}

.uk-subnav-pill>*>a:hover,
.uk-subnav-pill>*>a:focus {
  background-color: #f8f8f8;
  color: #666
}

.uk-subnav-pill>*>a:active {
  background-color: #f8f8f8;
  color: #666
}

.uk-subnav-pill>.uk-active>a {
  background-color: #1e87f0;
  color: #fff
}

.uk-subnav>.uk-disabled>a {
  color: #999
}

.uk-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none
}

.uk-breadcrumb>* {
  flex: none
}

.uk-breadcrumb>*>* {
  display: inline-block;
  font-size: 0.875rem;
  color: #999
}

.uk-breadcrumb>*>:hover,
.uk-breadcrumb>*>:focus {
  color: #666;
  text-decoration: none
}

.uk-breadcrumb>:last-child>span,
.uk-breadcrumb>:last-child>a:not([href]) {
  color: #666
}

.uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before {
  content: "/";
  display: inline-block;
  margin: 0 20px;
  font-size: 0.875rem;
  color: #999
}

.uk-pagination {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding: 0;
  list-style: none
}

.uk-pagination>* {
  flex: none;
  padding-left: 20px;
  position: relative
}

.uk-pagination>*>* {
  display: block;
  color: #999;
  transition: color 0.1s ease-in-out
}

.uk-pagination>*>:hover,
.uk-pagination>*>:focus {
  color: #666;
  text-decoration: none
}

.uk-pagination>.uk-active>* {
  color: #666
}

.uk-pagination>.uk-disabled>* {
  color: #999
}

.uk-tab {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding: 0;
  list-style: none;
  position: relative
}

.uk-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  border-bottom: 1px solid #e5e5e5
}

.uk-tab>* {
  flex: none;
  padding-left: 20px;
  position: relative
}

.uk-tab>*>a {
  display: block;
  text-align: center;
  padding: 5px 10px;
  color: #999;
  border-bottom: 1px solid transparent;
  font-size: 0.875rem;
  text-transform: uppercase;
  transition: color 0.1s ease-in-out
}

.uk-tab>*>a:hover,
.uk-tab>*>a:focus {
  color: #666;
  text-decoration: none
}

.uk-tab>.uk-active>a {
  color: #333;
  border-color: #1e87f0
}

.uk-tab>.uk-disabled>a {
  color: #999
}

.uk-tab-bottom::before {
  top: 0;
  bottom: auto
}

.uk-tab-bottom>*>a {
  border-top: 1px solid transparent;
  border-bottom: none
}

.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  margin-left: 0
}

.uk-tab-left>*,
.uk-tab-right>* {
  padding-left: 0
}

.uk-tab-left::before {
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  border-left: 1px solid #e5e5e5;
  border-bottom: none
}

.uk-tab-right::before {
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
  border-left: 1px solid #e5e5e5;
  border-bottom: none
}

.uk-tab-left>*>a {
  text-align: left;
  border-right: 1px solid transparent;
  border-bottom: none
}

.uk-tab-right>*>a {
  text-align: left;
  border-left: 1px solid transparent;
  border-bottom: none
}

.uk-tab .uk-dropdown {
  margin-left: 30px
}

.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5);
  transition: color 0.1s ease-in-out
}

.uk-slidenav:hover,
.uk-slidenav:focus {
  color: rgba(102, 102, 102, 0.9);
  outline: none
}

.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5)
}

.uk-slidenav-large {
  padding: 10px 10px
}

.uk-slidenav-container {
  display: flex
}

.uk-dotnav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -12px
}

.uk-dotnav>* {
  flex: none;
  padding-left: 12px
}

.uk-dotnav>*>* {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid rgba(102, 102, 102, 0.4);
  transition: 0.2s ease-in-out;
  transition-property: background-color, border-color
}

.uk-dotnav>*>:hover,
.uk-dotnav>*>:focus {
  background-color: rgba(102, 102, 102, 0.6);
  outline: none;
  border-color: transparent
}

.uk-dotnav>*>:active {
  background-color: rgba(102, 102, 102, 0.2);
  border-color: transparent
}

.uk-dotnav>.uk-active>* {
  background-color: rgba(102, 102, 102, 0.6);
  border-color: transparent
}

.uk-dotnav-vertical {
  flex-direction: column;
  margin-left: 0;
  margin-top: -12px
}

.uk-dotnav-vertical>* {
  padding-left: 0;
  padding-top: 12px
}

.uk-thumbnav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -15px
}

.uk-thumbnav>* {
  padding-left: 15px
}

.uk-thumbnav>*>* {
  display: inline-block;
  position: relative
}

.uk-thumbnav>*>*::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
  transition: background-color 0.1s ease-in-out
}

.uk-thumbnav>*>:hover,
.uk-thumbnav>*>:focus {
  outline: none
}

.uk-thumbnav>*>:hover::after,
.uk-thumbnav>*>:focus::after {
  background-color: transparent
}

.uk-thumbnav>.uk-active>*::after {
  background-color: transparent
}

.uk-thumbnav-vertical {
  flex-direction: column;
  margin-left: 0;
  margin-top: -15px
}

.uk-thumbnav-vertical>* {
  padding-left: 0;
  padding-top: 15px
}

.uk-iconnav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -10px
}

.uk-iconnav>* {
  padding-left: 10px
}

.uk-iconnav>*>a {
  display: block;
  color: #999
}

.uk-iconnav>*>a:hover,
.uk-iconnav>*>a:focus {
  color: #666;
  outline: none
}

.uk-iconnav>.uk-active>a {
  color: #666
}

.uk-iconnav-vertical {
  flex-direction: column;
  margin-left: 0;
  margin-top: -10px
}

.uk-iconnav-vertical>* {
  padding-left: 0;
  padding-top: 10px
}

[class*='uk-animation-'] {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: both
}

.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear
}

.uk-animation-scale-up {
  animation-name: uk-fade-scale-02
}

.uk-animation-scale-down {
  animation-name: uk-fade-scale-18
}

.uk-animation-slide-top {
  animation-name: uk-fade-top
}

.uk-animation-slide-bottom {
  animation-name: uk-fade-bottom
}

.uk-animation-slide-left {
  animation-name: uk-fade-left
}

.uk-animation-slide-right {
  animation-name: uk-fade-right
}

.uk-animation-slide-top-small {
  animation-name: uk-fade-top-small
}

.uk-animation-slide-bottom-small {
  animation-name: uk-fade-bottom-small
}

.uk-animation-slide-left-small {
  animation-name: uk-fade-left-small
}

.uk-animation-slide-right-small {
  animation-name: uk-fade-right-small
}

.uk-animation-slide-top-medium {
  animation-name: uk-fade-top-medium
}

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade-bottom-medium
}

.uk-animation-slide-left-medium {
  animation-name: uk-fade-left-medium
}

.uk-animation-slide-right-medium {
  animation-name: uk-fade-right-medium
}

.uk-animation-kenburns {
  animation-name: uk-scale-kenburns;
  animation-duration: 15s
}

.uk-animation-shake {
  animation-name: uk-shake
}

.uk-animation-stroke {
  animation-name: uk-stroke;
  stroke-dasharray: var(--uk-animation-stroke);
  animation-duration: 2s
}

.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in
}

.uk-animation-fast {
  animation-duration: 0.1s
}

.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
  animation-name: none
}

.uk-animation-toggle {
  -webkit-tap-highlight-color: transparent
}

.uk-animation-toggle:focus {
  outline: none
}

@keyframes uk-fade {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes uk-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes uk-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes uk-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes uk-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes uk-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes uk-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes uk-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes uk-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2)
  }

  100% {
    opacity: 1;
    transform: scale(1)
  }
}

@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8)
  }

  100% {
    opacity: 1;
    transform: scale(1)
  }
}

@keyframes uk-scale-kenburns {
  0% {
    transform: scale(1)
  }

  100% {
    transform: scale(1.2)
  }
}

@keyframes uk-shake {

  0%,
  100% {
    transform: translateX(0)
  }

  10% {
    transform: translateX(-9px)
  }

  20% {
    transform: translateX(8px)
  }

  30% {
    transform: translateX(-7px)
  }

  40% {
    transform: translateX(6px)
  }

  50% {
    transform: translateX(-5px)
  }

  60% {
    transform: translateX(4px)
  }

  70% {
    transform: translateX(-3px)
  }

  80% {
    transform: translateX(2px)
  }

  90% {
    transform: translateX(-1px)
  }
}

@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke)
  }

  100% {
    stroke-dashoffset: 0
  }
}

[class*='uk-child-width']>* {
  box-sizing: border-box;
  width: 100%
}

.uk-child-width-1-2>* {
  width: 50%
}

.uk-child-width-1-3>* {
  width: calc(100% * 1 / 3.001)
}

.uk-child-width-1-4>* {
  width: 25%
}

.uk-child-width-1-5>* {
  width: 20%
}

.uk-child-width-1-6>* {
  width: calc(100% * 1 / 6.001)
}

.uk-child-width-auto>* {
  width: auto
}

.uk-child-width-expand>:not([class*='uk-width']) {
  flex: 1;
  min-width: 1px
}

@media (min-width: 640px) {
  .uk-child-width-1-1\@s>* {
    width: 100%
  }

  .uk-child-width-1-2\@s>* {
    width: 50%
  }

  .uk-child-width-1-3\@s>* {
    width: calc(100% * 1 / 3.001)
  }

  .uk-child-width-1-4\@s>* {
    width: 25%
  }

  .uk-child-width-1-5\@s>* {
    width: 20%
  }

  .uk-child-width-1-6\@s>* {
    width: calc(100% * 1 / 6.001)
  }

  .uk-child-width-auto\@s>* {
    width: auto
  }

  .uk-child-width-expand\@s>:not([class*='uk-width']) {
    flex: 1;
    min-width: 1px
  }
}

@media (min-width: 960px) {
  .uk-child-width-1-1\@m>* {
    width: 100%
  }

  .uk-child-width-1-2\@m>* {
    width: 50%
  }

  .uk-child-width-1-3\@m>* {
    width: calc(100% * 1 / 3.001)
  }

  .uk-child-width-1-4\@m>* {
    width: 25%
  }

  .uk-child-width-1-5\@m>* {
    width: 20%
  }

  .uk-child-width-1-6\@m>* {
    width: calc(100% * 1 / 6.001)
  }

  .uk-child-width-auto\@m>* {
    width: auto
  }

  .uk-child-width-expand\@m>:not([class*='uk-width']) {
    flex: 1;
    min-width: 1px
  }
}

@media (min-width: 1200px) {
  .uk-child-width-1-1\@l>* {
    width: 100%
  }

  .uk-child-width-1-2\@l>* {
    width: 50%
  }

  .uk-child-width-1-3\@l>* {
    width: calc(100% * 1 / 3.001)
  }

  .uk-child-width-1-4\@l>* {
    width: 25%
  }

  .uk-child-width-1-5\@l>* {
    width: 20%
  }

  .uk-child-width-1-6\@l>* {
    width: calc(100% * 1 / 6.001)
  }

  .uk-child-width-auto\@l>* {
    width: auto
  }

  .uk-child-width-expand\@l>:not([class*='uk-width']) {
    flex: 1;
    min-width: 1px
  }
}

@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl>* {
    width: 100%
  }

  .uk-child-width-1-2\@xl>* {
    width: 50%
  }

  .uk-child-width-1-3\@xl>* {
    width: calc(100% * 1 / 3.001)
  }

  .uk-child-width-1-4\@xl>* {
    width: 25%
  }

  .uk-child-width-1-5\@xl>* {
    width: 20%
  }

  .uk-child-width-1-6\@xl>* {
    width: calc(100% * 1 / 6.001)
  }

  .uk-child-width-auto\@xl>* {
    width: auto
  }

  .uk-child-width-expand\@xl>:not([class*='uk-width']) {
    flex: 1;
    min-width: 1px
  }
}

[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%
}

.uk-width-1-2 {
  width: 50%
}

.uk-width-1-3 {
  width: calc(100% * 1 / 3.001)
}

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001)
}

.uk-width-1-4 {
  width: 25%
}

.uk-width-3-4 {
  width: 75%
}

.uk-width-1-5 {
  width: 20%
}

.uk-width-2-5 {
  width: 40%
}

.uk-width-3-5 {
  width: 60%
}

.uk-width-4-5 {
  width: 80%
}

.uk-width-1-6 {
  width: calc(100% * 1 / 6.001)
}

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001)
}

.uk-width-small {
  width: 150px
}

.uk-width-medium {
  width: 300px
}

.uk-width-large {
  width: 450px
}

.uk-width-xlarge {
  width: 600px
}

.uk-width-2xlarge {
  width: 750px
}

.uk-width-auto {
  width: auto
}

.uk-width-expand {
  flex: 1;
  min-width: 1px
}

@media (min-width: 640px) {
  .uk-width-1-1\@s {
    width: 100%
  }

  .uk-width-1-2\@s {
    width: 50%
  }

  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001)
  }

  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001)
  }

  .uk-width-1-4\@s {
    width: 25%
  }

  .uk-width-3-4\@s {
    width: 75%
  }

  .uk-width-1-5\@s {
    width: 20%
  }

  .uk-width-2-5\@s {
    width: 40%
  }

  .uk-width-3-5\@s {
    width: 60%
  }

  .uk-width-4-5\@s {
    width: 80%
  }

  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001)
  }

  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001)
  }

  .uk-width-small\@s {
    width: 150px
  }

  .uk-width-medium\@s {
    width: 300px
  }

  .uk-width-large\@s {
    width: 450px
  }

  .uk-width-xlarge\@s {
    width: 600px
  }

  .uk-width-2xlarge\@s {
    width: 750px
  }

  .uk-width-auto\@s {
    width: auto
  }

  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px
  }
}

@media (min-width: 960px) {
  .uk-width-1-1\@m {
    width: 100%
  }

  .uk-width-1-2\@m {
    width: 50%
  }

  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001)
  }

  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001)
  }

  .uk-width-1-4\@m {
    width: 25%
  }

  .uk-width-3-4\@m {
    width: 75%
  }

  .uk-width-1-5\@m {
    width: 20%
  }

  .uk-width-2-5\@m {
    width: 40%
  }

  .uk-width-3-5\@m {
    width: 60%
  }

  .uk-width-4-5\@m {
    width: 80%
  }

  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001)
  }

  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001)
  }

  .uk-width-small\@m {
    width: 150px
  }

  .uk-width-medium\@m {
    width: 300px
  }

  .uk-width-large\@m {
    width: 450px
  }

  .uk-width-xlarge\@m {
    width: 600px
  }

  .uk-width-2xlarge\@m {
    width: 750px
  }

  .uk-width-auto\@m {
    width: auto
  }

  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px
  }
}

@media (min-width: 1200px) {
  .uk-width-1-1\@l {
    width: 100%
  }

  .uk-width-1-2\@l {
    width: 50%
  }

  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001)
  }

  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001)
  }

  .uk-width-1-4\@l {
    width: 25%
  }

  .uk-width-3-4\@l {
    width: 75%
  }

  .uk-width-1-5\@l {
    width: 20%
  }

  .uk-width-2-5\@l {
    width: 40%
  }

  .uk-width-3-5\@l {
    width: 60%
  }

  .uk-width-4-5\@l {
    width: 80%
  }

  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001)
  }

  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001)
  }

  .uk-width-small\@l {
    width: 150px
  }

  .uk-width-medium\@l {
    width: 300px
  }

  .uk-width-large\@l {
    width: 450px
  }

  .uk-width-xlarge\@l {
    width: 600px
  }

  .uk-width-2xlarge\@l {
    width: 750px
  }

  .uk-width-auto\@l {
    width: auto
  }

  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px
  }
}

@media (min-width: 1600px) {
  .uk-width-1-1\@xl {
    width: 100%
  }

  .uk-width-1-2\@xl {
    width: 50%
  }

  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001)
  }

  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001)
  }

  .uk-width-1-4\@xl {
    width: 25%
  }

  .uk-width-3-4\@xl {
    width: 75%
  }

  .uk-width-1-5\@xl {
    width: 20%
  }

  .uk-width-2-5\@xl {
    width: 40%
  }

  .uk-width-3-5\@xl {
    width: 60%
  }

  .uk-width-4-5\@xl {
    width: 80%
  }

  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001)
  }

  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001)
  }

  .uk-width-small\@xl {
    width: 150px
  }

  .uk-width-medium\@xl {
    width: 300px
  }

  .uk-width-large\@xl {
    width: 450px
  }

  .uk-width-xlarge\@xl {
    width: 600px
  }

  .uk-width-2xlarge\@xl {
    width: 750px
  }

  .uk-width-auto\@xl {
    width: auto
  }

  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px
  }
}

[class*='uk-height'] {
  box-sizing: border-box
}

.uk-height-1-1 {
  height: 100%
}

.uk-height-viewport {
  min-height: 100vh
}

.uk-height-small {
  height: 150px
}

.uk-height-medium {
  height: 300px
}

.uk-height-large {
  height: 450px
}

.uk-height-max-small {
  max-height: 150px
}

.uk-height-max-medium {
  max-height: 300px
}

.uk-height-max-large {
  max-height: 450px
}

.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333
}

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999
}

.uk-text-meta a {
  color: #999
}

.uk-text-meta a:hover {
  color: #666;
  text-decoration: none
}

.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5
}

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5
}

.uk-text-default {
  font-size: 16px;
  line-height: 1.5
}

.uk-text-light {
  font-weight: 300
}

.uk-text-normal {
  font-weight: 400
}

.uk-text-bold {
  font-weight: 700
}

.uk-text-lighter {
  font-weight: lighter
}

.uk-text-bolder {
  font-weight: bolder
}

.uk-text-italic {
  font-style: italic
}

.uk-text-capitalize {
  text-transform: capitalize !important
}

.uk-text-uppercase {
  text-transform: uppercase !important
}

.uk-text-lowercase {
  text-transform: lowercase !important
}

.uk-text-muted {
  color: #999 !important
}

.uk-text-emphasis {
  color: #333 !important
}

.uk-text-primary {
  color: #1e87f0 !important
}

.uk-text-secondary {
  color: #222 !important
}

.uk-text-success {
  color: #32d296 !important
}

.uk-text-warning {
  color: #faa05a !important
}

.uk-text-danger {
  color: #f0506e !important
}

.uk-text-background {
  -webkit-background-clip: text;
  display: inline-block;
  color: #1e87f0 !important
}

@supports (-webkit-background-clip: text) {
  .uk-text-background {
    background-color: #1e87f0;
    color: transparent !important
  }
}

.uk-text-left {
  text-align: left !important
}

.uk-text-right {
  text-align: right !important
}

.uk-text-center {
  text-align: center !important
}

.uk-text-justify {
  text-align: justify !important
}

@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important
  }

  .uk-text-right\@s {
    text-align: right !important
  }

  .uk-text-center\@s {
    text-align: center !important
  }
}

@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important
  }

  .uk-text-right\@m {
    text-align: right !important
  }

  .uk-text-center\@m {
    text-align: center !important
  }
}

@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important
  }

  .uk-text-right\@l {
    text-align: right !important
  }

  .uk-text-center\@l {
    text-align: center !important
  }
}

@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important
  }

  .uk-text-right\@xl {
    text-align: right !important
  }

  .uk-text-center\@xl {
    text-align: center !important
  }
}

.uk-text-top {
  vertical-align: top !important
}

.uk-text-middle {
  vertical-align: middle !important
}

.uk-text-bottom {
  vertical-align: bottom !important
}

.uk-text-baseline {
  vertical-align: baseline !important
}

.uk-text-nowrap {
  white-space: nowrap
}

.uk-text-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0
}

.uk-text-break {
  overflow-wrap: break-word;
  word-wrap: break-word
}

th.uk-text-break,
td.uk-text-break {
  word-break: break-all
}

[class*='uk-column-'] {
  column-gap: 30px
}

@media (min-width: 1200px) {
  [class*='uk-column-'] {
    column-gap: 40px
  }
}

[class*='uk-column-'] img {
  transform: translate3d(0, 0, 0)
}

.uk-column-divider {
  column-rule: 1px solid #e5e5e5;
  column-gap: 60px
}

@media (min-width: 1200px) {
  .uk-column-divider {
    column-gap: 80px
  }
}

.uk-column-1-2 {
  column-count: 2
}

.uk-column-1-3 {
  column-count: 3
}

.uk-column-1-4 {
  column-count: 4
}

.uk-column-1-5 {
  column-count: 5
}

.uk-column-1-6 {
  column-count: 6
}

@media (min-width: 640px) {
  .uk-column-1-2\@s {
    column-count: 2
  }

  .uk-column-1-3\@s {
    column-count: 3
  }

  .uk-column-1-4\@s {
    column-count: 4
  }

  .uk-column-1-5\@s {
    column-count: 5
  }

  .uk-column-1-6\@s {
    column-count: 6
  }
}

@media (min-width: 960px) {
  .uk-column-1-2\@m {
    column-count: 2
  }

  .uk-column-1-3\@m {
    column-count: 3
  }

  .uk-column-1-4\@m {
    column-count: 4
  }

  .uk-column-1-5\@m {
    column-count: 5
  }

  .uk-column-1-6\@m {
    column-count: 6
  }
}

@media (min-width: 1200px) {
  .uk-column-1-2\@l {
    column-count: 2
  }

  .uk-column-1-3\@l {
    column-count: 3
  }

  .uk-column-1-4\@l {
    column-count: 4
  }

  .uk-column-1-5\@l {
    column-count: 5
  }

  .uk-column-1-6\@l {
    column-count: 6
  }
}

@media (min-width: 1600px) {
  .uk-column-1-2\@xl {
    column-count: 2
  }

  .uk-column-1-3\@xl {
    column-count: 3
  }

  .uk-column-1-4\@xl {
    column-count: 4
  }

  .uk-column-1-5\@xl {
    column-count: 5
  }

  .uk-column-1-6\@xl {
    column-count: 6
  }
}

.uk-column-span {
  column-span: all
}

.uk-cover {
  max-width: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}

iframe.uk-cover {
  pointer-events: none
}

.uk-cover-container {
  overflow: hidden;
  position: relative
}

.uk-background-default {
  background-color: #fff
}

.uk-background-muted {
  background-color: #f8f8f8
}

.uk-background-primary {
  background-color: #1e87f0
}

.uk-background-secondary {
  background-color: #222
}

.uk-background-cover,
.uk-background-contain {
  background-position: 50% 50%;
  background-repeat: no-repeat
}

.uk-background-cover {
  background-size: cover
}

.uk-background-contain {
  background-size: contain
}

.uk-background-top-left {
  background-position: 0 0
}

.uk-background-top-center {
  background-position: 50% 0
}

.uk-background-top-right {
  background-position: 100% 0
}

.uk-background-center-left {
  background-position: 0 50%
}

.uk-background-center-center {
  background-position: 50% 50%
}

.uk-background-center-right {
  background-position: 100% 50%
}

.uk-background-bottom-left {
  background-position: 0 100%
}

.uk-background-bottom-center {
  background-position: 50% 100%
}

.uk-background-bottom-right {
  background-position: 100% 100%
}

.uk-background-norepeat {
  background-repeat: no-repeat
}

.uk-background-fixed {
  background-attachment: fixed;
  backface-visibility: hidden
}

@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll
  }
}

@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important
  }
}

@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important
  }
}

@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important
  }
}

@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important
  }
}

.uk-background-blend-multiply {
  background-blend-mode: multiply
}

.uk-background-blend-screen {
  background-blend-mode: screen
}

.uk-background-blend-overlay {
  background-blend-mode: overlay
}

.uk-background-blend-darken {
  background-blend-mode: darken
}

.uk-background-blend-lighten {
  background-blend-mode: lighten
}

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge
}

.uk-background-blend-color-burn {
  background-blend-mode: color-burn
}

.uk-background-blend-hard-light {
  background-blend-mode: hard-light
}

.uk-background-blend-soft-light {
  background-blend-mode: soft-light
}

.uk-background-blend-difference {
  background-blend-mode: difference
}

.uk-background-blend-exclusion {
  background-blend-mode: exclusion
}

.uk-background-blend-hue {
  background-blend-mode: hue
}

.uk-background-blend-saturation {
  background-blend-mode: saturation
}

.uk-background-blend-color {
  background-blend-mode: color
}

.uk-background-blend-luminosity {
  background-blend-mode: luminosity
}

[class*='uk-align'] {
  display: block;
  margin-bottom: 30px
}

*+[class*='uk-align'] {
  margin-top: 30px
}

.uk-align-center {
  margin-left: auto;
  margin-right: auto
}

.uk-align-left {
  margin-top: 0;
  margin-right: 30px;
  float: left
}

.uk-align-right {
  margin-top: 0;
  margin-left: 30px;
  float: right
}

@media (min-width: 640px) {
  .uk-align-left\@s {
    margin-top: 0;
    margin-right: 30px;
    float: left
  }

  .uk-align-right\@s {
    margin-top: 0;
    margin-left: 30px;
    float: right
  }
}

@media (min-width: 960px) {
  .uk-align-left\@m {
    margin-top: 0;
    margin-right: 30px;
    float: left
  }

  .uk-align-right\@m {
    margin-top: 0;
    margin-left: 30px;
    float: right
  }
}

@media (min-width: 1200px) {
  .uk-align-left\@l {
    margin-top: 0;
    float: left
  }

  .uk-align-right\@l {
    margin-top: 0;
    float: right
  }

  .uk-align-left,
  .uk-align-left\@s,
  .uk-align-left\@m,
  .uk-align-left\@l {
    margin-right: 40px
  }

  .uk-align-right,
  .uk-align-right\@s,
  .uk-align-right\@m,
  .uk-align-right\@l {
    margin-left: 40px
  }
}

@media (min-width: 1600px) {
  .uk-align-left\@xl {
    margin-top: 0;
    margin-right: 40px;
    float: left
  }

  .uk-align-right\@xl {
    margin-top: 0;
    margin-left: 40px;
    float: right
  }
}

.uk-svg,
.uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor
}

.uk-svg:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor
}

.uk-svg {
  transform: translate(0, 0)
}

.uk-panel {
  display: flow-root;
  position: relative;
  box-sizing: border-box
}

.uk-panel>:last-child {
  margin-bottom: 0
}

.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both
}

.uk-clearfix::before {
  content: "";
  display: table-cell
}

.uk-clearfix::after {
  content: "";
  display: table;
  clear: both
}

.uk-float-left {
  float: left
}

.uk-float-right {
  float: right
}

[class*='uk-float-'] {
  max-width: 100%
}

.uk-overflow-hidden {
  overflow: hidden
}

.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch
}

.uk-overflow-auto>:last-child {
  margin-bottom: 0
}

.uk-resize {
  resize: both
}

.uk-resize-vertical {
  resize: vertical
}

.uk-display-block {
  display: block !important
}

.uk-display-inline {
  display: inline !important
}

.uk-display-inline-block {
  display: inline-block !important
}

[class*='uk-inline'] {
  display: inline-block;
  position: relative;
  max-width: 100%;
  vertical-align: middle;
  -webkit-backface-visibility: hidden
}

.uk-inline-clip {
  overflow: hidden
}

.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none
}

.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box
}

.uk-responsive-width {
  max-width: 100% !important;
  height: auto
}

.uk-responsive-height {
  max-height: 100%;
  width: auto;
  max-width: none
}

.uk-border-circle {
  border-radius: 50%
}

.uk-border-pill {
  border-radius: 500px
}

.uk-border-rounded {
  border-radius: 5px
}

.uk-inline-clip[class*='uk-border-'] {
  -webkit-transform: translateZ(0)
}

.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08)
}

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16)
}

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16)
}

[class*='uk-box-shadow-hover'] {
  transition: box-shadow 0.1s ease-in-out
}

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08)
}

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16)
}

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16)
}

@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle
  }

  .uk-box-shadow-bottom::before {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px)
  }

  .uk-box-shadow-bottom>* {
    position: relative
  }
}

.uk-dropcap::first-letter,
.uk-dropcap>p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1;
  margin-bottom: -2px
}

@-moz-document url-prefix() {

  .uk-dropcap::first-letter,
  .uk-dropcap>p:first-of-type::first-letter {
    margin-top: 1.1%
  }
}

@supports (-ms-ime-align: auto) {
  .uk-dropcap>p:first-of-type::first-letter {
    font-size: 1em
  }
}

.uk-logo {
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #666;
  text-decoration: none
}

.uk-logo:hover,
.uk-logo:focus {
  color: #666;
  outline: none;
  text-decoration: none
}

.uk-logo-inverse {
  display: none
}

.uk-disabled {
  pointer-events: none
}

.uk-drag,
.uk-drag * {
  cursor: move
}

.uk-drag iframe {
  pointer-events: none
}

.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3)
}

.uk-blend-multiply {
  mix-blend-mode: multiply
}

.uk-blend-screen {
  mix-blend-mode: screen
}

.uk-blend-overlay {
  mix-blend-mode: overlay
}

.uk-blend-darken {
  mix-blend-mode: darken
}

.uk-blend-lighten {
  mix-blend-mode: lighten
}

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge
}

.uk-blend-color-burn {
  mix-blend-mode: color-burn
}

.uk-blend-hard-light {
  mix-blend-mode: hard-light
}

.uk-blend-soft-light {
  mix-blend-mode: soft-light
}

.uk-blend-difference {
  mix-blend-mode: difference
}

.uk-blend-exclusion {
  mix-blend-mode: exclusion
}

.uk-blend-hue {
  mix-blend-mode: hue
}

.uk-blend-saturation {
  mix-blend-mode: saturation
}

.uk-blend-color {
  mix-blend-mode: color
}

.uk-blend-luminosity {
  mix-blend-mode: luminosity
}

.uk-transform-center {
  transform: translate(-50%, -50%)
}

.uk-transform-origin-top-left {
  transform-origin: 0 0
}

.uk-transform-origin-top-center {
  transform-origin: 50% 0
}

.uk-transform-origin-top-right {
  transform-origin: 100% 0
}

.uk-transform-origin-center-left {
  transform-origin: 0 50%
}

.uk-transform-origin-center-right {
  transform-origin: 100% 50%
}

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%
}

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%
}

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%
}

.uk-flex {
  display: flex
}

.uk-flex-inline {
  display: inline-flex
}

.uk-flex::before,
.uk-flex::after,
.uk-flex-inline::before,
.uk-flex-inline::after {
  display: none
}

.uk-flex-left {
  justify-content: flex-start
}

.uk-flex-center {
  justify-content: center
}

.uk-flex-right {
  justify-content: flex-end
}

.uk-flex-between {
  justify-content: space-between
}

.uk-flex-around {
  justify-content: space-around
}

@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start
  }

  .uk-flex-center\@s {
    justify-content: center
  }

  .uk-flex-right\@s {
    justify-content: flex-end
  }

  .uk-flex-between\@s {
    justify-content: space-between
  }

  .uk-flex-around\@s {
    justify-content: space-around
  }
}

@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start
  }

  .uk-flex-center\@m {
    justify-content: center
  }

  .uk-flex-right\@m {
    justify-content: flex-end
  }

  .uk-flex-between\@m {
    justify-content: space-between
  }

  .uk-flex-around\@m {
    justify-content: space-around
  }
}

@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start
  }

  .uk-flex-center\@l {
    justify-content: center
  }

  .uk-flex-right\@l {
    justify-content: flex-end
  }

  .uk-flex-between\@l {
    justify-content: space-between
  }

  .uk-flex-around\@l {
    justify-content: space-around
  }
}

@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start
  }

  .uk-flex-center\@xl {
    justify-content: center
  }

  .uk-flex-right\@xl {
    justify-content: flex-end
  }

  .uk-flex-between\@xl {
    justify-content: space-between
  }

  .uk-flex-around\@xl {
    justify-content: space-around
  }
}

.uk-flex-stretch {
  align-items: stretch
}

.uk-flex-top {
  align-items: flex-start
}

.uk-flex-middle {
  align-items: center
}

.uk-flex-bottom {
  align-items: flex-end
}

.uk-flex-row {
  flex-direction: row
}

.uk-flex-row-reverse {
  flex-direction: row-reverse
}

.uk-flex-column {
  flex-direction: column
}

.uk-flex-column-reverse {
  flex-direction: column-reverse
}

.uk-flex-nowrap {
  flex-wrap: nowrap
}

.uk-flex-wrap {
  flex-wrap: wrap
}

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse
}

.uk-flex-wrap-stretch {
  align-content: stretch
}

.uk-flex-wrap-top {
  align-content: flex-start
}

.uk-flex-wrap-middle {
  align-content: center
}

.uk-flex-wrap-bottom {
  align-content: flex-end
}

.uk-flex-wrap-between {
  align-content: space-between
}

.uk-flex-wrap-around {
  align-content: space-around
}

.uk-flex-first {
  order: -1
}

.uk-flex-last {
  order: 99
}

@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1
  }

  .uk-flex-last\@s {
    order: 99
  }
}

@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1
  }

  .uk-flex-last\@m {
    order: 99
  }
}

@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1
  }

  .uk-flex-last\@l {
    order: 99
  }
}

@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1
  }

  .uk-flex-last\@xl {
    order: 99
  }
}

.uk-flex-none {
  flex: none
}

.uk-flex-auto {
  flex: auto
}

.uk-flex-1 {
  flex: 1
}

.uk-margin {
  margin-bottom: 20px
}

*+.uk-margin {
  margin-top: 20px !important
}

.uk-margin-top {
  margin-top: 20px !important
}

.uk-margin-bottom {
  margin-bottom: 20px !important
}

.uk-margin-left {
  margin-left: 20px !important
}

.uk-margin-right {
  margin-right: 20px !important
}

.uk-margin-small {
  margin-bottom: 10px
}

*+.uk-margin-small {
  margin-top: 10px !important
}

.uk-margin-small-top {
  margin-top: 10px !important
}

.uk-margin-small-bottom {
  margin-bottom: 10px !important
}

.uk-margin-small-left {
  margin-left: 10px !important
}

.uk-margin-small-right {
  margin-right: 10px !important
}

.uk-margin-medium {
  margin-bottom: 40px
}

*+.uk-margin-medium {
  margin-top: 40px !important
}

.uk-margin-medium-top {
  margin-top: 40px !important
}

.uk-margin-medium-bottom {
  margin-bottom: 40px !important
}

.uk-margin-medium-left {
  margin-left: 40px !important
}

.uk-margin-medium-right {
  margin-right: 40px !important
}

.uk-margin-large {
  margin-bottom: 40px
}

*+.uk-margin-large {
  margin-top: 40px !important
}

.uk-margin-large-top {
  margin-top: 40px !important
}

.uk-margin-large-bottom {
  margin-bottom: 40px !important
}

.uk-margin-large-left {
  margin-left: 40px !important
}

.uk-margin-large-right {
  margin-right: 40px !important
}

@media (min-width: 1200px) {
  .uk-margin-large {
    margin-bottom: 70px
  }

  *+.uk-margin-large {
    margin-top: 70px !important
  }

  .uk-margin-large-top {
    margin-top: 70px !important
  }

  .uk-margin-large-bottom {
    margin-bottom: 70px !important
  }

  .uk-margin-large-left {
    margin-left: 70px !important
  }

  .uk-margin-large-right {
    margin-right: 70px !important
  }
}

.uk-margin-xlarge {
  margin-bottom: 70px
}

*+.uk-margin-xlarge {
  margin-top: 70px !important
}

.uk-margin-xlarge-top {
  margin-top: 70px !important
}

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important
}

.uk-margin-xlarge-left {
  margin-left: 70px !important
}

.uk-margin-xlarge-right {
  margin-right: 70px !important
}

@media (min-width: 1200px) {
  .uk-margin-xlarge {
    margin-bottom: 140px
  }

  *+.uk-margin-xlarge {
    margin-top: 140px !important
  }

  .uk-margin-xlarge-top {
    margin-top: 140px !important
  }

  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important
  }

  .uk-margin-xlarge-left {
    margin-left: 140px !important
  }

  .uk-margin-xlarge-right {
    margin-right: 140px !important
  }
}

.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.uk-margin-auto-top {
  margin-top: auto !important
}

.uk-margin-auto-bottom {
  margin-bottom: auto !important
}

.uk-margin-auto-left {
  margin-left: auto !important
}

.uk-margin-auto-right {
  margin-right: auto !important
}

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important
}

@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .uk-margin-auto-left\@s {
    margin-left: auto !important
  }

  .uk-margin-auto-right\@s {
    margin-right: auto !important
  }
}

@media (min-width: 960px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .uk-margin-auto-left\@m {
    margin-left: auto !important
  }

  .uk-margin-auto-right\@m {
    margin-right: auto !important
  }
}

@media (min-width: 1200px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .uk-margin-auto-left\@l {
    margin-left: auto !important
  }

  .uk-margin-auto-right\@l {
    margin-right: auto !important
  }
}

@media (min-width: 1600px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .uk-margin-auto-left\@xl {
    margin-left: auto !important
  }

  .uk-margin-auto-right\@xl {
    margin-right: auto !important
  }
}

.uk-margin-remove {
  margin: 0 !important
}

.uk-margin-remove-top {
  margin-top: 0 !important
}

.uk-margin-remove-bottom {
  margin-bottom: 0 !important
}

.uk-margin-remove-left {
  margin-left: 0 !important
}

.uk-margin-remove-right {
  margin-right: 0 !important
}

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.uk-margin-remove-adjacent+*,
.uk-margin-remove-first-child>:first-child {
  margin-top: 0 !important
}

.uk-margin-remove-last-child>:last-child {
  margin-bottom: 0 !important
}

@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important
  }

  .uk-margin-remove-right\@s {
    margin-right: 0 !important
  }
}

@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important
  }

  .uk-margin-remove-right\@m {
    margin-right: 0 !important
  }
}

@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important
  }

  .uk-margin-remove-right\@l {
    margin-right: 0 !important
  }
}

@media (min-width: 1600px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important
  }

  .uk-margin-remove-right\@xl {
    margin-right: 0 !important
  }
}

.uk-padding {
  padding: 30px
}

@media (min-width: 1200px) {
  .uk-padding {
    padding: 40px
  }
}

.uk-padding-small {
  padding: 15px
}

.uk-padding-large {
  padding: 30px
}

@media (min-width: 1200px) {
  .uk-padding-large {
    padding: 70px
  }
}

.uk-padding-remove {
  padding: 0 !important
}

.uk-padding-remove-top {
  padding-top: 0 !important
}

.uk-padding-remove-bottom {
  padding-bottom: 0 !important
}

.uk-padding-remove-left {
  padding-left: 0 !important
}

.uk-padding-remove-right {
  padding-right: 0 !important
}

.uk-padding-remove-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.uk-padding-remove-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important
}

[class*='uk-position-top'],
[class*='uk-position-bottom'],
[class*='uk-position-left'],
[class*='uk-position-right'],
[class*='uk-position-center'] {
  position: absolute !important;
  max-width: 100%
}

.uk-position-top {
  top: 0;
  left: 0;
  right: 0
}

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0
}

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0
}

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0
}

.uk-position-top-left {
  top: 0;
  left: 0
}

.uk-position-top-right {
  top: 0;
  right: 0
}

.uk-position-bottom-left {
  bottom: 0;
  left: 0
}

.uk-position-bottom-right {
  bottom: 0;
  right: 0
}

.uk-position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  max-width: 100%;
  box-sizing: border-box
}

[class*='uk-position-center-left'],
[class*='uk-position-center-right'] {
  top: 50%;
  transform: translateY(-50%)
}

.uk-position-center-left {
  left: 0
}

.uk-position-center-right {
  right: 0
}

.uk-position-center-left-out {
  right: 100%;
  width: max-content
}

.uk-position-center-right-out {
  left: 100%;
  width: max-content
}

.uk-position-top-center,
.uk-position-bottom-center {
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  max-width: 100%;
  box-sizing: border-box
}

.uk-position-top-center {
  top: 0
}

.uk-position-bottom-center {
  bottom: 0
}

.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.uk-position-relative {
  position: relative !important
}

.uk-position-absolute {
  position: absolute !important
}

.uk-position-fixed {
  position: fixed !important
}

.uk-position-z-index {
  z-index: 1
}

.uk-position-small {
  max-width: calc(100% - (15px * 2));
  margin: 15px
}

.uk-position-small.uk-position-center {
  transform: translate(-50%, -50%) translate(-15px, -15px)
}

.uk-position-small[class*='uk-position-center-left'],
.uk-position-small[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-15px)
}

.uk-position-small.uk-position-top-center,
.uk-position-small.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-15px)
}

.uk-position-medium {
  max-width: calc(100% - (30px * 2));
  margin: 30px
}

.uk-position-medium.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px)
}

.uk-position-medium[class*='uk-position-center-left'],
.uk-position-medium[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-30px)
}

.uk-position-medium.uk-position-top-center,
.uk-position-medium.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px)
}

.uk-position-large {
  max-width: calc(100% - (30px * 2));
  margin: 30px
}

.uk-position-large.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px)
}

.uk-position-large[class*='uk-position-center-left'],
.uk-position-large[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-30px)
}

.uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px)
}

@media (min-width: 1200px) {
  .uk-position-large {
    max-width: calc(100% - (50px * 2));
    margin: 50px
  }

  .uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-50px, -50px)
  }

  .uk-position-large[class*='uk-position-center-left'],
  .uk-position-large[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-50px)
  }

  .uk-position-large.uk-position-top-center,
  .uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-50px)
  }
}

.uk-transition-toggle {
  -webkit-tap-highlight-color: transparent
}

.uk-transition-toggle:focus {
  outline: none
}

.uk-transition-fade,
[class*='uk-transition-scale'],
[class*='uk-transition-slide'] {
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0
}

.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1
}

.uk-transition-scale-up {
  transform: scale(1, 1)
}

.uk-transition-scale-down {
  transform: scale(1.1, 1.1)
}

.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-active.uk-active .uk-transition-scale-up {
  opacity: 1;
  transform: scale(1.1, 1.1)
}

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-active.uk-active .uk-transition-scale-down {
  opacity: 1;
  transform: scale(1, 1)
}

.uk-transition-slide-top {
  transform: translateY(-100%)
}

.uk-transition-slide-bottom {
  transform: translateY(100%)
}

.uk-transition-slide-left {
  transform: translateX(-100%)
}

.uk-transition-slide-right {
  transform: translateX(100%)
}

.uk-transition-slide-top-small {
  transform: translateY(-10px)
}

.uk-transition-slide-bottom-small {
  transform: translateY(10px)
}

.uk-transition-slide-left-small {
  transform: translateX(-10px)
}

.uk-transition-slide-right-small {
  transform: translateX(10px)
}

.uk-transition-slide-top-medium {
  transform: translateY(-50px)
}

.uk-transition-slide-bottom-medium {
  transform: translateY(50px)
}

.uk-transition-slide-left-medium {
  transform: translateX(-50px)
}

.uk-transition-slide-right-medium {
  transform: translateX(50px)
}

.uk-transition-toggle:hover [class*='uk-transition-slide'],
.uk-transition-toggle:focus [class*='uk-transition-slide'],
.uk-transition-active.uk-active [class*='uk-transition-slide'] {
  opacity: 1;
  transform: translate(0, 0)
}

.uk-transition-opaque {
  opacity: 1
}

.uk-transition-slow {
  transition-duration: 0.7s
}

[hidden],
.uk-hidden {
  display: none !important
}

@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important
  }
}

@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important
  }
}

@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important
  }
}

@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important
  }
}

@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important
  }
}

@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important
  }
}

@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important
  }
}

@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important
  }
}

.uk-invisible {
  visibility: hidden !important
}

.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important
}

.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  opacity: 0 !important
}

.uk-visible-toggle {
  -webkit-tap-highlight-color: transparent
}

.uk-visible-toggle:focus {
  outline: none
}

@media (pointer: coarse) {
  .uk-hidden-touch {
    display: none !important
  }
}

.uk-hidden-notouch {
  display: none !important
}

@media (pointer: coarse) {
  .uk-hidden-notouch {
    display: block !important
  }
}

.uk-light,
.uk-section-primary:not(.uk-preserve-color),
.uk-section-secondary:not(.uk-preserve-color),
.uk-tile-primary:not(.uk-preserve-color),
.uk-tile-secondary:not(.uk-preserve-color),
.uk-card-primary.uk-card-body,
.uk-card-primary>:not([class*='uk-card-media']),
.uk-card-secondary.uk-card-body,
.uk-card-secondary>:not([class*='uk-card-media']),
.uk-overlay-primary,
.uk-offcanvas-bar {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light a,
.uk-light .uk-link,
.uk-section-primary:not(.uk-preserve-color) a,
.uk-section-primary:not(.uk-preserve-color) .uk-link,
.uk-section-secondary:not(.uk-preserve-color) a,
.uk-section-secondary:not(.uk-preserve-color) .uk-link,
.uk-tile-primary:not(.uk-preserve-color) a,
.uk-tile-primary:not(.uk-preserve-color) .uk-link,
.uk-tile-secondary:not(.uk-preserve-color) a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link,
.uk-card-primary.uk-card-body a,
.uk-card-primary.uk-card-body .uk-link,
.uk-card-primary>:not([class*='uk-card-media']) a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link,
.uk-card-secondary.uk-card-body a,
.uk-card-secondary.uk-card-body .uk-link,
.uk-card-secondary>:not([class*='uk-card-media']) a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link,
.uk-overlay-primary a,
.uk-overlay-primary .uk-link,
.uk-offcanvas-bar a,
.uk-offcanvas-bar .uk-link {
  color: #fff
}

.uk-light a:hover,
.uk-light .uk-link:hover,
.uk-section-primary:not(.uk-preserve-color) a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-section-secondary:not(.uk-preserve-color) a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link:hover,
.uk-tile-primary:not(.uk-preserve-color) a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-tile-secondary:not(.uk-preserve-color) a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link:hover,
.uk-card-primary.uk-card-body a:hover,
.uk-card-primary.uk-card-body .uk-link:hover,
.uk-card-primary>:not([class*='uk-card-media']) a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link:hover,
.uk-card-secondary.uk-card-body a:hover,
.uk-card-secondary.uk-card-body .uk-link:hover,
.uk-card-secondary>:not([class*='uk-card-media']) a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link:hover,
.uk-overlay-primary a:hover,
.uk-overlay-primary .uk-link:hover,
.uk-offcanvas-bar a:hover,
.uk-offcanvas-bar .uk-link:hover {
  color: #fff
}

.uk-light :not(pre)>code,
.uk-light :not(pre)>kbd,
.uk-light :not(pre)>samp,
.uk-section-primary:not(.uk-preserve-color) :not(pre)>code,
.uk-section-primary:not(.uk-preserve-color) :not(pre)>kbd,
.uk-section-primary:not(.uk-preserve-color) :not(pre)>samp,
.uk-section-secondary:not(.uk-preserve-color) :not(pre)>code,
.uk-section-secondary:not(.uk-preserve-color) :not(pre)>kbd,
.uk-section-secondary:not(.uk-preserve-color) :not(pre)>samp,
.uk-tile-primary:not(.uk-preserve-color) :not(pre)>code,
.uk-tile-primary:not(.uk-preserve-color) :not(pre)>kbd,
.uk-tile-primary:not(.uk-preserve-color) :not(pre)>samp,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre)>code,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre)>kbd,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre)>samp,
.uk-card-primary.uk-card-body :not(pre)>code,
.uk-card-primary.uk-card-body :not(pre)>kbd,
.uk-card-primary.uk-card-body :not(pre)>samp,
.uk-card-primary>:not([class*='uk-card-media']) :not(pre)>code,
.uk-card-primary>:not([class*='uk-card-media']) :not(pre)>kbd,
.uk-card-primary>:not([class*='uk-card-media']) :not(pre)>samp,
.uk-card-secondary.uk-card-body :not(pre)>code,
.uk-card-secondary.uk-card-body :not(pre)>kbd,
.uk-card-secondary.uk-card-body :not(pre)>samp,
.uk-card-secondary>:not([class*='uk-card-media']) :not(pre)>code,
.uk-card-secondary>:not([class*='uk-card-media']) :not(pre)>kbd,
.uk-card-secondary>:not([class*='uk-card-media']) :not(pre)>samp,
.uk-overlay-primary :not(pre)>code,
.uk-overlay-primary :not(pre)>kbd,
.uk-overlay-primary :not(pre)>samp,
.uk-offcanvas-bar :not(pre)>code,
.uk-offcanvas-bar :not(pre)>kbd,
.uk-offcanvas-bar :not(pre)>samp {
  color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.1)
}

.uk-light em,
.uk-section-primary:not(.uk-preserve-color) em,
.uk-section-secondary:not(.uk-preserve-color) em,
.uk-tile-primary:not(.uk-preserve-color) em,
.uk-tile-secondary:not(.uk-preserve-color) em,
.uk-card-primary.uk-card-body em,
.uk-card-primary>:not([class*='uk-card-media']) em,
.uk-card-secondary.uk-card-body em,
.uk-card-secondary>:not([class*='uk-card-media']) em,
.uk-overlay-primary em,
.uk-offcanvas-bar em {
  color: #fff
}

.uk-light h1,
.uk-light .uk-h1,
.uk-light h2,
.uk-light .uk-h2,
.uk-light h3,
.uk-light .uk-h3,
.uk-light h4,
.uk-light .uk-h4,
.uk-light h5,
.uk-light .uk-h5,
.uk-light h6,
.uk-light .uk-h6,
.uk-light .uk-heading-small,
.uk-light .uk-heading-medium,
.uk-light .uk-heading-large,
.uk-light .uk-heading-xlarge,
.uk-light .uk-heading-2xlarge,
.uk-section-primary:not(.uk-preserve-color) h1,
.uk-section-primary:not(.uk-preserve-color) .uk-h1,
.uk-section-primary:not(.uk-preserve-color) h2,
.uk-section-primary:not(.uk-preserve-color) .uk-h2,
.uk-section-primary:not(.uk-preserve-color) h3,
.uk-section-primary:not(.uk-preserve-color) .uk-h3,
.uk-section-primary:not(.uk-preserve-color) h4,
.uk-section-primary:not(.uk-preserve-color) .uk-h4,
.uk-section-primary:not(.uk-preserve-color) h5,
.uk-section-primary:not(.uk-preserve-color) .uk-h5,
.uk-section-primary:not(.uk-preserve-color) h6,
.uk-section-primary:not(.uk-preserve-color) .uk-h6,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-section-secondary:not(.uk-preserve-color) h1,
.uk-section-secondary:not(.uk-preserve-color) .uk-h1,
.uk-section-secondary:not(.uk-preserve-color) h2,
.uk-section-secondary:not(.uk-preserve-color) .uk-h2,
.uk-section-secondary:not(.uk-preserve-color) h3,
.uk-section-secondary:not(.uk-preserve-color) .uk-h3,
.uk-section-secondary:not(.uk-preserve-color) h4,
.uk-section-secondary:not(.uk-preserve-color) .uk-h4,
.uk-section-secondary:not(.uk-preserve-color) h5,
.uk-section-secondary:not(.uk-preserve-color) .uk-h5,
.uk-section-secondary:not(.uk-preserve-color) h6,
.uk-section-secondary:not(.uk-preserve-color) .uk-h6,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-small,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-medium,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-large,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-tile-primary:not(.uk-preserve-color) h1,
.uk-tile-primary:not(.uk-preserve-color) .uk-h1,
.uk-tile-primary:not(.uk-preserve-color) h2,
.uk-tile-primary:not(.uk-preserve-color) .uk-h2,
.uk-tile-primary:not(.uk-preserve-color) h3,
.uk-tile-primary:not(.uk-preserve-color) .uk-h3,
.uk-tile-primary:not(.uk-preserve-color) h4,
.uk-tile-primary:not(.uk-preserve-color) .uk-h4,
.uk-tile-primary:not(.uk-preserve-color) h5,
.uk-tile-primary:not(.uk-preserve-color) .uk-h5,
.uk-tile-primary:not(.uk-preserve-color) h6,
.uk-tile-primary:not(.uk-preserve-color) .uk-h6,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-tile-secondary:not(.uk-preserve-color) h1,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h1,
.uk-tile-secondary:not(.uk-preserve-color) h2,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h2,
.uk-tile-secondary:not(.uk-preserve-color) h3,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h3,
.uk-tile-secondary:not(.uk-preserve-color) h4,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h4,
.uk-tile-secondary:not(.uk-preserve-color) h5,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h5,
.uk-tile-secondary:not(.uk-preserve-color) h6,
.uk-tile-secondary:not(.uk-preserve-color) .uk-h6,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-small,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-medium,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-large,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-card-primary.uk-card-body h1,
.uk-card-primary.uk-card-body .uk-h1,
.uk-card-primary.uk-card-body h2,
.uk-card-primary.uk-card-body .uk-h2,
.uk-card-primary.uk-card-body h3,
.uk-card-primary.uk-card-body .uk-h3,
.uk-card-primary.uk-card-body h4,
.uk-card-primary.uk-card-body .uk-h4,
.uk-card-primary.uk-card-body h5,
.uk-card-primary.uk-card-body .uk-h5,
.uk-card-primary.uk-card-body h6,
.uk-card-primary.uk-card-body .uk-h6,
.uk-card-primary.uk-card-body .uk-heading-small,
.uk-card-primary.uk-card-body .uk-heading-medium,
.uk-card-primary.uk-card-body .uk-heading-large,
.uk-card-primary.uk-card-body .uk-heading-xlarge,
.uk-card-primary.uk-card-body .uk-heading-2xlarge,
.uk-card-primary>:not([class*='uk-card-media']) h1,
.uk-card-primary>:not([class*='uk-card-media']) .uk-h1,
.uk-card-primary>:not([class*='uk-card-media']) h2,
.uk-card-primary>:not([class*='uk-card-media']) .uk-h2,
.uk-card-primary>:not([class*='uk-card-media']) h3,
.uk-card-primary>:not([class*='uk-card-media']) .uk-h3,
.uk-card-primary>:not([class*='uk-card-media']) h4,
.uk-card-primary>:not([class*='uk-card-media']) .uk-h4,
.uk-card-primary>:not([class*='uk-card-media']) h5,
.uk-card-primary>:not([class*='uk-card-media']) .uk-h5,
.uk-card-primary>:not([class*='uk-card-media']) h6,
.uk-card-primary>:not([class*='uk-card-media']) .uk-h6,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-small,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-medium,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-large,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-xlarge,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-2xlarge,
.uk-card-secondary.uk-card-body h1,
.uk-card-secondary.uk-card-body .uk-h1,
.uk-card-secondary.uk-card-body h2,
.uk-card-secondary.uk-card-body .uk-h2,
.uk-card-secondary.uk-card-body h3,
.uk-card-secondary.uk-card-body .uk-h3,
.uk-card-secondary.uk-card-body h4,
.uk-card-secondary.uk-card-body .uk-h4,
.uk-card-secondary.uk-card-body h5,
.uk-card-secondary.uk-card-body .uk-h5,
.uk-card-secondary.uk-card-body h6,
.uk-card-secondary.uk-card-body .uk-h6,
.uk-card-secondary.uk-card-body .uk-heading-small,
.uk-card-secondary.uk-card-body .uk-heading-medium,
.uk-card-secondary.uk-card-body .uk-heading-large,
.uk-card-secondary.uk-card-body .uk-heading-xlarge,
.uk-card-secondary.uk-card-body .uk-heading-2xlarge,
.uk-card-secondary>:not([class*='uk-card-media']) h1,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-h1,
.uk-card-secondary>:not([class*='uk-card-media']) h2,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-h2,
.uk-card-secondary>:not([class*='uk-card-media']) h3,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-h3,
.uk-card-secondary>:not([class*='uk-card-media']) h4,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-h4,
.uk-card-secondary>:not([class*='uk-card-media']) h5,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-h5,
.uk-card-secondary>:not([class*='uk-card-media']) h6,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-h6,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-small,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-medium,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-large,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-xlarge,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-2xlarge,
.uk-overlay-primary h1,
.uk-overlay-primary .uk-h1,
.uk-overlay-primary h2,
.uk-overlay-primary .uk-h2,
.uk-overlay-primary h3,
.uk-overlay-primary .uk-h3,
.uk-overlay-primary h4,
.uk-overlay-primary .uk-h4,
.uk-overlay-primary h5,
.uk-overlay-primary .uk-h5,
.uk-overlay-primary h6,
.uk-overlay-primary .uk-h6,
.uk-overlay-primary .uk-heading-small,
.uk-overlay-primary .uk-heading-medium,
.uk-overlay-primary .uk-heading-large,
.uk-overlay-primary .uk-heading-xlarge,
.uk-overlay-primary .uk-heading-2xlarge,
.uk-offcanvas-bar h1,
.uk-offcanvas-bar .uk-h1,
.uk-offcanvas-bar h2,
.uk-offcanvas-bar .uk-h2,
.uk-offcanvas-bar h3,
.uk-offcanvas-bar .uk-h3,
.uk-offcanvas-bar h4,
.uk-offcanvas-bar .uk-h4,
.uk-offcanvas-bar h5,
.uk-offcanvas-bar .uk-h5,
.uk-offcanvas-bar h6,
.uk-offcanvas-bar .uk-h6,
.uk-offcanvas-bar .uk-heading-small,
.uk-offcanvas-bar .uk-heading-medium,
.uk-offcanvas-bar .uk-heading-large,
.uk-offcanvas-bar .uk-heading-xlarge,
.uk-offcanvas-bar .uk-heading-2xlarge {
  color: #fff
}

.uk-light blockquote,
.uk-section-primary:not(.uk-preserve-color) blockquote,
.uk-section-secondary:not(.uk-preserve-color) blockquote,
.uk-tile-primary:not(.uk-preserve-color) blockquote,
.uk-tile-secondary:not(.uk-preserve-color) blockquote,
.uk-card-primary.uk-card-body blockquote,
.uk-card-primary>:not([class*='uk-card-media']) blockquote,
.uk-card-secondary.uk-card-body blockquote,
.uk-card-secondary>:not([class*='uk-card-media']) blockquote,
.uk-overlay-primary blockquote,
.uk-offcanvas-bar blockquote {
  color: #fff
}

.uk-light blockquote footer,
.uk-section-primary:not(.uk-preserve-color) blockquote footer,
.uk-section-secondary:not(.uk-preserve-color) blockquote footer,
.uk-tile-primary:not(.uk-preserve-color) blockquote footer,
.uk-tile-secondary:not(.uk-preserve-color) blockquote footer,
.uk-card-primary.uk-card-body blockquote footer,
.uk-card-primary>:not([class*='uk-card-media']) blockquote footer,
.uk-card-secondary.uk-card-body blockquote footer,
.uk-card-secondary>:not([class*='uk-card-media']) blockquote footer,
.uk-overlay-primary blockquote footer,
.uk-offcanvas-bar blockquote footer {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light hr,
.uk-light .uk-hr,
.uk-section-primary:not(.uk-preserve-color) hr,
.uk-section-primary:not(.uk-preserve-color) .uk-hr,
.uk-section-secondary:not(.uk-preserve-color) hr,
.uk-section-secondary:not(.uk-preserve-color) .uk-hr,
.uk-tile-primary:not(.uk-preserve-color) hr,
.uk-tile-primary:not(.uk-preserve-color) .uk-hr,
.uk-tile-secondary:not(.uk-preserve-color) hr,
.uk-tile-secondary:not(.uk-preserve-color) .uk-hr,
.uk-card-primary.uk-card-body hr,
.uk-card-primary.uk-card-body .uk-hr,
.uk-card-primary>:not([class*='uk-card-media']) hr,
.uk-card-primary>:not([class*='uk-card-media']) .uk-hr,
.uk-card-secondary.uk-card-body hr,
.uk-card-secondary.uk-card-body .uk-hr,
.uk-card-secondary>:not([class*='uk-card-media']) hr,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-hr,
.uk-overlay-primary hr,
.uk-overlay-primary .uk-hr,
.uk-offcanvas-bar hr,
.uk-offcanvas-bar .uk-hr {
  border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-link-muted,
.uk-light .uk-link-muted a,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a,
.uk-card-primary.uk-card-body .uk-link-muted,
.uk-card-primary.uk-card-body .uk-link-muted a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link-muted,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link-muted a,
.uk-card-secondary.uk-card-body .uk-link-muted,
.uk-card-secondary.uk-card-body .uk-link-muted a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link-muted,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link-muted a,
.uk-overlay-primary .uk-link-muted,
.uk-overlay-primary .uk-link-muted a,
.uk-offcanvas-bar .uk-link-muted,
.uk-offcanvas-bar .uk-link-muted a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-link-muted:hover,
.uk-light .uk-link-muted a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-card-primary.uk-card-body .uk-link-muted:hover,
.uk-card-primary.uk-card-body .uk-link-muted a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link-muted:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link-muted a:hover,
.uk-card-secondary.uk-card-body .uk-link-muted:hover,
.uk-card-secondary.uk-card-body .uk-link-muted a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link-muted:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link-muted a:hover,
.uk-overlay-primary .uk-link-muted:hover,
.uk-overlay-primary .uk-link-muted a:hover,
.uk-offcanvas-bar .uk-link-muted:hover,
.uk-offcanvas-bar .uk-link-muted a:hover {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-link-text:hover,
.uk-light .uk-link-text a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-text:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-text:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-text:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-text:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-card-primary.uk-card-body .uk-link-text:hover,
.uk-card-primary.uk-card-body .uk-link-text a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link-text:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link-text a:hover,
.uk-card-secondary.uk-card-body .uk-link-text:hover,
.uk-card-secondary.uk-card-body .uk-link-text a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link-text:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link-text a:hover,
.uk-overlay-primary .uk-link-text:hover,
.uk-overlay-primary .uk-link-text a:hover,
.uk-offcanvas-bar .uk-link-text:hover,
.uk-offcanvas-bar .uk-link-text a:hover {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-link-heading:hover,
.uk-light .uk-link-heading a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-heading:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-heading:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-heading:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-heading:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-card-primary.uk-card-body .uk-link-heading:hover,
.uk-card-primary.uk-card-body .uk-link-heading a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link-heading:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-link-heading a:hover,
.uk-card-secondary.uk-card-body .uk-link-heading:hover,
.uk-card-secondary.uk-card-body .uk-link-heading a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link-heading:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-link-heading a:hover,
.uk-overlay-primary .uk-link-heading:hover,
.uk-overlay-primary .uk-link-heading a:hover,
.uk-offcanvas-bar .uk-link-heading:hover,
.uk-offcanvas-bar .uk-link-heading a:hover {
  color: #fff
}

.uk-light .uk-heading-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-divider,
.uk-card-primary.uk-card-body .uk-heading-divider,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-divider,
.uk-card-secondary.uk-card-body .uk-heading-divider,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-divider,
.uk-overlay-primary .uk-heading-divider,
.uk-offcanvas-bar .uk-heading-divider {
  border-bottom-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-heading-bullet::before,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-bullet::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-bullet::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-bullet::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-bullet::before,
.uk-card-primary.uk-card-body .uk-heading-bullet::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-bullet::before,
.uk-card-secondary.uk-card-body .uk-heading-bullet::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-bullet::before,
.uk-overlay-primary .uk-heading-bullet::before,
.uk-offcanvas-bar .uk-heading-bullet::before {
  border-left-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-heading-line>::before,
.uk-light .uk-heading-line>::after,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line>::before,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line>::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-line>::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-line>::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-line>::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-line>::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line>::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line>::after,
.uk-card-primary.uk-card-body .uk-heading-line>::before,
.uk-card-primary.uk-card-body .uk-heading-line>::after,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-line>::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-heading-line>::after,
.uk-card-secondary.uk-card-body .uk-heading-line>::before,
.uk-card-secondary.uk-card-body .uk-heading-line>::after,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-line>::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-heading-line>::after,
.uk-overlay-primary .uk-heading-line>::before,
.uk-overlay-primary .uk-heading-line>::after,
.uk-offcanvas-bar .uk-heading-line>::before,
.uk-offcanvas-bar .uk-heading-line>::after {
  border-bottom-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-divider-icon,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon,
.uk-card-primary.uk-card-body .uk-divider-icon,
.uk-card-primary>:not([class*='uk-card-media']) .uk-divider-icon,
.uk-card-secondary.uk-card-body .uk-divider-icon,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-divider-icon,
.uk-overlay-primary .uk-divider-icon,
.uk-offcanvas-bar .uk-divider-icon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.2%29%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-light .uk-divider-icon::before,
.uk-light .uk-divider-icon::after,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::before,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-card-primary.uk-card-body .uk-divider-icon::before,
.uk-card-primary.uk-card-body .uk-divider-icon::after,
.uk-card-primary>:not([class*='uk-card-media']) .uk-divider-icon::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-divider-icon::after,
.uk-card-secondary.uk-card-body .uk-divider-icon::before,
.uk-card-secondary.uk-card-body .uk-divider-icon::after,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-divider-icon::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-divider-icon::after,
.uk-overlay-primary .uk-divider-icon::before,
.uk-overlay-primary .uk-divider-icon::after,
.uk-offcanvas-bar .uk-divider-icon::before,
.uk-offcanvas-bar .uk-divider-icon::after {
  border-bottom-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-divider-small::after,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-small::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-small::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-small::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-small::after,
.uk-card-primary.uk-card-body .uk-divider-small::after,
.uk-card-primary>:not([class*='uk-card-media']) .uk-divider-small::after,
.uk-card-secondary.uk-card-body .uk-divider-small::after,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-divider-small::after,
.uk-overlay-primary .uk-divider-small::after,
.uk-offcanvas-bar .uk-divider-small::after {
  border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-divider-vertical,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-vertical,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-vertical,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-vertical,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-vertical,
.uk-card-primary.uk-card-body .uk-divider-vertical,
.uk-card-primary>:not([class*='uk-card-media']) .uk-divider-vertical,
.uk-card-secondary.uk-card-body .uk-divider-vertical,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-divider-vertical,
.uk-overlay-primary .uk-divider-vertical,
.uk-offcanvas-bar .uk-divider-vertical {
  border-left-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-list-muted>::before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-muted>::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-muted>::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-muted>::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-muted>::before,
.uk-card-primary.uk-card-body .uk-list-muted>::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-list-muted>::before,
.uk-card-secondary.uk-card-body .uk-list-muted>::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-list-muted>::before,
.uk-overlay-primary .uk-list-muted>::before,
.uk-offcanvas-bar .uk-list-muted>::before {
  color: rgba(255, 255, 255, 0.5) !important
}

.uk-light .uk-list-emphasis>::before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-emphasis>::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-emphasis>::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-emphasis>::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-emphasis>::before,
.uk-card-primary.uk-card-body .uk-list-emphasis>::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-list-emphasis>::before,
.uk-card-secondary.uk-card-body .uk-list-emphasis>::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-list-emphasis>::before,
.uk-overlay-primary .uk-list-emphasis>::before,
.uk-offcanvas-bar .uk-list-emphasis>::before {
  color: #fff !important
}

.uk-light .uk-list-primary>::before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-primary>::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-primary>::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-primary>::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-primary>::before,
.uk-card-primary.uk-card-body .uk-list-primary>::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-list-primary>::before,
.uk-card-secondary.uk-card-body .uk-list-primary>::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-list-primary>::before,
.uk-overlay-primary .uk-list-primary>::before,
.uk-offcanvas-bar .uk-list-primary>::before {
  color: #fff !important
}

.uk-light .uk-list-secondary>::before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-secondary>::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-secondary>::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-secondary>::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-secondary>::before,
.uk-card-primary.uk-card-body .uk-list-secondary>::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-list-secondary>::before,
.uk-card-secondary.uk-card-body .uk-list-secondary>::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-list-secondary>::before,
.uk-overlay-primary .uk-list-secondary>::before,
.uk-offcanvas-bar .uk-list-secondary>::before {
  color: #fff !important
}

.uk-light .uk-list-bullet>::before,
.uk-section-primary:not(.uk-preserve-color) .uk-list-bullet>::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-list-bullet>::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-list-bullet>::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-bullet>::before,
.uk-card-primary.uk-card-body .uk-list-bullet>::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-list-bullet>::before,
.uk-card-secondary.uk-card-body .uk-list-bullet>::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-list-bullet>::before,
.uk-overlay-primary .uk-list-bullet>::before,
.uk-offcanvas-bar .uk-list-bullet>::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-list-divider>:nth-child(n+2),
.uk-section-primary:not(.uk-preserve-color) .uk-list-divider>:nth-child(n+2),
.uk-section-secondary:not(.uk-preserve-color) .uk-list-divider>:nth-child(n+2),
.uk-tile-primary:not(.uk-preserve-color) .uk-list-divider>:nth-child(n+2),
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-divider>:nth-child(n+2),
.uk-card-primary.uk-card-body .uk-list-divider>:nth-child(n+2),
.uk-card-primary>:not([class*='uk-card-media']) .uk-list-divider>:nth-child(n+2),
.uk-card-secondary.uk-card-body .uk-list-divider>:nth-child(n+2),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-list-divider>:nth-child(n+2),
.uk-overlay-primary .uk-list-divider>:nth-child(n+2),
.uk-offcanvas-bar .uk-list-divider>:nth-child(n+2) {
  border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-list-striped>*:nth-of-type(odd),
.uk-section-primary:not(.uk-preserve-color) .uk-list-striped>*:nth-of-type(odd),
.uk-section-secondary:not(.uk-preserve-color) .uk-list-striped>*:nth-of-type(odd),
.uk-tile-primary:not(.uk-preserve-color) .uk-list-striped>*:nth-of-type(odd),
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-striped>*:nth-of-type(odd),
.uk-card-primary.uk-card-body .uk-list-striped>*:nth-of-type(odd),
.uk-card-primary>:not([class*='uk-card-media']) .uk-list-striped>*:nth-of-type(odd),
.uk-card-secondary.uk-card-body .uk-list-striped>*:nth-of-type(odd),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-list-striped>*:nth-of-type(odd),
.uk-overlay-primary .uk-list-striped>*:nth-of-type(odd),
.uk-offcanvas-bar .uk-list-striped>*:nth-of-type(odd) {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-list-striped>:nth-of-type(odd),
.uk-section-primary:not(.uk-preserve-color) .uk-list-striped>:nth-of-type(odd),
.uk-section-secondary:not(.uk-preserve-color) .uk-list-striped>:nth-of-type(odd),
.uk-tile-primary:not(.uk-preserve-color) .uk-list-striped>:nth-of-type(odd),
.uk-tile-secondary:not(.uk-preserve-color) .uk-list-striped>:nth-of-type(odd),
.uk-card-primary.uk-card-body .uk-list-striped>:nth-of-type(odd),
.uk-card-primary>:not([class*='uk-card-media']) .uk-list-striped>:nth-of-type(odd),
.uk-card-secondary.uk-card-body .uk-list-striped>:nth-of-type(odd),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-list-striped>:nth-of-type(odd),
.uk-overlay-primary .uk-list-striped>:nth-of-type(odd),
.uk-offcanvas-bar .uk-list-striped>:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1)
}

.uk-light .uk-table th,
.uk-section-primary:not(.uk-preserve-color) .uk-table th,
.uk-section-secondary:not(.uk-preserve-color) .uk-table th,
.uk-tile-primary:not(.uk-preserve-color) .uk-table th,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table th,
.uk-card-primary.uk-card-body .uk-table th,
.uk-card-primary>:not([class*='uk-card-media']) .uk-table th,
.uk-card-secondary.uk-card-body .uk-table th,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table th,
.uk-overlay-primary .uk-table th,
.uk-offcanvas-bar .uk-table th {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-table caption,
.uk-section-primary:not(.uk-preserve-color) .uk-table caption,
.uk-section-secondary:not(.uk-preserve-color) .uk-table caption,
.uk-tile-primary:not(.uk-preserve-color) .uk-table caption,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table caption,
.uk-card-primary.uk-card-body .uk-table caption,
.uk-card-primary>:not([class*='uk-card-media']) .uk-table caption,
.uk-card-secondary.uk-card-body .uk-table caption,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table caption,
.uk-overlay-primary .uk-table caption,
.uk-offcanvas-bar .uk-table caption {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-table>tr.uk-active,
.uk-light .uk-table tbody tr.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-table>tr.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-table tbody tr.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-table>tr.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-table tbody tr.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-table>tr.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-table tbody tr.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table>tr.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table tbody tr.uk-active,
.uk-card-primary.uk-card-body .uk-table>tr.uk-active,
.uk-card-primary.uk-card-body .uk-table tbody tr.uk-active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-table>tr.uk-active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-table tbody tr.uk-active,
.uk-card-secondary.uk-card-body .uk-table>tr.uk-active,
.uk-card-secondary.uk-card-body .uk-table tbody tr.uk-active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table>tr.uk-active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table tbody tr.uk-active,
.uk-overlay-primary .uk-table>tr.uk-active,
.uk-overlay-primary .uk-table tbody tr.uk-active,
.uk-offcanvas-bar .uk-table>tr.uk-active,
.uk-offcanvas-bar .uk-table tbody tr.uk-active {
  background: rgba(255, 255, 255, 0.08)
}

.uk-light .uk-table-divider>tr:not(:first-child),
.uk-light .uk-table-divider>:not(:first-child)>tr,
.uk-light .uk-table-divider>:first-child>tr:not(:first-child),
.uk-section-primary:not(.uk-preserve-color) .uk-table-divider>tr:not(:first-child),
.uk-section-primary:not(.uk-preserve-color) .uk-table-divider>:not(:first-child)>tr,
.uk-section-primary:not(.uk-preserve-color) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-section-secondary:not(.uk-preserve-color) .uk-table-divider>tr:not(:first-child),
.uk-section-secondary:not(.uk-preserve-color) .uk-table-divider>:not(:first-child)>tr,
.uk-section-secondary:not(.uk-preserve-color) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-tile-primary:not(.uk-preserve-color) .uk-table-divider>tr:not(:first-child),
.uk-tile-primary:not(.uk-preserve-color) .uk-table-divider>:not(:first-child)>tr,
.uk-tile-primary:not(.uk-preserve-color) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-divider>tr:not(:first-child),
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-divider>:not(:first-child)>tr,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-card-primary.uk-card-body .uk-table-divider>tr:not(:first-child),
.uk-card-primary.uk-card-body .uk-table-divider>:not(:first-child)>tr,
.uk-card-primary.uk-card-body .uk-table-divider>:first-child>tr:not(:first-child),
.uk-card-primary>:not([class*='uk-card-media']) .uk-table-divider>tr:not(:first-child),
.uk-card-primary>:not([class*='uk-card-media']) .uk-table-divider>:not(:first-child)>tr,
.uk-card-primary>:not([class*='uk-card-media']) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-card-secondary.uk-card-body .uk-table-divider>tr:not(:first-child),
.uk-card-secondary.uk-card-body .uk-table-divider>:not(:first-child)>tr,
.uk-card-secondary.uk-card-body .uk-table-divider>:first-child>tr:not(:first-child),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table-divider>tr:not(:first-child),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table-divider>:not(:first-child)>tr,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table-divider>:first-child>tr:not(:first-child),
.uk-overlay-primary .uk-table-divider>tr:not(:first-child),
.uk-overlay-primary .uk-table-divider>:not(:first-child)>tr,
.uk-overlay-primary .uk-table-divider>:first-child>tr:not(:first-child),
.uk-offcanvas-bar .uk-table-divider>tr:not(:first-child),
.uk-offcanvas-bar .uk-table-divider>:not(:first-child)>tr,
.uk-offcanvas-bar .uk-table-divider>:first-child>tr:not(:first-child) {
  border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-table-striped>tr:nth-of-type(odd),
.uk-light .uk-table-striped tbody tr:nth-of-type(odd),
.uk-section-primary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(odd),
.uk-section-primary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-section-secondary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(odd),
.uk-section-secondary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-tile-primary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(odd),
.uk-tile-primary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-striped>tr:nth-of-type(odd),
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-card-primary.uk-card-body .uk-table-striped>tr:nth-of-type(odd),
.uk-card-primary.uk-card-body .uk-table-striped tbody tr:nth-of-type(odd),
.uk-card-primary>:not([class*='uk-card-media']) .uk-table-striped>tr:nth-of-type(odd),
.uk-card-primary>:not([class*='uk-card-media']) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-card-secondary.uk-card-body .uk-table-striped>tr:nth-of-type(odd),
.uk-card-secondary.uk-card-body .uk-table-striped tbody tr:nth-of-type(odd),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table-striped>tr:nth-of-type(odd),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table-striped tbody tr:nth-of-type(odd),
.uk-overlay-primary .uk-table-striped>tr:nth-of-type(odd),
.uk-overlay-primary .uk-table-striped tbody tr:nth-of-type(odd),
.uk-offcanvas-bar .uk-table-striped>tr:nth-of-type(odd),
.uk-offcanvas-bar .uk-table-striped tbody tr:nth-of-type(odd) {
  background: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-table-hover>tr:hover,
.uk-light .uk-table-hover tbody tr:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-table-hover>tr:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-table-hover tbody tr:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-table-hover>tr:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-table-hover tbody tr:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-table-hover>tr:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-table-hover tbody tr:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-hover>tr:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-table-hover tbody tr:hover,
.uk-card-primary.uk-card-body .uk-table-hover>tr:hover,
.uk-card-primary.uk-card-body .uk-table-hover tbody tr:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-table-hover>tr:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-table-hover tbody tr:hover,
.uk-card-secondary.uk-card-body .uk-table-hover>tr:hover,
.uk-card-secondary.uk-card-body .uk-table-hover tbody tr:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table-hover>tr:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-table-hover tbody tr:hover,
.uk-overlay-primary .uk-table-hover>tr:hover,
.uk-overlay-primary .uk-table-hover tbody tr:hover,
.uk-offcanvas-bar .uk-table-hover>tr:hover,
.uk-offcanvas-bar .uk-table-hover tbody tr:hover {
  background: rgba(255, 255, 255, 0.08)
}

.uk-light .uk-icon-link,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link,
.uk-card-primary.uk-card-body .uk-icon-link,
.uk-card-primary>:not([class*='uk-card-media']) .uk-icon-link,
.uk-card-secondary.uk-card-body .uk-icon-link,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-icon-link,
.uk-overlay-primary .uk-icon-link,
.uk-offcanvas-bar .uk-icon-link {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-icon-link:hover,
.uk-light .uk-icon-link:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-link:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-link:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:focus,
.uk-card-primary.uk-card-body .uk-icon-link:hover,
.uk-card-primary.uk-card-body .uk-icon-link:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-icon-link:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-icon-link:focus,
.uk-card-secondary.uk-card-body .uk-icon-link:hover,
.uk-card-secondary.uk-card-body .uk-icon-link:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-icon-link:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-icon-link:focus,
.uk-overlay-primary .uk-icon-link:hover,
.uk-overlay-primary .uk-icon-link:focus,
.uk-offcanvas-bar .uk-icon-link:hover,
.uk-offcanvas-bar .uk-icon-link:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-icon-link:active,
.uk-light .uk-active>.uk-icon-link,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-link:active,
.uk-section-primary:not(.uk-preserve-color) .uk-active>.uk-icon-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-active>.uk-icon-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-active>.uk-icon-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-active>.uk-icon-link,
.uk-card-primary.uk-card-body .uk-icon-link:active,
.uk-card-primary.uk-card-body .uk-active>.uk-icon-link,
.uk-card-primary>:not([class*='uk-card-media']) .uk-icon-link:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-active>.uk-icon-link,
.uk-card-secondary.uk-card-body .uk-icon-link:active,
.uk-card-secondary.uk-card-body .uk-active>.uk-icon-link,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-icon-link:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-active>.uk-icon-link,
.uk-overlay-primary .uk-icon-link:active,
.uk-overlay-primary .uk-active>.uk-icon-link,
.uk-offcanvas-bar .uk-icon-link:active,
.uk-offcanvas-bar .uk-active>.uk-icon-link {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-icon-button,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-button,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-button,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-button,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button,
.uk-card-primary.uk-card-body .uk-icon-button,
.uk-card-primary>:not([class*='uk-card-media']) .uk-icon-button,
.uk-card-secondary.uk-card-body .uk-icon-button,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-icon-button,
.uk-overlay-primary .uk-icon-button,
.uk-offcanvas-bar .uk-icon-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-icon-button:hover,
.uk-light .uk-icon-button:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-button:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-button:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:focus,
.uk-card-primary.uk-card-body .uk-icon-button:hover,
.uk-card-primary.uk-card-body .uk-icon-button:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-icon-button:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-icon-button:focus,
.uk-card-secondary.uk-card-body .uk-icon-button:hover,
.uk-card-secondary.uk-card-body .uk-icon-button:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-icon-button:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-icon-button:focus,
.uk-overlay-primary .uk-icon-button:hover,
.uk-overlay-primary .uk-icon-button:focus,
.uk-offcanvas-bar .uk-icon-button:hover,
.uk-offcanvas-bar .uk-icon-button:focus {
  background-color: rgba(242, 242, 242, 0.1);
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-icon-button:active,
.uk-section-primary:not(.uk-preserve-color) .uk-icon-button:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:active,
.uk-card-primary.uk-card-body .uk-icon-button:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-icon-button:active,
.uk-card-secondary.uk-card-body .uk-icon-button:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-icon-button:active,
.uk-overlay-primary .uk-icon-button:active,
.uk-offcanvas-bar .uk-icon-button:active {
  background-color: rgba(230, 230, 230, 0.1);
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-input,
.uk-light .uk-select,
.uk-light .uk-textarea,
.uk-section-primary:not(.uk-preserve-color) .uk-input,
.uk-section-primary:not(.uk-preserve-color) .uk-select,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea,
.uk-section-secondary:not(.uk-preserve-color) .uk-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-select,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea,
.uk-tile-primary:not(.uk-preserve-color) .uk-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-select,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-select,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea,
.uk-card-primary.uk-card-body .uk-input,
.uk-card-primary.uk-card-body .uk-select,
.uk-card-primary.uk-card-body .uk-textarea,
.uk-card-primary>:not([class*='uk-card-media']) .uk-input,
.uk-card-primary>:not([class*='uk-card-media']) .uk-select,
.uk-card-primary>:not([class*='uk-card-media']) .uk-textarea,
.uk-card-secondary.uk-card-body .uk-input,
.uk-card-secondary.uk-card-body .uk-select,
.uk-card-secondary.uk-card-body .uk-textarea,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-input,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-select,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-textarea,
.uk-overlay-primary .uk-input,
.uk-overlay-primary .uk-select,
.uk-overlay-primary .uk-textarea,
.uk-offcanvas-bar .uk-input,
.uk-offcanvas-bar .uk-select,
.uk-offcanvas-bar .uk-textarea {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  border-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-input:focus,
.uk-light .uk-select:focus,
.uk-light .uk-textarea:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-input:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-input:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-select:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-input:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-select:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-card-primary.uk-card-body .uk-input:focus,
.uk-card-primary.uk-card-body .uk-select:focus,
.uk-card-primary.uk-card-body .uk-textarea:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-input:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-select:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-textarea:focus,
.uk-card-secondary.uk-card-body .uk-input:focus,
.uk-card-secondary.uk-card-body .uk-select:focus,
.uk-card-secondary.uk-card-body .uk-textarea:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-input:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-select:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-textarea:focus,
.uk-overlay-primary .uk-input:focus,
.uk-overlay-primary .uk-select:focus,
.uk-overlay-primary .uk-textarea:focus,
.uk-offcanvas-bar .uk-input:focus,
.uk-offcanvas-bar .uk-select:focus,
.uk-offcanvas-bar .uk-textarea:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-input::-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder,
.uk-card-primary.uk-card-body .uk-input::-ms-input-placeholder,
.uk-card-primary>:not([class*='uk-card-media']) .uk-input::-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-input::-ms-input-placeholder,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-input::-ms-input-placeholder,
.uk-overlay-primary .uk-input::-ms-input-placeholder,
.uk-offcanvas-bar .uk-input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important
}

.uk-light .uk-input::placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-input::placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-input::placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-input::placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input::placeholder,
.uk-card-primary.uk-card-body .uk-input::placeholder,
.uk-card-primary>:not([class*='uk-card-media']) .uk-input::placeholder,
.uk-card-secondary.uk-card-body .uk-input::placeholder,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-input::placeholder,
.uk-overlay-primary .uk-input::placeholder,
.uk-offcanvas-bar .uk-input::placeholder {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-textarea::-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder,
.uk-card-primary.uk-card-body .uk-textarea::-ms-input-placeholder,
.uk-card-primary>:not([class*='uk-card-media']) .uk-textarea::-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-textarea::-ms-input-placeholder,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-textarea::-ms-input-placeholder,
.uk-overlay-primary .uk-textarea::-ms-input-placeholder,
.uk-offcanvas-bar .uk-textarea::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important
}

.uk-light .uk-textarea::placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea::placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea::placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea::placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::placeholder,
.uk-card-primary.uk-card-body .uk-textarea::placeholder,
.uk-card-primary>:not([class*='uk-card-media']) .uk-textarea::placeholder,
.uk-card-secondary.uk-card-body .uk-textarea::placeholder,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-textarea::placeholder,
.uk-overlay-primary .uk-textarea::placeholder,
.uk-offcanvas-bar .uk-textarea::placeholder {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-select:not([multiple]):not([size]),
.uk-section-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]),
.uk-section-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]),
.uk-tile-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]),
.uk-tile-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]),
.uk-card-primary.uk-card-body .uk-select:not([multiple]):not([size]),
.uk-card-primary>:not([class*='uk-card-media']) .uk-select:not([multiple]):not([size]),
.uk-card-secondary.uk-card-body .uk-select:not([multiple]):not([size]),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-select:not([multiple]):not([size]),
.uk-overlay-primary .uk-select:not([multiple]):not([size]),
.uk-offcanvas-bar .uk-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-light .uk-input[list]:hover,
.uk-light .uk-input[list]:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-input[list]:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-card-primary.uk-card-body .uk-input[list]:hover,
.uk-card-primary.uk-card-body .uk-input[list]:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-input[list]:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-input[list]:focus,
.uk-card-secondary.uk-card-body .uk-input[list]:hover,
.uk-card-secondary.uk-card-body .uk-input[list]:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-input[list]:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-input[list]:focus,
.uk-overlay-primary .uk-input[list]:hover,
.uk-overlay-primary .uk-input[list]:focus,
.uk-offcanvas-bar .uk-input[list]:hover,
.uk-offcanvas-bar .uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-light .uk-radio,
.uk-light .uk-checkbox,
.uk-section-primary:not(.uk-preserve-color) .uk-radio,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox,
.uk-card-primary.uk-card-body .uk-radio,
.uk-card-primary.uk-card-body .uk-checkbox,
.uk-card-primary>:not([class*='uk-card-media']) .uk-radio,
.uk-card-primary>:not([class*='uk-card-media']) .uk-checkbox,
.uk-card-secondary.uk-card-body .uk-radio,
.uk-card-secondary.uk-card-body .uk-checkbox,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-radio,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-checkbox,
.uk-overlay-primary .uk-radio,
.uk-overlay-primary .uk-checkbox,
.uk-offcanvas-bar .uk-radio,
.uk-offcanvas-bar .uk-checkbox {
  background-color: rgba(242, 242, 242, 0.1);
  border-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-radio:focus,
.uk-light .uk-checkbox:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-radio:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-card-primary.uk-card-body .uk-radio:focus,
.uk-card-primary.uk-card-body .uk-checkbox:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-radio:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-checkbox:focus,
.uk-card-secondary.uk-card-body .uk-radio:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-radio:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-checkbox:focus,
.uk-overlay-primary .uk-radio:focus,
.uk-overlay-primary .uk-checkbox:focus,
.uk-offcanvas-bar .uk-radio:focus,
.uk-offcanvas-bar .uk-checkbox:focus {
  border-color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-radio:checked,
.uk-light .uk-checkbox:checked,
.uk-light .uk-checkbox:indeterminate,
.uk-section-primary:not(.uk-preserve-color) .uk-radio:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-card-primary.uk-card-body .uk-radio:checked,
.uk-card-primary.uk-card-body .uk-checkbox:checked,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-primary>:not([class*='uk-card-media']) .uk-radio:checked,
.uk-card-primary>:not([class*='uk-card-media']) .uk-checkbox:checked,
.uk-card-primary>:not([class*='uk-card-media']) .uk-checkbox:indeterminate,
.uk-card-secondary.uk-card-body .uk-radio:checked,
.uk-card-secondary.uk-card-body .uk-checkbox:checked,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-radio:checked,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-checkbox:checked,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-checkbox:indeterminate,
.uk-overlay-primary .uk-radio:checked,
.uk-overlay-primary .uk-checkbox:checked,
.uk-overlay-primary .uk-checkbox:indeterminate,
.uk-offcanvas-bar .uk-radio:checked,
.uk-offcanvas-bar .uk-checkbox:checked,
.uk-offcanvas-bar .uk-checkbox:indeterminate {
  background-color: #fff;
  border-color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-radio:checked:focus,
.uk-light .uk-checkbox:checked:focus,
.uk-light .uk-checkbox:indeterminate:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-radio:checked:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-card-primary.uk-card-body .uk-radio:checked:focus,
.uk-card-primary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-radio:checked:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-checkbox:checked:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-checkbox:indeterminate:focus,
.uk-card-secondary.uk-card-body .uk-radio:checked:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-radio:checked:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-checkbox:checked:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-checkbox:indeterminate:focus,
.uk-overlay-primary .uk-radio:checked:focus,
.uk-overlay-primary .uk-checkbox:checked:focus,
.uk-overlay-primary .uk-checkbox:indeterminate:focus,
.uk-offcanvas-bar .uk-radio:checked:focus,
.uk-offcanvas-bar .uk-checkbox:checked:focus,
.uk-offcanvas-bar .uk-checkbox:indeterminate:focus {
  background-color: #e6e6e6
}

.uk-light .uk-radio:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-radio:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked,
.uk-card-primary.uk-card-body .uk-radio:checked,
.uk-card-primary>:not([class*='uk-card-media']) .uk-radio:checked,
.uk-card-secondary.uk-card-body .uk-radio:checked,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-radio:checked,
.uk-overlay-primary .uk-radio:checked,
.uk-offcanvas-bar .uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-checkbox:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-card-primary.uk-card-body .uk-checkbox:checked,
.uk-card-primary>:not([class*='uk-card-media']) .uk-checkbox:checked,
.uk-card-secondary.uk-card-body .uk-checkbox:checked,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-checkbox:checked,
.uk-overlay-primary .uk-checkbox:checked,
.uk-offcanvas-bar .uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-light .uk-checkbox:indeterminate,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-primary>:not([class*='uk-card-media']) .uk-checkbox:indeterminate,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-checkbox:indeterminate,
.uk-overlay-primary .uk-checkbox:indeterminate,
.uk-offcanvas-bar .uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-form-label,
.uk-section-primary:not(.uk-preserve-color) .uk-form-label,
.uk-section-secondary:not(.uk-preserve-color) .uk-form-label,
.uk-tile-primary:not(.uk-preserve-color) .uk-form-label,
.uk-tile-secondary:not(.uk-preserve-color) .uk-form-label,
.uk-card-primary.uk-card-body .uk-form-label,
.uk-card-primary>:not([class*='uk-card-media']) .uk-form-label,
.uk-card-secondary.uk-card-body .uk-form-label,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-form-label,
.uk-overlay-primary .uk-form-label,
.uk-offcanvas-bar .uk-form-label {
  color: #fff
}

.uk-light .uk-form-icon,
.uk-section-primary:not(.uk-preserve-color) .uk-form-icon,
.uk-section-secondary:not(.uk-preserve-color) .uk-form-icon,
.uk-tile-primary:not(.uk-preserve-color) .uk-form-icon,
.uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon,
.uk-card-primary.uk-card-body .uk-form-icon,
.uk-card-primary>:not([class*='uk-card-media']) .uk-form-icon,
.uk-card-secondary.uk-card-body .uk-form-icon,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-form-icon,
.uk-overlay-primary .uk-form-icon,
.uk-offcanvas-bar .uk-form-icon {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-form-icon:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-form-icon:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-form-icon:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-form-icon:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon:hover,
.uk-card-primary.uk-card-body .uk-form-icon:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-form-icon:hover,
.uk-card-secondary.uk-card-body .uk-form-icon:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-form-icon:hover,
.uk-overlay-primary .uk-form-icon:hover,
.uk-offcanvas-bar .uk-form-icon:hover {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-button-default,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default,
.uk-card-primary.uk-card-body .uk-button-default,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-default,
.uk-card-secondary.uk-card-body .uk-button-default,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-default,
.uk-overlay-primary .uk-button-default,
.uk-offcanvas-bar .uk-button-default {
  background-color: transparent;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-button-default:hover,
.uk-light .uk-button-default:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:focus,
.uk-card-primary.uk-card-body .uk-button-default:hover,
.uk-card-primary.uk-card-body .uk-button-default:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-default:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-default:focus,
.uk-card-secondary.uk-card-body .uk-button-default:hover,
.uk-card-secondary.uk-card-body .uk-button-default:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-default:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-default:focus,
.uk-overlay-primary .uk-button-default:hover,
.uk-overlay-primary .uk-button-default:focus,
.uk-offcanvas-bar .uk-button-default:hover,
.uk-offcanvas-bar .uk-button-default:focus {
  background-color: transparent;
  color: #fff;
  border-color: #fff
}

.uk-light .uk-button-default:active,
.uk-light .uk-button-default.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default:active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-card-primary.uk-card-body .uk-button-default:active,
.uk-card-primary.uk-card-body .uk-button-default.uk-active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-default:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-default.uk-active,
.uk-card-secondary.uk-card-body .uk-button-default:active,
.uk-card-secondary.uk-card-body .uk-button-default.uk-active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-default:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-default.uk-active,
.uk-overlay-primary .uk-button-default:active,
.uk-overlay-primary .uk-button-default.uk-active,
.uk-offcanvas-bar .uk-button-default:active,
.uk-offcanvas-bar .uk-button-default.uk-active {
  background-color: transparent;
  color: #fff;
  border-color: #fff
}

.uk-light .uk-button-primary,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary,
.uk-card-primary.uk-card-body .uk-button-primary,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-primary,
.uk-card-secondary.uk-card-body .uk-button-primary,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-primary,
.uk-overlay-primary .uk-button-primary,
.uk-offcanvas-bar .uk-button-primary {
  background-color: #fff;
  color: #666
}

.uk-light .uk-button-primary:hover,
.uk-light .uk-button-primary:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:focus,
.uk-card-primary.uk-card-body .uk-button-primary:hover,
.uk-card-primary.uk-card-body .uk-button-primary:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-primary:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-primary:focus,
.uk-card-secondary.uk-card-body .uk-button-primary:hover,
.uk-card-secondary.uk-card-body .uk-button-primary:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-primary:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-primary:focus,
.uk-overlay-primary .uk-button-primary:hover,
.uk-overlay-primary .uk-button-primary:focus,
.uk-offcanvas-bar .uk-button-primary:hover,
.uk-offcanvas-bar .uk-button-primary:focus {
  background-color: #f2f2f2;
  color: #666
}

.uk-light .uk-button-primary:active,
.uk-light .uk-button-primary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary:active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-card-primary.uk-card-body .uk-button-primary:active,
.uk-card-primary.uk-card-body .uk-button-primary.uk-active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-primary:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-primary.uk-active,
.uk-card-secondary.uk-card-body .uk-button-primary:active,
.uk-card-secondary.uk-card-body .uk-button-primary.uk-active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-primary:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-primary.uk-active,
.uk-overlay-primary .uk-button-primary:active,
.uk-overlay-primary .uk-button-primary.uk-active,
.uk-offcanvas-bar .uk-button-primary:active,
.uk-offcanvas-bar .uk-button-primary.uk-active {
  background-color: #e6e6e6;
  color: #666
}

.uk-light .uk-button-secondary,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary,
.uk-card-primary.uk-card-body .uk-button-secondary,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-secondary,
.uk-card-secondary.uk-card-body .uk-button-secondary,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-secondary,
.uk-overlay-primary .uk-button-secondary,
.uk-offcanvas-bar .uk-button-secondary {
  background-color: #fff;
  color: #666
}

.uk-light .uk-button-secondary:hover,
.uk-light .uk-button-secondary:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:focus,
.uk-card-primary.uk-card-body .uk-button-secondary:hover,
.uk-card-primary.uk-card-body .uk-button-secondary:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-secondary:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-secondary:focus,
.uk-card-secondary.uk-card-body .uk-button-secondary:hover,
.uk-card-secondary.uk-card-body .uk-button-secondary:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-secondary:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-secondary:focus,
.uk-overlay-primary .uk-button-secondary:hover,
.uk-overlay-primary .uk-button-secondary:focus,
.uk-offcanvas-bar .uk-button-secondary:hover,
.uk-offcanvas-bar .uk-button-secondary:focus {
  background-color: #f2f2f2;
  color: #666
}

.uk-light .uk-button-secondary:active,
.uk-light .uk-button-secondary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-card-primary.uk-card-body .uk-button-secondary:active,
.uk-card-primary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-secondary:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-secondary.uk-active,
.uk-card-secondary.uk-card-body .uk-button-secondary:active,
.uk-card-secondary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-secondary:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-secondary.uk-active,
.uk-overlay-primary .uk-button-secondary:active,
.uk-overlay-primary .uk-button-secondary.uk-active,
.uk-offcanvas-bar .uk-button-secondary:active,
.uk-offcanvas-bar .uk-button-secondary.uk-active {
  background-color: #e6e6e6;
  color: #666
}

.uk-light .uk-button-text,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text,
.uk-card-primary.uk-card-body .uk-button-text,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-text,
.uk-card-secondary.uk-card-body .uk-button-text,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-text,
.uk-overlay-primary .uk-button-text,
.uk-offcanvas-bar .uk-button-text {
  color: #fff
}

.uk-light .uk-button-text::before,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text::before,
.uk-card-primary.uk-card-body .uk-button-text::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-text::before,
.uk-card-secondary.uk-card-body .uk-button-text::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-text::before,
.uk-overlay-primary .uk-button-text::before,
.uk-offcanvas-bar .uk-button-text::before {
  border-bottom-color: #fff
}

.uk-light .uk-button-text:hover,
.uk-light .uk-button-text:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:focus,
.uk-card-primary.uk-card-body .uk-button-text:hover,
.uk-card-primary.uk-card-body .uk-button-text:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-text:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-text:focus,
.uk-card-secondary.uk-card-body .uk-button-text:hover,
.uk-card-secondary.uk-card-body .uk-button-text:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-text:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-text:focus,
.uk-overlay-primary .uk-button-text:hover,
.uk-overlay-primary .uk-button-text:focus,
.uk-offcanvas-bar .uk-button-text:hover,
.uk-offcanvas-bar .uk-button-text:focus {
  color: #fff
}

.uk-light .uk-button-text:disabled,
.uk-section-primary:not(.uk-preserve-color) .uk-button-text:disabled,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-text:disabled,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-text:disabled,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:disabled,
.uk-card-primary.uk-card-body .uk-button-text:disabled,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-text:disabled,
.uk-card-secondary.uk-card-body .uk-button-text:disabled,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-text:disabled,
.uk-overlay-primary .uk-button-text:disabled,
.uk-offcanvas-bar .uk-button-text:disabled {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-button-link,
.uk-section-primary:not(.uk-preserve-color) .uk-button-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-link,
.uk-card-primary.uk-card-body .uk-button-link,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-link,
.uk-card-secondary.uk-card-body .uk-button-link,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-link,
.uk-overlay-primary .uk-button-link,
.uk-offcanvas-bar .uk-button-link {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-button-link:hover,
.uk-light .uk-button-link:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-button-link:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-button-link:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-link:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-link:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-link:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-link:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-link:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-link:focus,
.uk-card-primary.uk-card-body .uk-button-link:hover,
.uk-card-primary.uk-card-body .uk-button-link:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-link:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-button-link:focus,
.uk-card-secondary.uk-card-body .uk-button-link:hover,
.uk-card-secondary.uk-card-body .uk-button-link:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-link:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-button-link:focus,
.uk-overlay-primary .uk-button-link:hover,
.uk-overlay-primary .uk-button-link:focus,
.uk-offcanvas-bar .uk-button-link:hover,
.uk-offcanvas-bar .uk-button-link:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-close,
.uk-section-primary:not(.uk-preserve-color) .uk-close,
.uk-section-secondary:not(.uk-preserve-color) .uk-close,
.uk-tile-primary:not(.uk-preserve-color) .uk-close,
.uk-tile-secondary:not(.uk-preserve-color) .uk-close,
.uk-card-primary.uk-card-body .uk-close,
.uk-card-primary>:not([class*='uk-card-media']) .uk-close,
.uk-card-secondary.uk-card-body .uk-close,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-close,
.uk-overlay-primary .uk-close,
.uk-offcanvas-bar .uk-close {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-close:hover,
.uk-light .uk-close:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-close:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-close:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-close:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-close:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-close:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-close:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-close:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-close:focus,
.uk-card-primary.uk-card-body .uk-close:hover,
.uk-card-primary.uk-card-body .uk-close:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-close:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-close:focus,
.uk-card-secondary.uk-card-body .uk-close:hover,
.uk-card-secondary.uk-card-body .uk-close:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-close:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-close:focus,
.uk-overlay-primary .uk-close:hover,
.uk-overlay-primary .uk-close:focus,
.uk-offcanvas-bar .uk-close:hover,
.uk-offcanvas-bar .uk-close:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-totop,
.uk-section-primary:not(.uk-preserve-color) .uk-totop,
.uk-section-secondary:not(.uk-preserve-color) .uk-totop,
.uk-tile-primary:not(.uk-preserve-color) .uk-totop,
.uk-tile-secondary:not(.uk-preserve-color) .uk-totop,
.uk-card-primary.uk-card-body .uk-totop,
.uk-card-primary>:not([class*='uk-card-media']) .uk-totop,
.uk-card-secondary.uk-card-body .uk-totop,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-totop,
.uk-overlay-primary .uk-totop,
.uk-offcanvas-bar .uk-totop {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-totop:hover,
.uk-light .uk-totop:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-totop:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-totop:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-totop:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-totop:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-totop:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-totop:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-totop:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-totop:focus,
.uk-card-primary.uk-card-body .uk-totop:hover,
.uk-card-primary.uk-card-body .uk-totop:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-totop:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-totop:focus,
.uk-card-secondary.uk-card-body .uk-totop:hover,
.uk-card-secondary.uk-card-body .uk-totop:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-totop:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-totop:focus,
.uk-overlay-primary .uk-totop:hover,
.uk-overlay-primary .uk-totop:focus,
.uk-offcanvas-bar .uk-totop:hover,
.uk-offcanvas-bar .uk-totop:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-totop:active,
.uk-section-primary:not(.uk-preserve-color) .uk-totop:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-totop:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-totop:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-totop:active,
.uk-card-primary.uk-card-body .uk-totop:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-totop:active,
.uk-card-secondary.uk-card-body .uk-totop:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-totop:active,
.uk-overlay-primary .uk-totop:active,
.uk-offcanvas-bar .uk-totop:active {
  color: #fff
}

.uk-light .uk-marker,
.uk-section-primary:not(.uk-preserve-color) .uk-marker,
.uk-section-secondary:not(.uk-preserve-color) .uk-marker,
.uk-tile-primary:not(.uk-preserve-color) .uk-marker,
.uk-tile-secondary:not(.uk-preserve-color) .uk-marker,
.uk-card-primary.uk-card-body .uk-marker,
.uk-card-primary>:not([class*='uk-card-media']) .uk-marker,
.uk-card-secondary.uk-card-body .uk-marker,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-marker,
.uk-overlay-primary .uk-marker,
.uk-offcanvas-bar .uk-marker {
  background: #f8f8f8;
  color: #666
}

.uk-light .uk-marker:hover,
.uk-light .uk-marker:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-marker:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-marker:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-marker:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-marker:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-marker:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-marker:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-marker:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-marker:focus,
.uk-card-primary.uk-card-body .uk-marker:hover,
.uk-card-primary.uk-card-body .uk-marker:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-marker:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-marker:focus,
.uk-card-secondary.uk-card-body .uk-marker:hover,
.uk-card-secondary.uk-card-body .uk-marker:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-marker:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-marker:focus,
.uk-overlay-primary .uk-marker:hover,
.uk-overlay-primary .uk-marker:focus,
.uk-offcanvas-bar .uk-marker:hover,
.uk-offcanvas-bar .uk-marker:focus {
  color: #666
}

.uk-light .uk-badge,
.uk-section-primary:not(.uk-preserve-color) .uk-badge,
.uk-section-secondary:not(.uk-preserve-color) .uk-badge,
.uk-tile-primary:not(.uk-preserve-color) .uk-badge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-badge,
.uk-card-primary.uk-card-body .uk-badge,
.uk-card-primary>:not([class*='uk-card-media']) .uk-badge,
.uk-card-secondary.uk-card-body .uk-badge,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-badge,
.uk-overlay-primary .uk-badge,
.uk-offcanvas-bar .uk-badge {
  background-color: #fff;
  color: #666
}

.uk-light .uk-badge:hover,
.uk-light .uk-badge:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-badge:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-badge:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-badge:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-badge:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-badge:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-badge:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-badge:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-badge:focus,
.uk-card-primary.uk-card-body .uk-badge:hover,
.uk-card-primary.uk-card-body .uk-badge:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-badge:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-badge:focus,
.uk-card-secondary.uk-card-body .uk-badge:hover,
.uk-card-secondary.uk-card-body .uk-badge:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-badge:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-badge:focus,
.uk-overlay-primary .uk-badge:hover,
.uk-overlay-primary .uk-badge:focus,
.uk-offcanvas-bar .uk-badge:hover,
.uk-offcanvas-bar .uk-badge:focus {
  color: #666
}

.uk-light .uk-label,
.uk-section-primary:not(.uk-preserve-color) .uk-label,
.uk-section-secondary:not(.uk-preserve-color) .uk-label,
.uk-tile-primary:not(.uk-preserve-color) .uk-label,
.uk-tile-secondary:not(.uk-preserve-color) .uk-label,
.uk-card-primary.uk-card-body .uk-label,
.uk-card-primary>:not([class*='uk-card-media']) .uk-label,
.uk-card-secondary.uk-card-body .uk-label,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-label,
.uk-overlay-primary .uk-label,
.uk-offcanvas-bar .uk-label {
  background-color: #fff;
  color: #666
}

.uk-light .uk-article-meta,
.uk-section-primary:not(.uk-preserve-color) .uk-article-meta,
.uk-section-secondary:not(.uk-preserve-color) .uk-article-meta,
.uk-tile-primary:not(.uk-preserve-color) .uk-article-meta,
.uk-tile-secondary:not(.uk-preserve-color) .uk-article-meta,
.uk-card-primary.uk-card-body .uk-article-meta,
.uk-card-primary>:not([class*='uk-card-media']) .uk-article-meta,
.uk-card-secondary.uk-card-body .uk-article-meta,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-article-meta,
.uk-overlay-primary .uk-article-meta,
.uk-offcanvas-bar .uk-article-meta {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-search-input,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input,
.uk-card-primary.uk-card-body .uk-search-input,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-input,
.uk-card-secondary.uk-card-body .uk-search-input,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-input,
.uk-overlay-primary .uk-search-input,
.uk-offcanvas-bar .uk-search-input {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-search-input:-ms-input-placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder,
.uk-card-primary.uk-card-body .uk-search-input:-ms-input-placeholder,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-input:-ms-input-placeholder,
.uk-card-secondary.uk-card-body .uk-search-input:-ms-input-placeholder,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-input:-ms-input-placeholder,
.uk-overlay-primary .uk-search-input:-ms-input-placeholder,
.uk-offcanvas-bar .uk-search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important
}

.uk-light .uk-search-input::placeholder,
.uk-section-primary:not(.uk-preserve-color) .uk-search-input::placeholder,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-input::placeholder,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-input::placeholder,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::placeholder,
.uk-card-primary.uk-card-body .uk-search-input::placeholder,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-input::placeholder,
.uk-card-secondary.uk-card-body .uk-search-input::placeholder,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-input::placeholder,
.uk-overlay-primary .uk-search-input::placeholder,
.uk-offcanvas-bar .uk-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-search .uk-search-icon,
.uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon,
.uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon,
.uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon,
.uk-card-primary.uk-card-body .uk-search .uk-search-icon,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search .uk-search-icon,
.uk-card-secondary.uk-card-body .uk-search .uk-search-icon,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search .uk-search-icon,
.uk-overlay-primary .uk-search .uk-search-icon,
.uk-offcanvas-bar .uk-search .uk-search-icon {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-search .uk-search-icon:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover,
.uk-card-primary.uk-card-body .uk-search .uk-search-icon:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search .uk-search-icon:hover,
.uk-card-secondary.uk-card-body .uk-search .uk-search-icon:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search .uk-search-icon:hover,
.uk-overlay-primary .uk-search .uk-search-icon:hover,
.uk-offcanvas-bar .uk-search .uk-search-icon:hover {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-search-default .uk-search-input,
.uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input,
.uk-card-primary.uk-card-body .uk-search-default .uk-search-input,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-default .uk-search-input,
.uk-card-secondary.uk-card-body .uk-search-default .uk-search-input,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-default .uk-search-input,
.uk-overlay-primary .uk-search-default .uk-search-input,
.uk-offcanvas-bar .uk-search-default .uk-search-input {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-search-default .uk-search-input:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus,
.uk-card-primary.uk-card-body .uk-search-default .uk-search-input:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-default .uk-search-input:focus,
.uk-card-secondary.uk-card-body .uk-search-default .uk-search-input:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-default .uk-search-input:focus,
.uk-overlay-primary .uk-search-default .uk-search-input:focus,
.uk-offcanvas-bar .uk-search-default .uk-search-input:focus {
  background-color: transparent
}

.uk-light .uk-search-navbar .uk-search-input,
.uk-section-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input,
.uk-card-primary.uk-card-body .uk-search-navbar .uk-search-input,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-navbar .uk-search-input,
.uk-card-secondary.uk-card-body .uk-search-navbar .uk-search-input,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-navbar .uk-search-input,
.uk-overlay-primary .uk-search-navbar .uk-search-input,
.uk-offcanvas-bar .uk-search-navbar .uk-search-input {
  background-color: transparent
}

.uk-light .uk-search-large .uk-search-input,
.uk-section-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input,
.uk-card-primary.uk-card-body .uk-search-large .uk-search-input,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-large .uk-search-input,
.uk-card-secondary.uk-card-body .uk-search-large .uk-search-input,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-large .uk-search-input,
.uk-overlay-primary .uk-search-large .uk-search-input,
.uk-offcanvas-bar .uk-search-large .uk-search-input {
  background-color: transparent
}

.uk-light .uk-search-toggle,
.uk-section-primary:not(.uk-preserve-color) .uk-search-toggle,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle,
.uk-card-primary.uk-card-body .uk-search-toggle,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-toggle,
.uk-card-secondary.uk-card-body .uk-search-toggle,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-toggle,
.uk-overlay-primary .uk-search-toggle,
.uk-offcanvas-bar .uk-search-toggle {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-search-toggle:hover,
.uk-light .uk-search-toggle:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle:focus,
.uk-card-primary.uk-card-body .uk-search-toggle:hover,
.uk-card-primary.uk-card-body .uk-search-toggle:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-toggle:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-search-toggle:focus,
.uk-card-secondary.uk-card-body .uk-search-toggle:hover,
.uk-card-secondary.uk-card-body .uk-search-toggle:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-toggle:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-search-toggle:focus,
.uk-overlay-primary .uk-search-toggle:hover,
.uk-overlay-primary .uk-search-toggle:focus,
.uk-offcanvas-bar .uk-search-toggle:hover,
.uk-offcanvas-bar .uk-search-toggle:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-accordion-title,
.uk-section-primary:not(.uk-preserve-color) .uk-accordion-title,
.uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title,
.uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title,
.uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title,
.uk-card-primary.uk-card-body .uk-accordion-title,
.uk-card-primary>:not([class*='uk-card-media']) .uk-accordion-title,
.uk-card-secondary.uk-card-body .uk-accordion-title,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-accordion-title,
.uk-overlay-primary .uk-accordion-title,
.uk-offcanvas-bar .uk-accordion-title {
  color: #fff
}

.uk-light .uk-accordion-title:hover,
.uk-light .uk-accordion-title:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-accordion-title:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-accordion-title:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title:focus,
.uk-card-primary.uk-card-body .uk-accordion-title:hover,
.uk-card-primary.uk-card-body .uk-accordion-title:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-accordion-title:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-accordion-title:focus,
.uk-card-secondary.uk-card-body .uk-accordion-title:hover,
.uk-card-secondary.uk-card-body .uk-accordion-title:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-accordion-title:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-accordion-title:focus,
.uk-overlay-primary .uk-accordion-title:hover,
.uk-overlay-primary .uk-accordion-title:focus,
.uk-offcanvas-bar .uk-accordion-title:hover,
.uk-offcanvas-bar .uk-accordion-title:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-grid-divider>:not(.uk-first-column)::before,
.uk-section-primary:not(.uk-preserve-color) .uk-grid-divider>:not(.uk-first-column)::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider>:not(.uk-first-column)::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider>:not(.uk-first-column)::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider>:not(.uk-first-column)::before,
.uk-card-primary.uk-card-body .uk-grid-divider>:not(.uk-first-column)::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-grid-divider>:not(.uk-first-column)::before,
.uk-card-secondary.uk-card-body .uk-grid-divider>:not(.uk-first-column)::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-grid-divider>:not(.uk-first-column)::before,
.uk-overlay-primary .uk-grid-divider>:not(.uk-first-column)::before,
.uk-offcanvas-bar .uk-grid-divider>:not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-section-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-card-primary.uk-card-body .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-card-secondary.uk-card-body .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-overlay-primary .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before,
.uk-offcanvas-bar .uk-grid-divider.uk-grid-stack>.uk-grid-margin::before {
  border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-nav-parent-icon>.uk-parent>a::after,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent>a::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent>a::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent>a::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent>a::after,
.uk-card-primary.uk-card-body .uk-nav-parent-icon>.uk-parent>a::after,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-parent-icon>.uk-parent>a::after,
.uk-card-secondary.uk-card-body .uk-nav-parent-icon>.uk-parent>a::after,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-parent-icon>.uk-parent>a::after,
.uk-overlay-primary .uk-nav-parent-icon>.uk-parent>a::after,
.uk-offcanvas-bar .uk-nav-parent-icon>.uk-parent>a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-card-primary.uk-card-body .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-card-secondary.uk-card-body .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-overlay-primary .uk-nav-parent-icon>.uk-parent.uk-open>a::after,
.uk-offcanvas-bar .uk-nav-parent-icon>.uk-parent.uk-open>a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-nav-default>li>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default>li>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default>li>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default>li>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default>li>a,
.uk-card-primary.uk-card-body .uk-nav-default>li>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default>li>a,
.uk-card-secondary.uk-card-body .uk-nav-default>li>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default>li>a,
.uk-overlay-primary .uk-nav-default>li>a,
.uk-offcanvas-bar .uk-nav-default>li>a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-nav-default>li>a:hover,
.uk-light .uk-nav-default>li>a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default>li>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default>li>a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default>li>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default>li>a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default>li>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default>li>a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default>li>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default>li>a:focus,
.uk-card-primary.uk-card-body .uk-nav-default>li>a:hover,
.uk-card-primary.uk-card-body .uk-nav-default>li>a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default>li>a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default>li>a:focus,
.uk-card-secondary.uk-card-body .uk-nav-default>li>a:hover,
.uk-card-secondary.uk-card-body .uk-nav-default>li>a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default>li>a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default>li>a:focus,
.uk-overlay-primary .uk-nav-default>li>a:hover,
.uk-overlay-primary .uk-nav-default>li>a:focus,
.uk-offcanvas-bar .uk-nav-default>li>a:hover,
.uk-offcanvas-bar .uk-nav-default>li>a:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-nav-default>li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default>li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default>li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default>li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default>li.uk-active>a,
.uk-card-primary.uk-card-body .uk-nav-default>li.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default>li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-default>li.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default>li.uk-active>a,
.uk-overlay-primary .uk-nav-default>li.uk-active>a,
.uk-offcanvas-bar .uk-nav-default>li.uk-active>a {
  color: #fff
}

.uk-light .uk-nav-default .uk-nav-header,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header,
.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-header,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-header,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-header,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-header,
.uk-overlay-primary .uk-nav-default .uk-nav-header,
.uk-offcanvas-bar .uk-nav-default .uk-nav-header {
  color: #fff
}

.uk-light .uk-nav-default .uk-nav-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider,
.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-divider,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-divider,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-divider,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-divider,
.uk-overlay-primary .uk-nav-default .uk-nav-divider,
.uk-offcanvas-bar .uk-nav-default .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-nav-default .uk-nav-sub a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a,
.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a,
.uk-overlay-primary .uk-nav-default .uk-nav-sub a,
.uk-offcanvas-bar .uk-nav-default .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-nav-default .uk-nav-sub a:hover,
.uk-light .uk-nav-default .uk-nav-sub a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:focus,
.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:hover,
.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:focus,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a:hover,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:focus,
.uk-overlay-primary .uk-nav-default .uk-nav-sub a:hover,
.uk-overlay-primary .uk-nav-default .uk-nav-sub a:focus,
.uk-offcanvas-bar .uk-nav-default .uk-nav-sub a:hover,
.uk-offcanvas-bar .uk-nav-default .uk-nav-sub a:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-overlay-primary .uk-nav-default .uk-nav-sub li.uk-active>a,
.uk-offcanvas-bar .uk-nav-default .uk-nav-sub li.uk-active>a {
  color: #fff
}

.uk-light .uk-nav-primary>li>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary>li>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary>li>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a,
.uk-card-primary.uk-card-body .uk-nav-primary>li>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary>li>a,
.uk-card-secondary.uk-card-body .uk-nav-primary>li>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary>li>a,
.uk-overlay-primary .uk-nav-primary>li>a,
.uk-offcanvas-bar .uk-nav-primary>li>a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-nav-primary>li>a:hover,
.uk-light .uk-nav-primary>li>a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary>li>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary>li>a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary>li>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary>li>a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary>li>a:focus,
.uk-card-primary.uk-card-body .uk-nav-primary>li>a:hover,
.uk-card-primary.uk-card-body .uk-nav-primary>li>a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary>li>a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary>li>a:focus,
.uk-card-secondary.uk-card-body .uk-nav-primary>li>a:hover,
.uk-card-secondary.uk-card-body .uk-nav-primary>li>a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary>li>a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary>li>a:focus,
.uk-overlay-primary .uk-nav-primary>li>a:hover,
.uk-overlay-primary .uk-nav-primary>li>a:focus,
.uk-offcanvas-bar .uk-nav-primary>li>a:hover,
.uk-offcanvas-bar .uk-nav-primary>li>a:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-nav-primary>li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary>li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary>li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary>li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary>li.uk-active>a,
.uk-card-primary.uk-card-body .uk-nav-primary>li.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary>li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-primary>li.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary>li.uk-active>a,
.uk-overlay-primary .uk-nav-primary>li.uk-active>a,
.uk-offcanvas-bar .uk-nav-primary>li.uk-active>a {
  color: #fff
}

.uk-light .uk-nav-primary .uk-nav-header,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header,
.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-header,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-header,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-header,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-header,
.uk-overlay-primary .uk-nav-primary .uk-nav-header,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-header {
  color: #fff
}

.uk-light .uk-nav-primary .uk-nav-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider,
.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-divider,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-divider,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-divider,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-divider,
.uk-overlay-primary .uk-nav-primary .uk-nav-divider,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-nav-primary .uk-nav-sub a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a,
.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a,
.uk-overlay-primary .uk-nav-primary .uk-nav-sub a,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-nav-primary .uk-nav-sub a:hover,
.uk-light .uk-nav-primary .uk-nav-sub a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:focus,
.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover,
.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:focus,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:focus,
.uk-overlay-primary .uk-nav-primary .uk-nav-sub a:hover,
.uk-overlay-primary .uk-nav-primary .uk-nav-sub a:focus,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a:hover,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-overlay-primary .uk-nav-primary .uk-nav-sub li.uk-active>a,
.uk-offcanvas-bar .uk-nav-primary .uk-nav-sub li.uk-active>a {
  color: #fff
}

.uk-light .uk-navbar-nav>li>a,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a,
.uk-card-primary.uk-card-body .uk-navbar-nav>li>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-nav>li>a,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-nav>li>a,
.uk-overlay-primary .uk-navbar-nav>li>a,
.uk-offcanvas-bar .uk-navbar-nav>li>a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-navbar-nav>li:hover>a,
.uk-light .uk-navbar-nav>li>a:focus,
.uk-light .uk-navbar-nav>li>a.uk-open,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li:hover>a,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a.uk-open,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li:hover>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a.uk-open,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li:hover>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a.uk-open,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li:hover>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a.uk-open,
.uk-card-primary.uk-card-body .uk-navbar-nav>li:hover>a,
.uk-card-primary.uk-card-body .uk-navbar-nav>li>a:focus,
.uk-card-primary.uk-card-body .uk-navbar-nav>li>a.uk-open,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-nav>li:hover>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-nav>li>a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-nav>li>a.uk-open,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li:hover>a,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li>a:focus,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li>a.uk-open,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-nav>li:hover>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-nav>li>a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-nav>li>a.uk-open,
.uk-overlay-primary .uk-navbar-nav>li:hover>a,
.uk-overlay-primary .uk-navbar-nav>li>a:focus,
.uk-overlay-primary .uk-navbar-nav>li>a.uk-open,
.uk-offcanvas-bar .uk-navbar-nav>li:hover>a,
.uk-offcanvas-bar .uk-navbar-nav>li>a:focus,
.uk-offcanvas-bar .uk-navbar-nav>li>a.uk-open {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-navbar-nav>li>a:active,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li>a:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li>a:active,
.uk-card-primary.uk-card-body .uk-navbar-nav>li>a:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-nav>li>a:active,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li>a:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-nav>li>a:active,
.uk-overlay-primary .uk-navbar-nav>li>a:active,
.uk-offcanvas-bar .uk-navbar-nav>li>a:active {
  color: #fff
}

.uk-light .uk-navbar-nav>li.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav>li.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav>li.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav>li.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav>li.uk-active>a,
.uk-card-primary.uk-card-body .uk-navbar-nav>li.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-nav>li.uk-active>a,
.uk-card-secondary.uk-card-body .uk-navbar-nav>li.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-nav>li.uk-active>a,
.uk-overlay-primary .uk-navbar-nav>li.uk-active>a,
.uk-offcanvas-bar .uk-navbar-nav>li.uk-active>a {
  color: #fff
}

.uk-light .uk-navbar-item,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-item,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-item,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-item,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-item,
.uk-card-primary.uk-card-body .uk-navbar-item,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-item,
.uk-card-secondary.uk-card-body .uk-navbar-item,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-item,
.uk-overlay-primary .uk-navbar-item,
.uk-offcanvas-bar .uk-navbar-item {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-navbar-toggle,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle,
.uk-card-primary.uk-card-body .uk-navbar-toggle,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-toggle,
.uk-card-secondary.uk-card-body .uk-navbar-toggle,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-toggle,
.uk-overlay-primary .uk-navbar-toggle,
.uk-offcanvas-bar .uk-navbar-toggle {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-navbar-toggle:hover,
.uk-light .uk-navbar-toggle:focus,
.uk-light .uk-navbar-toggle.uk-open,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle.uk-open,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle.uk-open,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle.uk-open,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle.uk-open,
.uk-card-primary.uk-card-body .uk-navbar-toggle:hover,
.uk-card-primary.uk-card-body .uk-navbar-toggle:focus,
.uk-card-primary.uk-card-body .uk-navbar-toggle.uk-open,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-toggle:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-toggle:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-navbar-toggle.uk-open,
.uk-card-secondary.uk-card-body .uk-navbar-toggle:hover,
.uk-card-secondary.uk-card-body .uk-navbar-toggle:focus,
.uk-card-secondary.uk-card-body .uk-navbar-toggle.uk-open,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-toggle:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-toggle:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-navbar-toggle.uk-open,
.uk-overlay-primary .uk-navbar-toggle:hover,
.uk-overlay-primary .uk-navbar-toggle:focus,
.uk-overlay-primary .uk-navbar-toggle.uk-open,
.uk-offcanvas-bar .uk-navbar-toggle:hover,
.uk-offcanvas-bar .uk-navbar-toggle:focus,
.uk-offcanvas-bar .uk-navbar-toggle.uk-open {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-subnav>*>:first-child,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>*>:first-child,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>*>:first-child,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>*>:first-child,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>*>:first-child,
.uk-card-primary.uk-card-body .uk-subnav>*>:first-child,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav>*>:first-child,
.uk-card-secondary.uk-card-body .uk-subnav>*>:first-child,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav>*>:first-child,
.uk-overlay-primary .uk-subnav>*>:first-child,
.uk-offcanvas-bar .uk-subnav>*>:first-child {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-subnav>*>a:hover,
.uk-light .uk-subnav>*>a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>*>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>*>a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>*>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>*>a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>*>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>*>a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>*>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>*>a:focus,
.uk-card-primary.uk-card-body .uk-subnav>*>a:hover,
.uk-card-primary.uk-card-body .uk-subnav>*>a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav>*>a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav>*>a:focus,
.uk-card-secondary.uk-card-body .uk-subnav>*>a:hover,
.uk-card-secondary.uk-card-body .uk-subnav>*>a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav>*>a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav>*>a:focus,
.uk-overlay-primary .uk-subnav>*>a:hover,
.uk-overlay-primary .uk-subnav>*>a:focus,
.uk-offcanvas-bar .uk-subnav>*>a:hover,
.uk-offcanvas-bar .uk-subnav>*>a:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-subnav>.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>.uk-active>a,
.uk-card-primary.uk-card-body .uk-subnav>.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav>.uk-active>a,
.uk-card-secondary.uk-card-body .uk-subnav>.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav>.uk-active>a,
.uk-overlay-primary .uk-subnav>.uk-active>a,
.uk-offcanvas-bar .uk-subnav>.uk-active>a {
  color: #fff
}

.uk-light .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-card-primary.uk-card-body .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-card-secondary.uk-card-body .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-overlay-primary .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before,
.uk-offcanvas-bar .uk-subnav-divider>:nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-subnav-pill>*>:first-child,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>*>:first-child,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>:first-child,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>*>:first-child,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>:first-child,
.uk-card-primary.uk-card-body .uk-subnav-pill>*>:first-child,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav-pill>*>:first-child,
.uk-card-secondary.uk-card-body .uk-subnav-pill>*>:first-child,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav-pill>*>:first-child,
.uk-overlay-primary .uk-subnav-pill>*>:first-child,
.uk-offcanvas-bar .uk-subnav-pill>*>:first-child {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-subnav-pill>*>a:hover,
.uk-light .uk-subnav-pill>*>a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:focus,
.uk-card-primary.uk-card-body .uk-subnav-pill>*>a:hover,
.uk-card-primary.uk-card-body .uk-subnav-pill>*>a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav-pill>*>a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav-pill>*>a:focus,
.uk-card-secondary.uk-card-body .uk-subnav-pill>*>a:hover,
.uk-card-secondary.uk-card-body .uk-subnav-pill>*>a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav-pill>*>a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav-pill>*>a:focus,
.uk-overlay-primary .uk-subnav-pill>*>a:hover,
.uk-overlay-primary .uk-subnav-pill>*>a:focus,
.uk-offcanvas-bar .uk-subnav-pill>*>a:hover,
.uk-offcanvas-bar .uk-subnav-pill>*>a:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-subnav-pill>*>a:active,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>*>a:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>*>a:active,
.uk-card-primary.uk-card-body .uk-subnav-pill>*>a:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav-pill>*>a:active,
.uk-card-secondary.uk-card-body .uk-subnav-pill>*>a:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav-pill>*>a:active,
.uk-overlay-primary .uk-subnav-pill>*>a:active,
.uk-offcanvas-bar .uk-subnav-pill>*>a:active {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-subnav-pill>.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill>.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill>.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill>.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill>.uk-active>a,
.uk-card-primary.uk-card-body .uk-subnav-pill>.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav-pill>.uk-active>a,
.uk-card-secondary.uk-card-body .uk-subnav-pill>.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav-pill>.uk-active>a,
.uk-overlay-primary .uk-subnav-pill>.uk-active>a,
.uk-offcanvas-bar .uk-subnav-pill>.uk-active>a {
  background-color: #fff;
  color: #666
}

.uk-light .uk-subnav>.uk-disabled>a,
.uk-section-primary:not(.uk-preserve-color) .uk-subnav>.uk-disabled>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-subnav>.uk-disabled>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-subnav>.uk-disabled>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-subnav>.uk-disabled>a,
.uk-card-primary.uk-card-body .uk-subnav>.uk-disabled>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-subnav>.uk-disabled>a,
.uk-card-secondary.uk-card-body .uk-subnav>.uk-disabled>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-subnav>.uk-disabled>a,
.uk-overlay-primary .uk-subnav>.uk-disabled>a,
.uk-offcanvas-bar .uk-subnav>.uk-disabled>a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-breadcrumb>*>*,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>*>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>*>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>*,
.uk-card-primary.uk-card-body .uk-breadcrumb>*>*,
.uk-card-primary>:not([class*='uk-card-media']) .uk-breadcrumb>*>*,
.uk-card-secondary.uk-card-body .uk-breadcrumb>*>*,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-breadcrumb>*>*,
.uk-overlay-primary .uk-breadcrumb>*>*,
.uk-offcanvas-bar .uk-breadcrumb>*>* {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-breadcrumb>*>:hover,
.uk-light .uk-breadcrumb>*>:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>*>:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>*>:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>*>:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>*>:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>*>:focus,
.uk-card-primary.uk-card-body .uk-breadcrumb>*>:hover,
.uk-card-primary.uk-card-body .uk-breadcrumb>*>:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-breadcrumb>*>:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-breadcrumb>*>:focus,
.uk-card-secondary.uk-card-body .uk-breadcrumb>*>:hover,
.uk-card-secondary.uk-card-body .uk-breadcrumb>*>:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-breadcrumb>*>:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-breadcrumb>*>:focus,
.uk-overlay-primary .uk-breadcrumb>*>:hover,
.uk-overlay-primary .uk-breadcrumb>*>:focus,
.uk-offcanvas-bar .uk-breadcrumb>*>:hover,
.uk-offcanvas-bar .uk-breadcrumb>*>:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-breadcrumb>:last-child>*,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>:last-child>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>:last-child>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>:last-child>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>:last-child>*,
.uk-card-primary.uk-card-body .uk-breadcrumb>:last-child>*,
.uk-card-primary>:not([class*='uk-card-media']) .uk-breadcrumb>:last-child>*,
.uk-card-secondary.uk-card-body .uk-breadcrumb>:last-child>*,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-breadcrumb>:last-child>*,
.uk-overlay-primary .uk-breadcrumb>:last-child>*,
.uk-offcanvas-bar .uk-breadcrumb>:last-child>* {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-card-primary.uk-card-body .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-card-secondary.uk-card-body .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-overlay-primary .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before,
.uk-offcanvas-bar .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-pagination>*>*,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>*>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>*>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>*>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>*>*,
.uk-card-primary.uk-card-body .uk-pagination>*>*,
.uk-card-primary>:not([class*='uk-card-media']) .uk-pagination>*>*,
.uk-card-secondary.uk-card-body .uk-pagination>*>*,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-pagination>*>*,
.uk-overlay-primary .uk-pagination>*>*,
.uk-offcanvas-bar .uk-pagination>*>* {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-pagination>*>:hover,
.uk-light .uk-pagination>*>:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>*>:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>*>:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>*>:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>*>:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>*>:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>*>:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>*>:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>*>:focus,
.uk-card-primary.uk-card-body .uk-pagination>*>:hover,
.uk-card-primary.uk-card-body .uk-pagination>*>:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-pagination>*>:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-pagination>*>:focus,
.uk-card-secondary.uk-card-body .uk-pagination>*>:hover,
.uk-card-secondary.uk-card-body .uk-pagination>*>:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-pagination>*>:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-pagination>*>:focus,
.uk-overlay-primary .uk-pagination>*>:hover,
.uk-overlay-primary .uk-pagination>*>:focus,
.uk-offcanvas-bar .uk-pagination>*>:hover,
.uk-offcanvas-bar .uk-pagination>*>:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-pagination>.uk-active>*,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>.uk-active>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>.uk-active>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>.uk-active>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>.uk-active>*,
.uk-card-primary.uk-card-body .uk-pagination>.uk-active>*,
.uk-card-primary>:not([class*='uk-card-media']) .uk-pagination>.uk-active>*,
.uk-card-secondary.uk-card-body .uk-pagination>.uk-active>*,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-pagination>.uk-active>*,
.uk-overlay-primary .uk-pagination>.uk-active>*,
.uk-offcanvas-bar .uk-pagination>.uk-active>* {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-pagination>.uk-disabled>*,
.uk-section-primary:not(.uk-preserve-color) .uk-pagination>.uk-disabled>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-pagination>.uk-disabled>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-pagination>.uk-disabled>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-pagination>.uk-disabled>*,
.uk-card-primary.uk-card-body .uk-pagination>.uk-disabled>*,
.uk-card-primary>:not([class*='uk-card-media']) .uk-pagination>.uk-disabled>*,
.uk-card-secondary.uk-card-body .uk-pagination>.uk-disabled>*,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-pagination>.uk-disabled>*,
.uk-overlay-primary .uk-pagination>.uk-disabled>*,
.uk-offcanvas-bar .uk-pagination>.uk-disabled>* {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-tab::before,
.uk-section-primary:not(.uk-preserve-color) .uk-tab::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab::before,
.uk-card-primary.uk-card-body .uk-tab::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-tab::before,
.uk-card-secondary.uk-card-body .uk-tab::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-tab::before,
.uk-overlay-primary .uk-tab::before,
.uk-offcanvas-bar .uk-tab::before {
  border-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-tab>*>a,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>*>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>*>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>*>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>*>a,
.uk-card-primary.uk-card-body .uk-tab>*>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-tab>*>a,
.uk-card-secondary.uk-card-body .uk-tab>*>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-tab>*>a,
.uk-overlay-primary .uk-tab>*>a,
.uk-offcanvas-bar .uk-tab>*>a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-tab>*>a:hover,
.uk-light .uk-tab>*>a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>*>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>*>a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>*>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>*>a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>*>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>*>a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>*>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>*>a:focus,
.uk-card-primary.uk-card-body .uk-tab>*>a:hover,
.uk-card-primary.uk-card-body .uk-tab>*>a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-tab>*>a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-tab>*>a:focus,
.uk-card-secondary.uk-card-body .uk-tab>*>a:hover,
.uk-card-secondary.uk-card-body .uk-tab>*>a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-tab>*>a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-tab>*>a:focus,
.uk-overlay-primary .uk-tab>*>a:hover,
.uk-overlay-primary .uk-tab>*>a:focus,
.uk-offcanvas-bar .uk-tab>*>a:hover,
.uk-offcanvas-bar .uk-tab>*>a:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-tab>.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>.uk-active>a,
.uk-card-primary.uk-card-body .uk-tab>.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-tab>.uk-active>a,
.uk-card-secondary.uk-card-body .uk-tab>.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-tab>.uk-active>a,
.uk-overlay-primary .uk-tab>.uk-active>a,
.uk-offcanvas-bar .uk-tab>.uk-active>a {
  color: #fff;
  border-color: #fff
}

.uk-light .uk-tab>.uk-disabled>a,
.uk-section-primary:not(.uk-preserve-color) .uk-tab>.uk-disabled>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-tab>.uk-disabled>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-tab>.uk-disabled>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-tab>.uk-disabled>a,
.uk-card-primary.uk-card-body .uk-tab>.uk-disabled>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-tab>.uk-disabled>a,
.uk-card-secondary.uk-card-body .uk-tab>.uk-disabled>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-tab>.uk-disabled>a,
.uk-overlay-primary .uk-tab>.uk-disabled>a,
.uk-offcanvas-bar .uk-tab>.uk-disabled>a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-slidenav,
.uk-section-primary:not(.uk-preserve-color) .uk-slidenav,
.uk-section-secondary:not(.uk-preserve-color) .uk-slidenav,
.uk-tile-primary:not(.uk-preserve-color) .uk-slidenav,
.uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav,
.uk-card-primary.uk-card-body .uk-slidenav,
.uk-card-primary>:not([class*='uk-card-media']) .uk-slidenav,
.uk-card-secondary.uk-card-body .uk-slidenav,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-slidenav,
.uk-overlay-primary .uk-slidenav,
.uk-offcanvas-bar .uk-slidenav {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-slidenav:hover,
.uk-light .uk-slidenav:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-slidenav:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-slidenav:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:focus,
.uk-card-primary.uk-card-body .uk-slidenav:hover,
.uk-card-primary.uk-card-body .uk-slidenav:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-slidenav:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-slidenav:focus,
.uk-card-secondary.uk-card-body .uk-slidenav:hover,
.uk-card-secondary.uk-card-body .uk-slidenav:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-slidenav:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-slidenav:focus,
.uk-overlay-primary .uk-slidenav:hover,
.uk-overlay-primary .uk-slidenav:focus,
.uk-offcanvas-bar .uk-slidenav:hover,
.uk-offcanvas-bar .uk-slidenav:focus {
  color: rgba(255, 255, 255, 0.95)
}

.uk-light .uk-slidenav:active,
.uk-section-primary:not(.uk-preserve-color) .uk-slidenav:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:active,
.uk-card-primary.uk-card-body .uk-slidenav:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-slidenav:active,
.uk-card-secondary.uk-card-body .uk-slidenav:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-slidenav:active,
.uk-overlay-primary .uk-slidenav:active,
.uk-offcanvas-bar .uk-slidenav:active {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-dotnav>*>*,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>*>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>*>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>*>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>*>*,
.uk-card-primary.uk-card-body .uk-dotnav>*>*,
.uk-card-primary>:not([class*='uk-card-media']) .uk-dotnav>*>*,
.uk-card-secondary.uk-card-body .uk-dotnav>*>*,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-dotnav>*>*,
.uk-overlay-primary .uk-dotnav>*>*,
.uk-offcanvas-bar .uk-dotnav>*>* {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.9)
}

.uk-light .uk-dotnav>*>:hover,
.uk-light .uk-dotnav>*>:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>*>:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>*>:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>*>:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>*>:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>*>:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>*>:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>*>:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>*>:focus,
.uk-card-primary.uk-card-body .uk-dotnav>*>:hover,
.uk-card-primary.uk-card-body .uk-dotnav>*>:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-dotnav>*>:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-dotnav>*>:focus,
.uk-card-secondary.uk-card-body .uk-dotnav>*>:hover,
.uk-card-secondary.uk-card-body .uk-dotnav>*>:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-dotnav>*>:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-dotnav>*>:focus,
.uk-overlay-primary .uk-dotnav>*>:hover,
.uk-overlay-primary .uk-dotnav>*>:focus,
.uk-offcanvas-bar .uk-dotnav>*>:hover,
.uk-offcanvas-bar .uk-dotnav>*>:focus {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent
}

.uk-light .uk-dotnav>*>:active,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>*>:active,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>*>:active,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>*>:active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>*>:active,
.uk-card-primary.uk-card-body .uk-dotnav>*>:active,
.uk-card-primary>:not([class*='uk-card-media']) .uk-dotnav>*>:active,
.uk-card-secondary.uk-card-body .uk-dotnav>*>:active,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-dotnav>*>:active,
.uk-overlay-primary .uk-dotnav>*>:active,
.uk-offcanvas-bar .uk-dotnav>*>:active {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: transparent
}

.uk-light .uk-dotnav>.uk-active>*,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav>.uk-active>*,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav>.uk-active>*,
.uk-tile-primary:not(.uk-preserve-color) .uk-dotnav>.uk-active>*,
.uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav>.uk-active>*,
.uk-card-primary.uk-card-body .uk-dotnav>.uk-active>*,
.uk-card-primary>:not([class*='uk-card-media']) .uk-dotnav>.uk-active>*,
.uk-card-secondary.uk-card-body .uk-dotnav>.uk-active>*,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-dotnav>.uk-active>*,
.uk-overlay-primary .uk-dotnav>.uk-active>*,
.uk-offcanvas-bar .uk-dotnav>.uk-active>* {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent
}

.uk-light .uk-iconnav>*>a,
.uk-section-primary:not(.uk-preserve-color) .uk-iconnav>*>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-iconnav>*>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-iconnav>*>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav>*>a,
.uk-card-primary.uk-card-body .uk-iconnav>*>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-iconnav>*>a,
.uk-card-secondary.uk-card-body .uk-iconnav>*>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-iconnav>*>a,
.uk-overlay-primary .uk-iconnav>*>a,
.uk-offcanvas-bar .uk-iconnav>*>a {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-iconnav>*>a:hover,
.uk-light .uk-iconnav>*>a:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-iconnav>*>a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-iconnav>*>a:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-iconnav>*>a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-iconnav>*>a:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-iconnav>*>a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-iconnav>*>a:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav>*>a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav>*>a:focus,
.uk-card-primary.uk-card-body .uk-iconnav>*>a:hover,
.uk-card-primary.uk-card-body .uk-iconnav>*>a:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-iconnav>*>a:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-iconnav>*>a:focus,
.uk-card-secondary.uk-card-body .uk-iconnav>*>a:hover,
.uk-card-secondary.uk-card-body .uk-iconnav>*>a:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-iconnav>*>a:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-iconnav>*>a:focus,
.uk-overlay-primary .uk-iconnav>*>a:hover,
.uk-overlay-primary .uk-iconnav>*>a:focus,
.uk-offcanvas-bar .uk-iconnav>*>a:hover,
.uk-offcanvas-bar .uk-iconnav>*>a:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-iconnav>.uk-active>a,
.uk-section-primary:not(.uk-preserve-color) .uk-iconnav>.uk-active>a,
.uk-section-secondary:not(.uk-preserve-color) .uk-iconnav>.uk-active>a,
.uk-tile-primary:not(.uk-preserve-color) .uk-iconnav>.uk-active>a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav>.uk-active>a,
.uk-card-primary.uk-card-body .uk-iconnav>.uk-active>a,
.uk-card-primary>:not([class*='uk-card-media']) .uk-iconnav>.uk-active>a,
.uk-card-secondary.uk-card-body .uk-iconnav>.uk-active>a,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-iconnav>.uk-active>a,
.uk-overlay-primary .uk-iconnav>.uk-active>a,
.uk-offcanvas-bar .uk-iconnav>.uk-active>a {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-text-lead,
.uk-section-primary:not(.uk-preserve-color) .uk-text-lead,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-lead,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-lead,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-lead,
.uk-card-primary.uk-card-body .uk-text-lead,
.uk-card-primary>:not([class*='uk-card-media']) .uk-text-lead,
.uk-card-secondary.uk-card-body .uk-text-lead,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-text-lead,
.uk-overlay-primary .uk-text-lead,
.uk-offcanvas-bar .uk-text-lead {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-text-meta,
.uk-section-primary:not(.uk-preserve-color) .uk-text-meta,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-meta,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-meta,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-meta,
.uk-card-primary.uk-card-body .uk-text-meta,
.uk-card-primary>:not([class*='uk-card-media']) .uk-text-meta,
.uk-card-secondary.uk-card-body .uk-text-meta,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-text-meta,
.uk-overlay-primary .uk-text-meta,
.uk-offcanvas-bar .uk-text-meta {
  color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-text-muted,
.uk-section-primary:not(.uk-preserve-color) .uk-text-muted,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-muted,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-muted,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-muted,
.uk-card-primary.uk-card-body .uk-text-muted,
.uk-card-primary>:not([class*='uk-card-media']) .uk-text-muted,
.uk-card-secondary.uk-card-body .uk-text-muted,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-text-muted,
.uk-overlay-primary .uk-text-muted,
.uk-offcanvas-bar .uk-text-muted {
  color: rgba(255, 255, 255, 0.5) !important
}

.uk-light .uk-text-emphasis,
.uk-section-primary:not(.uk-preserve-color) .uk-text-emphasis,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-emphasis,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-emphasis,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-emphasis,
.uk-card-primary.uk-card-body .uk-text-emphasis,
.uk-card-primary>:not([class*='uk-card-media']) .uk-text-emphasis,
.uk-card-secondary.uk-card-body .uk-text-emphasis,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-text-emphasis,
.uk-overlay-primary .uk-text-emphasis,
.uk-offcanvas-bar .uk-text-emphasis {
  color: #fff !important
}

.uk-light .uk-text-primary,
.uk-section-primary:not(.uk-preserve-color) .uk-text-primary,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-primary,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-primary,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-primary,
.uk-card-primary.uk-card-body .uk-text-primary,
.uk-card-primary>:not([class*='uk-card-media']) .uk-text-primary,
.uk-card-secondary.uk-card-body .uk-text-primary,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-text-primary,
.uk-overlay-primary .uk-text-primary,
.uk-offcanvas-bar .uk-text-primary {
  color: #fff !important
}

.uk-light .uk-text-secondary,
.uk-section-primary:not(.uk-preserve-color) .uk-text-secondary,
.uk-section-secondary:not(.uk-preserve-color) .uk-text-secondary,
.uk-tile-primary:not(.uk-preserve-color) .uk-text-secondary,
.uk-tile-secondary:not(.uk-preserve-color) .uk-text-secondary,
.uk-card-primary.uk-card-body .uk-text-secondary,
.uk-card-primary>:not([class*='uk-card-media']) .uk-text-secondary,
.uk-card-secondary.uk-card-body .uk-text-secondary,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-text-secondary,
.uk-overlay-primary .uk-text-secondary,
.uk-offcanvas-bar .uk-text-secondary {
  color: #fff !important
}

.uk-light .uk-column-divider,
.uk-section-primary:not(.uk-preserve-color) .uk-column-divider,
.uk-section-secondary:not(.uk-preserve-color) .uk-column-divider,
.uk-tile-primary:not(.uk-preserve-color) .uk-column-divider,
.uk-tile-secondary:not(.uk-preserve-color) .uk-column-divider,
.uk-card-primary.uk-card-body .uk-column-divider,
.uk-card-primary>:not([class*='uk-card-media']) .uk-column-divider,
.uk-card-secondary.uk-card-body .uk-column-divider,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-column-divider,
.uk-overlay-primary .uk-column-divider,
.uk-offcanvas-bar .uk-column-divider {
  column-rule-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-logo,
.uk-section-primary:not(.uk-preserve-color) .uk-logo,
.uk-section-secondary:not(.uk-preserve-color) .uk-logo,
.uk-tile-primary:not(.uk-preserve-color) .uk-logo,
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo,
.uk-card-primary.uk-card-body .uk-logo,
.uk-card-primary>:not([class*='uk-card-media']) .uk-logo,
.uk-card-secondary.uk-card-body .uk-logo,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-logo,
.uk-overlay-primary .uk-logo,
.uk-offcanvas-bar .uk-logo {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-logo:hover,
.uk-light .uk-logo:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-logo:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-logo:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-logo:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-logo:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-logo:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-logo:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo:focus,
.uk-card-primary.uk-card-body .uk-logo:hover,
.uk-card-primary.uk-card-body .uk-logo:focus,
.uk-card-primary>:not([class*='uk-card-media']) .uk-logo:hover,
.uk-card-primary>:not([class*='uk-card-media']) .uk-logo:focus,
.uk-card-secondary.uk-card-body .uk-logo:hover,
.uk-card-secondary.uk-card-body .uk-logo:focus,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-logo:hover,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-logo:focus,
.uk-overlay-primary .uk-logo:hover,
.uk-overlay-primary .uk-logo:focus,
.uk-offcanvas-bar .uk-logo:hover,
.uk-offcanvas-bar .uk-logo:focus {
  color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-section-primary:not(.uk-preserve-color) .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-section-secondary:not(.uk-preserve-color) .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-tile-primary:not(.uk-preserve-color) .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-card-primary.uk-card-body .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-card-primary>:not([class*='uk-card-media']) .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-card-secondary.uk-card-body .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-card-secondary>:not([class*='uk-card-media']) .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-overlay-primary .uk-logo>:not(.uk-logo-inverse):not(:only-of-type),
.uk-offcanvas-bar .uk-logo>:not(.uk-logo-inverse):not(:only-of-type) {
  display: none
}

.uk-light .uk-logo-inverse,
.uk-section-primary:not(.uk-preserve-color) .uk-logo-inverse,
.uk-section-secondary:not(.uk-preserve-color) .uk-logo-inverse,
.uk-tile-primary:not(.uk-preserve-color) .uk-logo-inverse,
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo-inverse,
.uk-card-primary.uk-card-body .uk-logo-inverse,
.uk-card-primary>:not([class*='uk-card-media']) .uk-logo-inverse,
.uk-card-secondary.uk-card-body .uk-logo-inverse,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-logo-inverse,
.uk-overlay-primary .uk-logo-inverse,
.uk-offcanvas-bar .uk-logo-inverse {
  display: inline
}

.uk-light .uk-accordion-title::before,
.uk-section-primary:not(.uk-preserve-color) .uk-accordion-title::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title::before,
.uk-card-primary.uk-card-body .uk-accordion-title::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-accordion-title::before,
.uk-card-secondary.uk-card-body .uk-accordion-title::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-accordion-title::before,
.uk-overlay-primary .uk-accordion-title::before,
.uk-offcanvas-bar .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-open>.uk-accordion-title::before,
.uk-section-primary:not(.uk-preserve-color) .uk-open>.uk-accordion-title::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-open>.uk-accordion-title::before,
.uk-tile-primary:not(.uk-preserve-color) .uk-open>.uk-accordion-title::before,
.uk-tile-secondary:not(.uk-preserve-color) .uk-open>.uk-accordion-title::before,
.uk-card-primary.uk-card-body .uk-open>.uk-accordion-title::before,
.uk-card-primary>:not([class*='uk-card-media']) .uk-open>.uk-accordion-title::before,
.uk-card-secondary.uk-card-body .uk-open>.uk-accordion-title::before,
.uk-card-secondary>:not([class*='uk-card-media']) .uk-open>.uk-accordion-title::before,
.uk-overlay-primary .uk-open>.uk-accordion-title::before,
.uk-offcanvas-bar .uk-open>.uk-accordion-title::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba%28255,%20255,%20255,%200.7%29%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

@media print {

  *,
  *::before,
  *::after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important
  }

  a,
  a:visited {
    text-decoration: underline
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid
  }

  thead {
    display: table-header-group
  }

  tr,
  img {
    page-break-inside: avoid
  }

  img {
    max-width: 100% !important
  }

  @page {
    margin: 0.5cm
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3
  }

  h2,
  h3 {
    page-break-after: avoid
  }
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1
}

.swiper-container-no-flexbox .swiper-slide {
  float: left
}

.swiper-container-vertical>.swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.swiper-container-multirow>.swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.swiper-container-free-mode>.swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform
}

.swiper-invisible-blank-slide {
  visibility: hidden
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent)
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent)
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent)
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent)
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal>.swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical>.swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-lock {
  display: none
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s opacity;
  -o-transition: .3s opacity;
  transition: .3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33)
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .2
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff
}

.swiper-container-vertical>.swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0)
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: .2s top, .2s -webkit-transform;
  transition: .2s top, .2s -webkit-transform;
  -o-transition: .2s transform, .2s top;
  transition: .2s transform, .2s top;
  transition: .2s transform, .2s top, .2s -webkit-transform
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: .2s left, .2s -webkit-transform;
  transition: .2s left, .2s -webkit-transform;
  -o-transition: .2s transform, .2s left;
  transition: .2s transform, .2s left;
  transition: .2s transform, .2s left, .2s -webkit-transform
}

.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: .2s right, .2s -webkit-transform;
  transition: .2s right, .2s -webkit-transform;
  -o-transition: .2s transform, .2s right;
  transition: .2s transform, .2s right;
  transition: .2s transform, .2s right, .2s -webkit-transform
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top
}

.swiper-container-horizontal>.swiper-pagination-progressbar,
.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0
}

.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical>.swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25)
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #fff
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25)
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000
}

.swiper-pagination-lock {
  display: none
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1)
}

.swiper-container-horizontal>.swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%
}

.swiper-container-vertical>.swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0
}

.swiper-scrollbar-cursor-drag {
  cursor: move
}

.swiper-scrollbar-lock {
  display: none
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center
}

.swiper-zoom-container>canvas,
.swiper-zoom-container>img,
.swiper-zoom-container>svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.swiper-slide-zoomed {
  cursor: move
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite
}

.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-cube {
  overflow: visible
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next+.swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0
}

.swiper-container-flip {
  overflow: visible
}

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px
}

html,
body {
  width: 100%;
  height: 100%
}

@media only screen and (max-width: 767px) {

  html,
  body {
    height: auto;
    overflow-x: hidden
  }
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #2b2b2b;
  line-height: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  background: #f4f0f0
}

@media only screen and (max-width: 1024px) {
  body {
    font-size: 12px
  }
}

* {
  list-style: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box
}

img {
  max-width: 100%;
  border: none
}

*:hover,
*:focus,
*:active {
  outline: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal
}

a {
  color: #1e5591;
  text-decoration: none
}

a:hover {
  color: black;
  text-decoration: underline
}

.Wrapper {
  width: 94%;
  max-width: 1500px;
  margin: 0 auto
}

.default-svg path {
  fill: #555
}

.default-svg.sm-svg {
  width: 16px;
  height: 16px
}

#backToTop {
  width: 44px;
  height: 44px;
  background-color: #1e5591;
  border: 1px solid #1e5591;
  padding: 10px;
  border-radius: 8px;
  position: fixed;
  right: 15px;
  bottom: -60px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
  visibility: hidden;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000
}

#backToTop svg {
  color: #1e5591 !important;
}

@media only screen and (max-width: 767px) {
  #backToTop {
    width: 38px;
    height: 38px;
    padding: 8px
  }
}

#backToTop.show {
  bottom: 15px;
  opacity: 1;
  visibility: visible
}

#backToTop svg path {
  fill: #fff
}

#backToTop:hover {
  background-color: #fff;
  transform: translateY(-7px)
}

#backToTop:hover svg path {
  fill: #d40b2e
}

.button,
footer .FooterParent .connectWithUs .FooterSocials a,
section.BannerMain .BannerbottomSection .signUpContent,
section.AwordWiningSection .addServices,
section.DigitalDostSection .DigitalDostRow,
section.OurPartnersSection .partnersList,
section.signUpSection .signUPInner .signUpForm .row,
section.BannerMain .BannerInner,
section .ServicesDetails .List .ListInner,
section.BannerMain .contactusBanner .PageHeading .socialMedia a,
section.ContactForm .contactDetails .leftDetails .details ul li span,
section.ContactForm .contactDetails .RightForm .FormBox .heading span,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .row,
section.BannerMain .contactusBanner .PageHeading .heading .socialMedia a,
section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text ul,
section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title,
section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide .cirl,
section.ourJourny .OurJournySlider .SliderArrow,
section.ourJourny .OurJournySlider .SliderArrow .ArrowSlide,
.PopupMain .PopupCenter .PopupBox .CloseIcon,
.dtr-details .dtr-data,
footer .FooterParent .connectWithUs .FooterSocials,
section.AwordWiningSection .addServices .leftService ul li,
section.AwordWiningSection .addServices .rightService ul li,
section.BannerMain .contactusBanner .PageHeading .socialMedia,
section.ContactForm .contactDetails .RightForm .FormBox .heading,
section.BannerMain .contactusBanner .PageHeading .heading .socialMedia,
header.HeaderMain .MinMenu,
.IconButton,
.dropdown-menu ul li a,
header.HeaderMain .MinMenu nav.NavbarMain ul,
section.BannerMain .owl-carousel .owl-item .item .slideInner,
section.AdvSection .AdvInner,
section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper,
table.dataTable>tbody>tr.child ul.dtr-details>li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row
}

body,
#backToTop,
.CustomForm [type="radio"]:checked+label,
.CustomForm [type="radio"]:not(:checked)+label,
header.HeaderMain .MinMenu nav.NavbarMain ul li a,
section.AdvSection .AdvInner .item .itemInner,
section.AdvSection .AdvInner .item .itemInner .icon,
section .TopContent,
section.AboutContent .innerContent,
section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner,
section.OurScoreCard .ScoreCardInner .item .itemInner,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group span,
.LayoutMain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.button,
footer .FooterParent .connectWithUs .FooterSocials a,
section.BannerMain .BannerbottomSection .signUpContent,
section.AwordWiningSection .addServices,
section.DigitalDostSection .DigitalDostRow,
section.OurPartnersSection .partnersList,
section.signUpSection .signUPInner .signUpForm .row,
section.BannerMain .BannerInner,
section .ServicesDetails .List .ListInner,
section.BannerMain .contactusBanner .PageHeading .socialMedia a,
section.ContactForm .contactDetails .leftDetails .details ul li span,
section.ContactForm .contactDetails .RightForm .FormBox .heading span,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .row,
section.BannerMain .contactusBanner .PageHeading .heading .socialMedia a,
section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text ul,
section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title,
section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide .cirl,
section.ourJourny .OurJournySlider .SliderArrow,
section.ourJourny .OurJournySlider .SliderArrow .ArrowSlide,
.PopupMain .PopupCenter .PopupBox .CloseIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center
}

.dtr-details .dtr-data,
footer .FooterParent .connectWithUs .FooterSocials,
section.AwordWiningSection .addServices .leftService ul li,
section.AwordWiningSection .addServices .rightService ul li,
section.BannerMain .contactusBanner .PageHeading .socialMedia,
section.ContactForm .contactDetails .RightForm .FormBox .heading,
section.BannerMain .contactusBanner .PageHeading .heading .socialMedia {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start
}

header.HeaderMain .MinMenu {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end
}

.IconButton,
.dropdown-menu ul li a,
header.HeaderMain .MinMenu nav.NavbarMain ul,
section.BannerMain .owl-carousel .owl-item .item .slideInner,
section.AdvSection .AdvInner,
section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between
}

#backToTop,
.CustomForm [type="radio"]:checked+label,
.CustomForm [type="radio"]:not(:checked)+label,
header.HeaderMain .MinMenu nav.NavbarMain ul li a,
section.AdvSection .AdvInner .item .itemInner,
section.AdvSection .AdvInner .item .itemInner .icon,
section .TopContent,
section.AboutContent .innerContent,
section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner,
section.OurScoreCard .ScoreCardInner .item .itemInner,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group span {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center
}

@font-face {
  font-family: 'Cambria-Regular';
  src: url("https://www.masterpay.pro/../fonts/Cambria.woff2") format("woff2"), url("https://www.masterpay.pro/../fonts/Cambria.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Cambria-Italic';
  src: url("https://www.masterpay.pro/../fonts/Cambria-Italic.woff2") format("woff2"), url("https://www.masterpay.pro/../fonts/Cambria-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'Cambria-Bold';
  src: url("https://www.masterpay.pro/../fonts/Cambria-Bold.woff2") format("woff2"), url("https://www.masterpay.pro/../fonts/Cambria-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Cambria-BoldItalic';
  src: url("https://www.masterpay.pro/../fonts/Cambria-BoldItalic.woff2") format("woff2"), url("https://www.masterpay.pro/../fonts/Cambria-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap
}

.LayoutMain {
  width: 100%;
  background-color: #f4f0f0;
  flex: 1 0 auto
}

.ContainerMain {
  width: 100%;
  padding: 0
}

.SecTitle {
  text-align: center;
  position: relative;
  padding-bottom: 15px;
  max-width: 600px;
  margin: 0 auto 23px auto
}

@media only screen and (max-width: 1024px) {
  .SecTitle {
    margin-bottom: 35px;
    padding-bottom: 20px
  }
}

.SecTitle::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 70px;
  height: 2px;
  background: #1e5591;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%)
}

@media only screen and (max-width: 1024px) {
  .SecTitle::after {
    width: 50px;
    height: 2px
  }
}

.SecTitle h2 {
  font-size: 40px;
  color: #2b2b2b;
  font-weight: 300
}

@media only screen and (max-width: 1024px) {
  .SecTitle h2 {
    font-size: 30px
  }
}

.SecTitle h2 span {
  font-weight: 500
}

.SecTitle h4 {
  font-size: 17px;
  color: #2b2b2b;
  font-weight: 400
}

@media only screen and (max-width: 1024px) {
  .SecTitle h4 {
    font-size: 14px
  }
}

.SecTitle h4 strong {
  font-weight: 500
}

h3 {
  font-size: 26px;
  color: #2b2b2b;
  font-weight: 400;
  margin-bottom: 20px
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 22px
  }
}

@media only screen and (max-width: 600px) {
  h3 {
    font-size: 18px
  }
}

p {
  font-size: 14px;
  color: #2b2b2b;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 20px
}

@media only screen and (max-width: 767px) {
  p {
    font-size: 13px;
    line-height: 22px
  }
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 12px;
    line-height: 20px
  }
}

button,
a,
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none
}

.button {
  display: inline-flex;
  padding: 20px 40px;
  -webkit-border-radius: 300px;
  -moz-border-radius: 300px;
  -ms-border-radius: 300px;
  -o-border-radius: 300px;
  border-radius: 300px;
  font-size: 20px;
  color: #2b2b2b;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all ease-out .2s;
  -moz-transition: all ease-out .2s;
  -ms-transition: all ease-out .2s;
  -o-transition: all ease-out .2s;
  transition: all ease-out .2s;
  text-decoration: none
}

.button>span+i {
  margin-left: 10px
}

.button i {
  width: 18px;
  height: 18px;
  position: relative
}

.button i+span {
  margin-left: 10px
}

.button:hover {
  -webkit-transition: all ease-out .2s;
  -moz-transition: all ease-out .2s;
  -ms-transition: all ease-out .2s;
  -o-transition: all ease-out .2s;
  transition: all ease-out .2s;
  text-decoration: none
}

.button.Primary {
  background: #000;
  color: #fff;
  border: 1px solid #000;
  box-shadow: none;
  outline: none
}

.button.Primary:hover {
  background: #fff;
  border: 1px solid #d40b2e;
  color: #1e5591
}

.button.Primary.Bordered {
  background: none;
  border: 1px solid #d40b2e;
  color: #d40b2e
}

.button.Primary.Bordered:hover {
  background: #d40b2e;
  color: #fff
}

.IconButton i {
  margin-right: 10px
}

@media only screen and (max-width: 1024px) {
  .IconButton i {
    margin-right: 5px
  }
}

.IconButton>span {
  flex: 2
}

input,
select,
textarea,
button {
  font-family: "Montserrat", sans-serif;
  -webkit-appearance: none
}

.form-group {
  margin-top: 15px
}

.fl-form .fl-wrap {
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.fl-form input.fl-input,
.fl-form select.fl-select,
.fl-form textarea.fl-textarea {
  width: 100%;
  height: 38px;
  font-size: 16px;
  color: #2b2b2b;
  font-weight: 400;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border: 1px solid #a5a5a5;
  outline: 0;
  box-sizing: border-box;
  padding: 6px 12px
}

@media only screen and (max-width: 600px) {

  .fl-form input.fl-input,
  .fl-form select.fl-select,
  .fl-form textarea.fl-textarea {
    height: 42px;
    font-size: 14px
  }
}

.fl-form select.fl-select {
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none
}

.fl-form select.fl-select::-ms-expand {
  display: none
}

.fl-form .fl-is-active input.fl-input,
.fl-form .fl-is-active select.fl-select,
.fl-form .fl-is-active textarea.fl-textarea {
  border-color: #a5a5a5;
  padding: 6px 12px
}

.fl-form .fl-is-active label.fl-label {
  opacity: 1;
  visibility: visible;
  background-color: transparent;
  left: 10px;
  top: -9px;
  padding: 3px
}

.fl-form .fl-is-active label.fl-label::before {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  right: 0;
  left: 0;
  height: 4px;
  background-color: #fff;
  z-index: -1
}

.fl-form .fl-has-focus input.fl-input,
.fl-form .fl-has-focus select.fl-select,
.fl-form .fl-has-focus textarea.fl-textarea {
  border-color: #d40b2e
}

.fl-form .fl-has-focus input.fl-input:focus,
.fl-form .fl-has-focus select.fl-select:focus,
.fl-form .fl-has-focus textarea.fl-textarea:focus {
  box-shadow: 0 0 0 1px #d40b2e
}

.fl-form .fl-has-focus label.fl-label {
  color: #d40b2e
}

.fl-form label.fl-label {
  opacity: 0;
  visibility: hidden;
  display: block;
  position: absolute;
  top: -12px;
  left: 0;
  font-size: 12px;
  color: #2b2b2b;
  line-height: 1;
  font-weight: 400;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-top-left-radius: 3px;
  z-index: 1
}

.fl-form .fl-is-required::before {
  opacity: 1;
  content: "*";
  display: block;
  position: absolute;
  top: 1px;
  right: 15px;
  font-size: 16px;
  color: #d32f2f;
  line-height: 1.75;
  font-weight: 400;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 6px 0 0;
  z-index: 1
}

.fl-form .fl-is-required .fl-is-active::before {
  opacity: 0
}

.CustomForm [type="radio"]:checked,
.CustomForm [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px
}

.CustomForm [type="radio"]:checked+label,
.CustomForm [type="radio"]:not(:checked)+label {
  position: relative;
  cursor: pointer;
  width: 128px;
  height: 110px;
  text-align: center;
  background: #fff;
  border: 1px solid #a5a5a5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  color: #2b2b2b;
  font-weight: 400;
  padding: 7px
}

@media only screen and (max-width: 767px) {

  .CustomForm [type="radio"]:checked+label,
  .CustomForm [type="radio"]:not(:checked)+label {
    width: 100%;
    height: 90px
  }
}

.CustomForm [type="radio"]:checked+label svg,
.CustomForm [type="radio"]:not(:checked)+label svg {
  width: 50px;
  height: 50px;
  margin-bottom: 10px
}

@media only screen and (max-width: 767px) {

  .CustomForm [type="radio"]:checked+label svg,
  .CustomForm [type="radio"]:not(:checked)+label svg {
    margin-bottom: 5px
  }
}

.CustomForm [type="radio"]:checked+label svg path,
.CustomForm [type="radio"]:not(:checked)+label svg path {
  fill: #3f3f3f
}

.CustomForm [type="radio"]:checked+label {
  border-color: #d40b2e;
  border-width: 2px;
  color: #d40b2e
}

.CustomForm [type="radio"]:checked+label svg path {
  fill: #d40b2e
}

.GridUi {
  font-size: 12px;
  color: #2b2b2b;
  font-weight: 400
}

@media only screen and (max-width: 600px) {
  .GridUi {
    font-size: 11px
  }
}

.GridUi .DataTableGrid {
  border: 1px solid #ebebeb
}

.GridUi .DataTableGrid thead tr {
  -webkit-background-image: linear-gradient(0deg, #f7f7f7 0%, #fff 100%);
  -moz-background-image: linear-gradient(0deg, #f7f7f7 0%, #fff 100%);
  -ms-background-image: linear-gradient(0deg, #f7f7f7 0%, #fff 100%);
  -o-background-image: linear-gradient(0deg, #f7f7f7 0%, #fff 100%);
  background-image: linear-gradient(0deg, #f7f7f7 0%, #fff 100%);
  -webkit-box-shadow: 0px 1px 0px 0px rgba(234, 234, 234, 0.004);
  -moz-box-shadow: 0px 1px 0px 0px rgba(234, 234, 234, 0.004);
  -ms-box-shadow: 0px 1px 0px 0px rgba(234, 234, 234, 0.004);
  -o-box-shadow: 0px 1px 0px 0px rgba(234, 234, 234, 0.004);
  box-shadow: 0px 1px 0px 0px rgba(234, 234, 234, 0.004)
}

.GridUi .DataTableGrid thead tr th {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  border-bottom-color: #ebebeb
}

.GridUi .DataTableGrid tbody tr.odd {
  background: #ffffff !important
}

.GridUi .DataTableGrid tbody tr.odd .sorting_1 {
  background: #ffffff !important
}

.GridUi .DataTableGrid tbody tr.even {
  background: #fbf8f8 !important
}

.GridUi .DataTableGrid tbody tr.even .sorting_1 {
  background: #fbf8f8 !important
}

.GridUi .DataTableGrid tbody tr td {
  border: none;
  padding: 10px
}

.GridUi .DataTableGrid.no-footer {
  border-bottom-color: #ebebeb
}

.dtr-details {
  width: 100%
}

.dtr-details .dtr-data {
  display: inline-flex
}

table.dataTable>tbody>tr.child span.dtr-title {
  width: 120px
}

.dtr-details .dtr-data {
  flex: 2
}

.dropdown-menu {
  background: #fff;
  box-shadow: 0px 5px 7px 0px rgba(87, 89, 102, 0.1), inset 0px 1px 0px 0px rgba(209, 102, 102, 0.004);
  border: none;
  border-radius: 0 0 15px 15px;
  margin: 0;
  padding: 0;
  border-top: 1px solid #d40b2e;
  padding-bottom: 10px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center
}

.dropdown-menu ul {
  margin: 0;
  padding: 0px;
}

.dropdown-menu ul:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent #1e5591 transparent;
  position: absolute;
  top: -8px;
  left: 10px
}

.dropdown-menu ul li {
  font-size: 13px;
  color: #5e5e5e;
  font-weight: 400;
  padding: 10px 15px
}

.dropdown-menu ul li a {
  font-size: 13px;
  color: #5e5e5e;
  font-weight: 400
}

.dropdown-menu ul li a:hover {
  text-decoration: none;
  color: #1e5591;
  background: rgba(0, 0, 0, 0.02)
}

.dropdown-menu ul li a:hover i svg path {
  fill: #d40b2e
}

.dropdown-menu.dropdown-menu-right ul::before {
  left: auto;
  right: 10px
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0
  }
}

@keyframes rippleX {

  0%,
  35% {
    transform: translateX(-50%) translateY(0) translateZ(0) scale(0);
    opacity: 1
  }

  50% {
    transform: translateX(-50%) translateY(0) translateZ(0) scale(1.5);
    opacity: 0.8
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(0) translateZ(0) scale(3)
  }
}

@keyframes rippleY {

  0%,
  35% {
    transform: translateX(0) translateY(-50%) translateZ(0) scale(0);
    opacity: 1
  }

  50% {
    transform: translateX(0) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0.8
  }

  100% {
    opacity: 0;
    transform: translateX(0) translateY(-50%) translateZ(0) scale(3)
  }
}

header {
  width: 100%
}

header.HeaderMain {
  background-color: #fff;
  box-shadow: 0.105px 0.995px 2px 0px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  background: #fff;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px)
}

@media only screen and (max-width: 767px) {
  header.HeaderMain {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center
  }
}

header.HeaderMain .Wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: nowrap;
  -moz-flex-flow: nowrap;
  -ms-flex-flow: nowrap;
  flex-flow: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between
}

header.HeaderMain .Logo {
  padding: 7px 0
}

header.HeaderMain .Logo svg {
  width: auto;
  height: 60px
}

@media only screen and (max-width: 600px) {
  header.HeaderMain .Logo svg {
    height: 50px
  }
}

header.HeaderMain .menuicon {
  display: none
}

@media only screen and (max-width: 767px) {
  header.HeaderMain .menuicon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center
  }
}

header.HeaderMain .menuicon .menuicon-label {
  margin: 0;
  display: block;
  opacity: 0.8;
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  position: relative
}

header.HeaderMain .menuicon .menuicon-label .menuicon-bread-top {
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 3;
  width: 30px;
  height: 30px;
  width: 30px;
  height: 30px;
  transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96)
}

header.HeaderMain .menuicon .menuicon-label .menuicon-bread-top .menuicon-top {
  position: absolute;
  top: 5px;
  left: 0px;
  width: 30px;
  height: 2px;
  display: block;
  background: #2b2b2b;
  border-radius: 0.5px;
  transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1008s
}

header.HeaderMain .menuicon .menuicon-label .menuicon-bread-middle {
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 3;
  width: 30px;
  height: 30px;
  width: 30px;
  height: 30px;
  transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96)
}

header.HeaderMain .menuicon .menuicon-label .menuicon-bread-middle .menuicon-middle {
  position: absolute;
  top: 14px;
  left: 0px;
  width: 30px;
  height: 2px;
  display: block;
  background: #2b2b2b;
  border-radius: 0.5px;
  transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1008s
}

header.HeaderMain .menuicon .menuicon-label .menuicon-bread-bottom {
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 3;
  width: 30px;
  height: 30px;
  width: 30px;
  height: 30px;
  transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96)
}

header.HeaderMain .menuicon .menuicon-label .menuicon-bread-bottom .menuicon-bottom {
  position: absolute;
  bottom: 5px;
  left: 0px;
  width: 30px;
  height: 2px;
  display: block;
  background: #2b2b2b;
  border-radius: 0.5px;
  transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1008s
}

header.HeaderMain .MinMenu {
  margin-left: -15px;
  margin-right: -15px;
  padding: 0
}

@media only screen and (max-width: 600px) {
  header.HeaderMain .MinMenu {
    margin-right: -5px
  }
}

header.HeaderMain .MinMenu nav.NavbarMain {
  height: 100%
}

@media only screen and (max-width: 767px) {
  header.HeaderMain .MinMenu nav.NavbarMain {
    display: none;
    position: absolute;
    top: 74px;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
    transition: 0.5s linear
  }
}

@media only screen and (max-width: 600px) {
  header.HeaderMain .MinMenu nav.NavbarMain {
    top: 64px
  }
}

header.HeaderMain .MinMenu nav.NavbarMain ul {
  height: 100%;
  margin-bottom: 0;
  padding: 0
}

@media only screen and (max-width: 767px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px
  }
}

header.HeaderMain .MinMenu nav.NavbarMain ul li {
  height: 100%;
  padding: 0px 10px
}

@media only screen and (max-width: 1024px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul li {
    padding: 0px 6px
  }
}

@media only screen and (max-width: 767px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul li {
    width: 100%;
    position: relative;
    padding: 0
  }

  header.HeaderMain .MinMenu nav.NavbarMain ul li:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(43, 43, 43, 0.2);
    position: absolute;
    bottom: 0;
    left: 0
  }

  header.HeaderMain .MinMenu nav.NavbarMain ul li:last-child:before {
    display: none
  }
}

header.HeaderMain .MinMenu nav.NavbarMain ul li a {
  padding: 0 10px;
  height: 100%;
  transition: none;
  font-size: 15px;
  color: rgba(43, 43, 43, 0.8);
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  -webkit-text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
  -moz-text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
  -ms-text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
  -o-text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
  white-space: nowrap
}

@media only screen and (max-width: 1024px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul li a {
    padding: 0px 6px
  }
}

@media only screen and (max-width: 767px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul li a {
    display: block;
    padding: 15px 0px
  }
}

/* header.HeaderMain .MinMenu nav.NavbarMain ul li a::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  height: 2px;
  width: 0%;
  border-bottom: 2px solid #d40b2e;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
} */

@media only screen and (max-width: 1024px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul li a::before {
    top: -12px
  }
}

@media only screen and (max-width: 767px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul li a::before {
    top: auto;
    bottom: 0
  }
}

header.HeaderMain .MinMenu nav.NavbarMain ul li a::after {
  left: 0;
  top: 50%;
  margin: 0 10px;
  transform: translateY(-50%);
  position: absolute;
  content: attr(data-hover);
  text-align: center;
  color: #1e5591;
  ;
  white-space: nowrap;
  max-width: 0%;
  overflow: hidden;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  line-height: auto
}

@media only screen and (max-width: 1024px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul li a::after {
    margin: 0 6px
  }
}

@media only screen and (max-width: 767px) {
  header.HeaderMain .MinMenu nav.NavbarMain ul li a::after {
    margin: 0;
    display: block;
    padding: 15px 0px
  }
}

header.HeaderMain .MinMenu nav.NavbarMain ul li a:hover,
header.HeaderMain .MinMenu nav.NavbarMain ul li a.active {
  text-decoration: none
}

header.HeaderMain .MinMenu nav.NavbarMain ul li a:hover::after,
header.HeaderMain .MinMenu nav.NavbarMain ul li a.active::after {
  max-width: 100%
}

header.HeaderMain .MinMenu nav.NavbarMain ul li a:hover::before,
header.HeaderMain .MinMenu nav.NavbarMain ul li a.active::before {
  width: 100%
}

header.HeaderMain .MinMenu .SignInbtn {
  padding: 0 15px
}

@media only screen and (max-width: 600px) {
  header.HeaderMain .MinMenu .SignInbtn {
    padding: 0px 10px
  }
}

header.HeaderMain .MinMenu .SignInbtn .HeaderSignin {
  background: #1e5591;
  border: 1px solid #1e5591;
  padding: 9px 25px;
  white-space: nowrap;
  text-decoration: none;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  -ms-border-radius: 90px;
  -o-border-radius: 90px;
  border-radius: 90px;
  font-size: 18px;
  color: #fff;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

@media only screen and (max-width: 600px) {
  header.HeaderMain .MinMenu .SignInbtn .HeaderSignin {
    padding: 8px 15px;
    font-size: 14px
  }
}

header.HeaderMain .MinMenu .SignInbtn .HeaderSignin:after {
  display: none
}

header.HeaderMain .MinMenu .SignInbtn .HeaderSignin:hover {
  background: #fff;
  color: #0b0607;
  border: 1px solid #060606 !important;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

.menustate {
  display: none;
  position: fixed
}

@media only screen and (max-width: 767px) {
  .menustate {
    display: flex
  }
}

.menustate:checked~header {
  background: #fff;
  transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1), height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1)
}

.menustate:checked~header .menuicon .menuicon-label .menuicon-bread-top {
  transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.menustate:checked~header .menuicon .menuicon-label .menuicon-bread-top .menuicon-top {
  transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  -webkit-transform: none;
  transform: none;
  top: 13px
}

.menustate:checked~header .menuicon .menuicon-label .menuicon-bread-middle {
  transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  -webkit-transform: none;
  transform: none;
  opacity: 0
}

.menustate:checked~header .menuicon .menuicon-label .menuicon-bread-middle .menuicon-middle {
  transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  transform: none
}

.menustate:checked~header .menuicon .menuicon-label .menuicon-bread-bottom {
  transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.menustate:checked~header .menuicon .menuicon-label .menuicon-bread-bottom .menuicon-bottom {
  transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  -webkit-transform: none;
  transform: none;
  bottom: 13px
}

.menustate:checked~header .MinMenu .NavbarMain {
  padding: 0;
  width: 100%;
  height: auto;
  display: block;
  z-index: 99
}

.menustate:checked~header .MinMenu .NavbarMain ul {
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s
}

.menustate:checked~header .MinMenu .NavbarMain ul li {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: none;
  transform: none
}

footer {
  background: #fff url("https://www.masterpay.pro/../img/building.svg") no-repeat;
  background-position: bottom;
  position: relative;
  z-index: 1
}

@media only screen and (max-width: 767px) {
  footer {
    background-size: 200%;
    padding-top: 30px
  }
}

@media only screen and (max-width: 600px) {
  footer {
    background-size: 300%
  }
}

footer .FooterParent {
  margin: 30px 0px
}

@media only screen and (max-width: 1024px) {
  footer .FooterParent {
    margin: 20px 0px
  }
}

@media only screen and (max-width: 767px) {
  footer .FooterParent {
    flex-direction: column
  }
}

footer .FooterParent .footerLogo {
  max-width: 200px;
  margin-bottom: 30px
}

footer .FooterParent .footerLogo img {
  width: 100%
}

footer .FooterParent .address {
  padding-left: 0
}

footer .FooterParent .address>li {
  margin-bottom: 30px
}

footer .FooterParent .address>li h4 {
  font-size: 16px;
  color: #2b2b2b;
  font-weight: 600
}

footer .FooterParent .address>li h4 a {
  color: #2b2b2b;
  text-decoration: none
}

footer .FooterParent .address>li p {
  font-size: 15px;
  color: #2b2b2b;
  font-weight: 400;
  margin-bottom: 0
}

footer .FooterParent .address>li p a {
  color: #2b2b2b
}

footer .FooterParent .address>li p a:hover {
  color: #d40b2e
}

footer .FooterParent .address>li p span {
  color: #1e5591;
  margin-right: 10px
}

footer .FooterParent .menuLinks {
  padding-top: 36px
}

@media only screen and (max-width: 767px) {
  footer .FooterParent .menuLinks {
    padding-top: 0
  }
}

footer .FooterParent .menuLinks h3 {
  font-family: "Cambria-Bold";
  font-size: 18px;
  color: #1e5591;
  text-transform: uppercase
}

@media only screen and (max-width: 767px) {
  footer .FooterParent .menuLinks h3 {
    margin-bottom: 10px
  }
}

footer .FooterParent .menuLinks ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: wrap;
  -moz-flex-flow: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  padding-left: 0
}

@media only screen and (max-width: 767px) {
  footer .FooterParent .menuLinks ul {
    margin-top: 10px
  }
}

footer .FooterParent .menuLinks ul li {
  width: 100%;
  padding: 10px 0
}

@media only screen and (max-width: 1024px) {
  footer .FooterParent .menuLinks ul li {
    padding: 8px 0
  }
}

footer .FooterParent .menuLinks ul li a {
  font-size: 16px;
  color: #2b2b2b;
  position: relative;
  display: inline-block;
  padding-left: 20px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

@media only screen and (max-width: 1024px) {
  footer .FooterParent .menuLinks ul li a {
    font-size: 14px
  }
}

footer .FooterParent .menuLinks ul li a::before {
  content: "";
  width: 5px;
  height: 9px;
  position: absolute;
  top: 4px;
  left: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='5px' height='9px'%3E%3Cpath fill-rule='evenodd' fill='rgb(43, 43, 43)' d='M4.820,4.943 L1.046,8.813 C0.806,9.059 0.417,9.059 0.177,8.813 C-0.063,8.567 -0.063,8.168 0.177,7.922 L3.516,4.498 L0.177,1.074 C-0.063,0.828 -0.063,0.429 0.177,0.183 C0.417,-0.063 0.806,-0.063 1.046,0.183 L4.820,4.052 C4.940,4.175 5.000,4.336 5.000,4.498 C5.000,4.659 4.940,4.820 4.820,4.943 Z'/%3E%3C/svg%3E") no-repeat center center;
  background-size: cover;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

footer .FooterParent .menuLinks ul li a:hover {
  color: #1e5591;
  padding-left: 25px;
  text-decoration: none;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

footer .FooterParent .menuLinks ul li a:hover::before {
  left: 5px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='5px' height='9px'%3E%3Cpath fill-rule='evenodd' fill='rgb(212, 11, 46)' d='M4.820,4.943 L1.046,8.813 C0.806,9.059 0.417,9.059 0.177,8.813 C-0.063,8.567 -0.063,8.168 0.177,7.922 L3.516,4.498 L0.177,1.074 C-0.063,0.828 -0.063,0.429 0.177,0.183 C0.417,-0.063 0.806,-0.063 1.046,0.183 L4.820,4.052 C4.940,4.175 5.000,4.336 5.000,4.498 C5.000,4.659 4.940,4.820 4.820,4.943 Z'/%3E%3C/svg%3E") no-repeat center center;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

footer .FooterParent .connectWithUs {
  padding-top: 36px
}

@media only screen and (max-width: 767px) {
  footer .FooterParent .connectWithUs {
    padding-top: 0
  }
}

footer .FooterParent .connectWithUs h3 {
  font-family: "Cambria-Bold";
  font-size: 18px;
  color: #1e5591;
  text-transform: uppercase
}

footer .FooterParent .connectWithUs .FooterSocials {
  margin-bottom: 30px
}

footer .FooterParent .connectWithUs .FooterSocials a {
  width: 40px;
  height: 40px;
  background-color: #f6f6f6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

@media only screen and (max-width: 1024px) {
  footer .FooterParent .connectWithUs .FooterSocials a {
    width: 34px;
    height: 34px;
    margin-right: 5px
  }
}

footer .FooterParent .connectWithUs .FooterSocials a svg {
  width: 16px;
  height: 16px
}

@media only screen and (max-width: 1024px) {
  footer .FooterParent .connectWithUs .FooterSocials a svg {
    width: 13px;
    height: 13px
  }
}

footer .FooterParent .connectWithUs .FooterSocials a svg path {
  fill: #fff
}

footer .FooterParent .connectWithUs .FooterSocials a:hover {
  background: #d40b2e !important;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

footer .FooterParent .connectWithUs .Store-ic a {
  max-width: 200px;
  display: inline-block
}

footer .FooterParent .connectWithUs .Store-ic a img {
  margin-top: 5px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  max-width: 100%;
  height: 56px
}

footer .FooterParent .connectWithUs .Store-ic a img:hover {
  margin-top: 0px;
  margin-bottom: 5px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

footer .FooterBottom {
  padding: 25px 20px
}

footer .FooterBottom p {
  font-size: 14px;
  color: white;
  text-align: center;
  margin-bottom: 0
}

footer .FooterBottom p a {
  color: white;
}

@media only screen and (max-width: 1024px) {
  footer .FooterBottom p {
    font-size: 12px
  }
}

html {
  overflow-x: hidden
}

section.BannerMain {
  margin-top: 74px
}

@media only screen and (max-width: 600px) {
  section.BannerMain {
    margin-top: 64px
  }
}

section.BannerMain .owl-carousel {
  position: relative
}

section.BannerMain .owl-carousel .owl-item {
  width: 100%;
  height: 600px;
  background-color: #000
}

@media only screen and (max-width: 600px) {
  section.BannerMain .owl-carousel .owl-item {
    height: 490px
  }
}

section.BannerMain .owl-carousel .owl-item .item {
  height: 100%;
  background-size: cover;
  background-position: 35%
}

@media only screen and (max-width: 600px) {
  section.BannerMain .owl-carousel .owl-item .item {
    background-position: 12% center
  }
}

section.BannerMain .owl-carousel .owl-item .item::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1
}

section.BannerMain .owl-carousel .owl-item .item .slideInner {
  position: relative;
  z-index: 10
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .imageSection {
  text-align: left;
  height: 100%
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection {
  width: 50%;
  height: 600px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between
}

@media only screen and (max-width: 1400px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection {
    width: 65%
  }
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection {
    width: 75%
  }
}

@media only screen and (max-width: 767px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection {
    height: 490px
  }
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .Title {
  margin-top: 140px;
  transform: translateY(55px);
  transition: all 0.5s;
  opacity: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .Title {
    margin-top: 40px
  }
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .Title h2 {
  font-family: "Cambria-BoldItalic";
  font-size: 54px;
  color: #fff;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  text-align: right;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .Title h2 {
    text-align: center;
    font-size: 45px
  }

  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .Title h2 br {
    display: none
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .Title h2 {
    font-size: 28px;
    line-height: 30px
  }
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .Title h2 span {
  color: #f0ff00
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .appDwonload {
  margin: 50px 0;
  text-align: right;
  transform: translateY(55px);
  transition: all 0.5s;
  opacity: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .appDwonload {
    text-align: center;
    margin: 20px 0
  }
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .appDwonload h3 {
  font-family: "Cambria-Regular";
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4)
}

@media only screen and (max-width: 767px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .appDwonload h3 {
    font-size: 17px
  }
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .appDwonload a {
  text-decoration: none
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .appDwonload a img {
  width: 200px
}

@media only screen and (max-width: 767px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .appDwonload a img {
    width: 170px
  }
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .tagline {
  text-align: right;
  margin-bottom: 40px;
  transform: translateY(55px);
  transition: all 0.5s;
  opacity: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .tagline {
    margin-bottom: 35px
  }
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .tagline h3 {
  font-family: "Cookie", cursive;
  font-size: 36px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  margin: 0
}

@media only screen and (max-width: 600px) {
  section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .tagline h3 {
    font-size: 27px
  }
}

section.BannerMain .owl-carousel .owl-item .item .slideInner .RightSection .tagline h3 span {
  color: #f0ff00
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>* {
  opacity: 1;
  transform: none
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(1) {
  transition-delay: 0s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(2) {
  transition-delay: .2s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(3) {
  transition-delay: .4s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(4) {
  transition-delay: .6s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(5) {
  transition-delay: .8s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(6) {
  transition-delay: 1s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(7) {
  transition-delay: 1.2s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(8) {
  transition-delay: 1.4s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(9) {
  transition-delay: 1.6s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(10) {
  transition-delay: 1.8s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(11) {
  transition-delay: 2s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(12) {
  transition-delay: 2.2s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(13) {
  transition-delay: 2.4s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(14) {
  transition-delay: 2.6s
}

section.BannerMain .owl-carousel .owl-item.active .item .slideInner .RightSection>*:nth-child(15) {
  transition-delay: 2.8s
}

section.BannerMain .owl-carousel .owl-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
  width: 100%
}

section.BannerMain .owl-carousel .owl-dots button {
  width: 16px;
  height: 6px;
  background-color: #fff;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  -ms-border-radius: 90px;
  -o-border-radius: 90px;
  border-radius: 90px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  border: 0;
  margin: 0 5px;
  cursor: pointer;
  outline: none;
  transition: all ease-out .5s
}

section.BannerMain .owl-carousel .owl-dots button.active {
  width: 47px;
  background-color: #d40b2e
}

section.BannerMain .owl-carousel .owl-nav {
  position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: 99
}

section.BannerMain .owl-carousel .owl-nav.disabled {
  display: block
}

@media only screen and (max-width: 767px) {
  section.BannerMain .owl-carousel .owl-nav.disabled {
    display: none
  }
}

section.BannerMain .owl-carousel .owl-nav button {
  font-size: 50px;
  color: #fff;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  border: 1px solid #fff;
  padding: 10px !important;
  outline: none;
  margin: 0 10px;
  transition: all ease .2s
}

section.BannerMain .owl-carousel .owl-nav button span {
  display: block;
  line-height: 21px;
  height: 100%
}

section.BannerMain .owl-carousel .owl-nav button:hover {
  background-color: #fff;
  border-color: #fff;
  color: #d40b2e;
  transition: all ease .2s
}

section.BannerMain .BannerbottomSection {
  background-color: #1e5591;
}

section.BannerMain .BannerbottomSection .signUpContent {
  padding: 60px 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerbottomSection .signUpContent {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 40px 0
  }
}

section.BannerMain .BannerbottomSection .signUpContent .content {
  padding: 0 15px;
  text-align: right;
  flex: 3
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerbottomSection .signUpContent .content {
    text-align: center
  }
}

section.BannerMain .BannerbottomSection .signUpContent .content h3 {
  font-size: 32px;
  color: #fff;
  font-weight: 200;
  margin: 0
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .BannerbottomSection .signUpContent .content h3 {
    font-size: 26px
  }
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerbottomSection .signUpContent .content h3 {
    font-size: 22px;
    margin-bottom: 10px
  }
}

section.BannerMain .BannerbottomSection .signUpContent .content p {
  font-size: 20px;
  color: #fff;
  font-weight: 200;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerbottomSection .signUpContent .content p {
    font-size: 16px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .BannerbottomSection .signUpContent .content p {
    font-size: 14px
  }
}

section.BannerMain .BannerbottomSection .signUpContent .content p span {
  color: #f0ff00
}

section.BannerMain .BannerbottomSection .signUpContent .singUpbutton {
  padding: 0 15px;
  flex: 1
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerbottomSection .signUpContent .singUpbutton {
    margin-top: 20px
  }
}

section.BannerMain .BannerbottomSection .signUpContent .singUpbutton a {
  padding: 10px 20px;
  font-family: "Cambria-Regular";
  font-size: 24px;
  color: #fff;
  font-weight: 200;
  border: 1px solid #fff;
  display: inline-block;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.5s
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerbottomSection .signUpContent .singUpbutton a {
    font-size: 20px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .BannerbottomSection .signUpContent .singUpbutton a {
    font-size: 16px;
    padding: 8px 15px
  }
}

section.BannerMain .BannerbottomSection .signUpContent .singUpbutton a:hover {
  border-color: #0c1014;
  background-color: #fff;
  color: #0e0809;
}

section.AdvSection {
  padding: 100px 0
}

@media only screen and (max-width: 767px) {
  section.AdvSection {
    padding: 60px 0
  }
}

@media only screen and (max-width: 1024px) {
  section.AdvSection .AdvInner {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

section.AdvSection .AdvInner .item {
  flex-basis: 25%;
  max-width: 25%;
  padding: 10px 10px
}

@media only screen and (max-width: 1024px) {
  section.AdvSection .AdvInner .item {
    flex-basis: 50%;
    max-width: 50%
  }
}

@media only screen and (max-width: 600px) {
  section.AdvSection .AdvInner .item {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 20px
  }
}

section.AdvSection .AdvInner .item .itemInner {
  text-align: center
}

section.AdvSection .AdvInner .item .itemInner .icon {
  width: 75px;
  height: 75px;
  margin-bottom: 15px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

section.AdvSection .AdvInner .item .itemInner h3 {
  font-family: "Cambria-Bold";
  font-size: 30px;
  color: #2b2b2b;
  margin-top: 0px;
  margin-bottom: 10px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 767px) {
  section.AdvSection .AdvInner .item .itemInner h3 {
    font-size: 26px
  }
}

@media only screen and (max-width: 600px) {
  section.AdvSection .AdvInner .item .itemInner h3 {
    font-size: 24px
  }
}

section.AdvSection .AdvInner .item .itemInner p {
  font-size: 17px;
  color: #2b2b2b;
  margin: 10px 0;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 767px) {
  section.AdvSection .AdvInner .item .itemInner p {
    font-size: 16px
  }
}

section.AdvSection .AdvInner .item .itemInner:hover .icon {
  transform: translateY(-10px)
}

section.AdvSection .AdvInner .item .itemInner:hover h3 {
  transform: translateY(-7px)
}

section.AdvSection .AdvInner .item .itemInner:hover p {
  transform: translateY(-4px)
}

section .TopContent .ourservie {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center
}

section .TopContent .ourservie span {
  padding-top: 2px
}

section .TopContent .ourservie span i {
  background-color: #1e5591;
  height: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3px
}

section .TopContent .ourservie span i:first-child {
  width: 20px
}

section .TopContent .ourservie span i:last-child {
  width: 40px
}

section .TopContent .ourservie span.leftLine {
  text-align: right
}

section .TopContent .ourservie span.leftLine i {
  margin-left: auto
}

section .TopContent .ourservie span.rightLine {
  text-align: left
}

section .TopContent .ourservie p {
  font-size: 16px;
  color: #1e5591;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
  margin: 0;
  padding: 0 20px
}

@media only screen and (max-width: 600px) {
  section .TopContent .ourservie p {
    font-size: 14px;
    padding: 0 10px;
    letter-spacing: 0px
  }
}

section .TopContent .Title {
  margin-bottom: 20px
}

section .TopContent .Title h3 {
  font-family: "Cambria-Regular";
  font-size: 52px;
  color: #2b2b2b;
  text-align: center;
  margin: 0
}

@media only screen and (max-width: 1024px) {
  section .TopContent .Title h3 {
    font-size: 45px
  }

  section .TopContent .Title h3 br {
    display: none
  }
}

@media only screen and (max-width: 767px) {
  section .TopContent .Title h3 {
    font-size: 38px;
    line-height: 40px
  }
}

@media only screen and (max-width: 600px) {
  section .TopContent .Title h3 {
    font-size: 32px;
    line-height: 36px
  }
}

section .TopContent .content {
  margin-bottom: 20px
}

section .TopContent .content p {
  font-size: 18px;
  color: rgba(43, 43, 43, 0.7);
  text-align: center;
  margin: 0
}

@media only screen and (max-width: 1024px) {
  section .TopContent .content p br {
    display: none
  }
}

@media only screen and (max-width: 600px) {
  section .TopContent .content p {
    font-size: 16px;
    line-height: 20px
  }
}

section .TopContent .viewAll {
  font-family: "Cambria-Bold";
  font-size: 18px;
  color: #1e5591;
  text-transform: uppercase
}

section .TopContent .viewAll:after {
  content: '';
  width: 7px;
  height: 7px;
  border-width: 1px;
  border-style: solid;
  border-color: #1e5591 #1e5591 transparent transparent;
  transform: rotate(45deg);
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 2px
}

section .TopContent .playStore {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex
}

section .TopContent .playStore img {
  max-width: 180px;
  max-width: 100%;
  height: 52px
}

section .SingleLineHeading h3 {
  font-family: "Cambria-Regular";
  font-size: 52px;
  color: #2b2b2b;
  text-align: center;
  margin: 0
}

@media only screen and (max-width: 1024px) {
  section .SingleLineHeading h3 {
    font-size: 45px
  }

  section .SingleLineHeading h3 br {
    display: none
  }
}

@media only screen and (max-width: 767px) {
  section .SingleLineHeading h3 {
    font-size: 38px;
    line-height: 40px
  }
}

@media only screen and (max-width: 600px) {
  section .SingleLineHeading h3 {
    font-size: 32px;
    line-height: 36px
  }
}

section.ServiceSection {
  background-color: #f6f6f6;
  padding: 80px 0
}

@media only screen and (max-width: 600px) {
  section.ServiceSection {
    padding: 50px 0
  }
}

section.ServiceSection .ServiceSlider {
  margin-top: 65px
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage {
  padding-left: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%
}

@media only screen and (max-width: 767px) {
  section.ServiceSection .ServiceSlider .owl-carousel .owl-stage {
    padding-left: 0
  }
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item {
  height: 100%
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner {
  text-decoration: none;
  text-align: center;
  padding: 40px 25px 40px;
  margin: 0 20px;
  display: block;
  height: 100%;
  border-radius: 4px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 767px) {
  section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner {
    padding: 40px 20px 40px;
    margin: 0 10px
  }
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner .image-box {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  height: 250px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s
}

@media only screen and (max-width: 767px) {
  section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner .image-box {
    margin-bottom: 30px;
    height: 200px
  }
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner .image-box img {
  object-fit: contain;
  height: 100%
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner h4 {
  font-size: 20px;
  color: #2b2b2b;
  font-weight: 600;
  margin-bottom: 16px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner p {
  font-size: 16px;
  color: #2b2b2b;
  line-height: 28px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  margin: 0
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner .readMore {
  font-size: 16px;
  color: #d40b2e;
  line-height: 24px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  opacity: 0;
  margin-top: 16px;
  transition: all .3s ease-in-out
}

@media only screen and (max-width: 767px) {
  section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner .readMore {
    opacity: 1
  }
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner .readMore:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  position: absolute;
  content: "";
  height: 3px;
  width: 0;
  background-color: transparent;
  border-bottom: 2px solid #d40b2e;
  transition: all .3s ease-in-out;
  transform: translate(0, 0) translateZ(0)
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner:hover .image-box {
  transform: translateY(-10px)
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner:hover h4 {
  transform: translateY(-7px)
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner:hover p {
  transform: translateY(-4px)
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner:hover .readMore {
  opacity: 1;
  transform: translateY(-3px)
}

section.ServiceSection .ServiceSlider .owl-carousel .owl-stage .owl-item .item .services-inner:hover .readMore:after {
  width: 100%
}

section.AwordWiningSection {
  padding: 80px 0
}

@media only screen and (max-width: 600px) {
  section.AwordWiningSection {
    padding: 50px 0
  }
}

section.AwordWiningSection .addServices {
  margin-top: 65px
}

@media only screen and (max-width: 767px) {
  section.AwordWiningSection .addServices {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
  }
}

section.AwordWiningSection .addServices .leftService,
section.AwordWiningSection .addServices .rightService {
  width: 35%
}

@media only screen and (max-width: 767px) {

  section.AwordWiningSection .addServices .leftService,
  section.AwordWiningSection .addServices .rightService {
    order: 2;
    width: 50%;
    padding: 0 15px
  }
}

@media only screen and (max-width: 600px) {

  section.AwordWiningSection .addServices .leftService,
  section.AwordWiningSection .addServices .rightService {
    width: 100%
  }
}

section.AwordWiningSection .addServices .leftService ul,
section.AwordWiningSection .addServices .rightService ul {
  padding-left: 0
}

@media only screen and (max-width: 600px) {

  section.AwordWiningSection .addServices .leftService ul,
  section.AwordWiningSection .addServices .rightService ul {
    margin-bottom: 0
  }
}

section.AwordWiningSection .addServices .leftService ul li,
section.AwordWiningSection .addServices .rightService ul li {
  padding: 20px 0;
  cursor: default
}

section.AwordWiningSection .addServices .leftService ul li .icon,
section.AwordWiningSection .addServices .rightService ul li .icon {
  width: 100px;
  height: 100px;
  min-width: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  background-color: #f6f6f6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  margin-right: 15px
}

@media only screen and (max-width: 1024px) {

  section.AwordWiningSection .addServices .leftService ul li .icon,
  section.AwordWiningSection .addServices .rightService ul li .icon {
    width: 75px;
    height: 75px;
    min-width: 75px
  }
}

@media only screen and (max-width: 600px) {

  section.AwordWiningSection .addServices .leftService ul li .icon,
  section.AwordWiningSection .addServices .rightService ul li .icon {
    width: 65px;
    height: 65px;
    min-width: 65px
  }
}

section.AwordWiningSection .addServices .leftService ul li .icon svg,
section.AwordWiningSection .addServices .rightService ul li .icon svg {
  width: 48px;
  height: 48px
}

@media only screen and (max-width: 1024px) {

  section.AwordWiningSection .addServices .leftService ul li .icon svg,
  section.AwordWiningSection .addServices .rightService ul li .icon svg {
    width: 40px;
    height: 40px
  }
}

@media only screen and (max-width: 600px) {

  section.AwordWiningSection .addServices .leftService ul li .icon svg,
  section.AwordWiningSection .addServices .rightService ul li .icon svg {
    width: 34px;
    height: 34px
  }
}

section.AwordWiningSection .addServices .leftService ul li .text p,
section.AwordWiningSection .addServices .rightService ul li .text p {
  font-size: 22px;
  color: #2b2b2b;
  font-weight: 200;
  margin: 0
}

@media only screen and (max-width: 1024px) {

  section.AwordWiningSection .addServices .leftService ul li .text p,
  section.AwordWiningSection .addServices .rightService ul li .text p {
    font-size: 18px
  }
}

section.AwordWiningSection .addServices .leftService ul li {
  text-align: right;
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

@media only screen and (max-width: 767px) {
  section.AwordWiningSection .addServices .leftService ul li {
    text-align: left
  }
}

section.AwordWiningSection .addServices .leftService ul li .icon {
  margin-right: 0;
  margin-left: 15px
}

@media only screen and (max-width: 767px) {
  section.AwordWiningSection .addServices .leftService ul li .icon {
    margin-left: 0px;
    margin-right: 15px
  }
}

@media only screen and (max-width: 767px) {
  section.AwordWiningSection .addServices .leftService ul li {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row
  }
}

section.AwordWiningSection .addServices .AppMobile {
  width: 30%;
  padding: 0 20px;
  text-align: center
}

@media only screen and (max-width: 767px) {
  section.AwordWiningSection .addServices .AppMobile {
    order: 1;
    width: 100%;
    margin-bottom: 40px
  }

  section.AwordWiningSection .addServices .AppMobile img {
    max-width: 230px
  }
}

section.DigitalDostSection {
  background-color: #fff6f6;
  padding: 80px 0 40px
}

@media only screen and (max-width: 600px) {
  section.DigitalDostSection {
    padding: 50px 0 20px
  }
}

section.DigitalDostSection .DigitalDostRow {
  padding: 40px 0;
  position: relative
}

@media only screen and (max-width: 767px) {
  section.DigitalDostSection .DigitalDostRow {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

section.DigitalDostSection .DigitalDostRow:last-child:after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  top: -1px;
  background-image: -moz-linear-gradient(0deg, #fff6f6 0%, #d40b2e 50%, #fff6f6 100%);
  background-image: -webkit-linear-gradient(0deg, #fff6f6 0%, #d40b2e 50%, #fff6f6 100%);
  background-image: -ms-linear-gradient(0deg, #fff6f6 0%, #d40b2e 50%, #fff6f6 100%)
}

section.DigitalDostSection .DigitalDostRow .LeftSide {
  width: 25%;
  text-align: center;
  padding: 10px 10px;
  position: relative
}

@media only screen and (max-width: 767px) {
  section.DigitalDostSection .DigitalDostRow .LeftSide {
    width: 100%
  }
}

section.DigitalDostSection .DigitalDostRow .LeftSide img {
  max-height: 450px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: translateY(20px)
}

section.DigitalDostSection .DigitalDostRow .LeftSide .Title {
  background-color: #1e5591;
  padding: 10px 15px;
  position: absolute;
  bottom: 40px;
  left: 50%;
  display: inline-block;
  transform: translateX(-50%)
}

section.DigitalDostSection .DigitalDostRow .LeftSide .Title .left:before {
  content: '';
  border-width: 22px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: -22px
}

section.DigitalDostSection .DigitalDostRow .LeftSide .Title .left:after {
  content: '';
  border-width: 22px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
  position: absolute;
  bottom: 0;
  left: -22px
}

section.DigitalDostSection .DigitalDostRow .LeftSide .Title .right:before {
  content: '';
  border-width: 22px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -22px
}

section.DigitalDostSection .DigitalDostRow .LeftSide .Title .right:after {
  content: '';
  border-width: 22px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
  position: absolute;
  right: -22px;
  bottom: 0
}

section.DigitalDostSection .DigitalDostRow .LeftSide .Title h3 {
  font-family: "Cambria-Regular";
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0
}

@media only screen and (max-width: 600px) {
  section.DigitalDostSection .DigitalDostRow .LeftSide .Title h3 {
    font-size: 16px
  }
}

section.DigitalDostSection .DigitalDostRow:hover .LeftSide img {
  transform: translateY(0px)
}

section.DigitalDostSection .DigitalDostRow .RightSide {
  width: 75%;
  padding: 10px 15px
}

@media only screen and (max-width: 767px) {
  section.DigitalDostSection .DigitalDostRow .RightSide {
    width: 100%;
    padding: 10px 0;
    margin-top: 30px
  }
}

section.DigitalDostSection .DigitalDostRow .RightSide ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0
}

section.DigitalDostSection .DigitalDostRow .RightSide ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  -moz-justify-content: start;
  justify-content: start;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 15px 10px;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%
}

@media only screen and (max-width: 1024px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li {
    flex-basis: 50%;
    max-width: 50%
  }
}

@media only screen and (max-width: 767px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%
  }
}

@media only screen and (max-width: 600px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li {
    flex-basis: 50%;
    max-width: 50%
  }
}

@media (max-width: 400px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li {
    flex-basis: 100%;
    max-width: 100%
  }
}

section.DigitalDostSection .DigitalDostRow .RightSide ul li .icon {
  width: 90px;
  height: 90px;
  min-width: 90px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  background-color: #f6f6f6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 1024px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li .icon {
    width: 75px;
    height: 75px;
    min-width: 75px
  }
}

@media only screen and (max-width: 767px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li .icon {
    margin-bottom: 10px
  }
}

@media only screen and (max-width: 600px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li .icon {
    width: 65px;
    height: 65px;
    min-width: 65px
  }
}

section.DigitalDostSection .DigitalDostRow .RightSide ul li .icon svg {
  width: 48px;
  height: 48px
}

@media only screen and (max-width: 1024px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li .icon svg {
    width: 40px;
    height: 40px
  }
}

@media only screen and (max-width: 600px) {
  section.DigitalDostSection .DigitalDostRow .RightSide ul li .icon svg {
    width: 34px;
    height: 34px
  }
}

section.DigitalDostSection .DigitalDostRow .RightSide ul li .text {
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

section.DigitalDostSection .DigitalDostRow .RightSide ul li .text p {
  font-size: 15px;
  color: #2b2b2b;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  margin: 0
}

section.DigitalDostSection .DigitalDostRow .RightSide ul li:hover {
  cursor: default
}

section.DigitalDostSection .DigitalDostRow .RightSide ul li:hover .icon {
  transform: translateY(-15px)
}

section.DigitalDostSection .DigitalDostRow .RightSide ul li:hover .text {
  transform: translateY(-5px)
}

section.OurPartnersSection {
  padding: 80px 0
}

@media only screen and (max-width: 600px) {
  section.OurPartnersSection {
    padding: 50px 0
  }
}

section.OurPartnersSection .partnersList {
  margin: 40px 0 0
}

@media only screen and (max-width: 600px) {
  section.OurPartnersSection .partnersList {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

section.OurPartnersSection .partnersList .item {
  margin: 0px 10px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  border-radius: 12px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 600px) {
  section.OurPartnersSection .partnersList .item {
    width: calc(50% - 20px);
    margin: 10px
  }
}

section.OurPartnersSection .partnersList .item img {
  object-fit: contain;
  vertical-align: middle
}

@media only screen and (max-width: 600px) {
  section.OurPartnersSection .partnersList .item img {
    width: 100%
  }
}

section.OurPartnersSection .partnersList .item:hover {
  transform: translateY(-10px)
}

section.signUpSection {
  /* background-color: #3e1929; */
  background-color: #253c5d;
  padding: 80px 0
}

section.signUpSection .TopContent .ourservie span i {
  background-color: #f0ff00
}

section.signUpSection .TopContent .ourservie p {
  color: #f0ff00
}

section.signUpSection .TopContent .Title h3 {
  color: #fff
}

section.signUpSection .TopContent .content p {
  color: rgba(255, 255, 255, 0.7)
}

section.signUpSection .signUPInner ul {
  margin: 50px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  border: 0;
  padding: 0
}

section.signUpSection .signUPInner ul .or {
  min-width: auto;
  width: auto
}

section.signUpSection .signUPInner ul .or h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.signUpSection .signUPInner ul .or h4 {
    font-size: 14px
  }
}

section.signUpSection .signUPInner ul li {
  margin: 0 20px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  width: 262px
}

@media only screen and (max-width: 767px) {
  section.signUpSection .signUPInner ul li {
    margin: 0 7px;
    width: 160px
  }
}

@media only screen and (max-width: 600px) {
  section.signUpSection .signUPInner ul li {
    width: 110px
  }
}

section.signUpSection .signUPInner ul li input[type="radio"] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer
}

section.signUpSection .signUPInner ul li input[type="radio"]:checked+.SignOptRadio {
  background-color: #fff;
  color: #1e5591
}

section.signUpSection .signUPInner ul li input[type="radio"]:checked+.SignOptRadio .icon svg {
  fill: #1e5591
}

section.signUpSection .signUPInner ul li .SignOptRadio {
  cursor: pointer;
  border: 1px solid #fff;
  padding: 10px 30px;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-size: 18px;
  color: #fff
}

@media only screen and (max-width: 767px) {
  section.signUpSection .signUPInner ul li .SignOptRadio {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border-radius: 12px;
    text-align: center;
    padding: 10px 15px;
    font-size: 16px
  }
}

@media only screen and (max-width: 600px) {
  section.signUpSection .signUPInner ul li .SignOptRadio {
    padding: 10px 10px;
    font-size: 14px
  }
}

@media only screen and (max-width: 767px) {
  section.signUpSection .signUPInner ul li .SignOptRadio .icon {
    margin-bottom: 10px
  }
}

section.signUpSection .signUPInner ul li .SignOptRadio .icon svg {
  width: 36px;
  height: 36px;
  fill: #fff
}

section.signUpSection .signUPInner ul li .SignOptRadio span {
  margin-left: 15px
}

@media only screen and (max-width: 767px) {
  section.signUpSection .signUPInner ul li .SignOptRadio span {
    margin-left: 0
  }
}

section.signUpSection .signUPInner .signUpForm .row {
  margin-left: -15px;
  margin-right: -15px
}

section.signUpSection .signUPInner .signUpForm input:-webkit-autofill,
section.signUpSection .signUPInner .signUpForm input:-webkit-autofill:hover,
section.signUpSection .signUPInner .signUpForm input:-webkit-autofill:focus,
section.signUpSection .signUPInner .signUpForm textarea:-webkit-autofill,
section.signUpSection .signUPInner .signUpForm textarea:-webkit-autofill:hover,
section.signUpSection .signUPInner .signUpForm textarea:-webkit-autofill:focus,
section.signUpSection .signUPInner .signUpForm select:-webkit-autofill,
section.signUpSection .signUPInner .signUpForm select:-webkit-autofill:hover,
section.signUpSection .signUPInner .signUpForm select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s
}

section.signUpSection .signUPInner .signUpForm .form-group {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  width: 100%;
  position: relative
}

section.signUpSection .signUPInner .signUpForm .form-group .label {
  font-size: 16px;
  color: #fff;
  padding: 10px 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0px;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1)
}

section.signUpSection .signUPInner .signUpForm ::-webkit-input-placeholder {
  color: #fff
}

section.signUpSection .signUPInner .signUpForm ::-moz-placeholder {
  color: #fff
}

section.signUpSection .signUPInner .signUpForm :-ms-input-placeholder {
  color: #fff
}

section.signUpSection .signUPInner .signUpForm :-moz-placeholder {
  color: #fff
}

section.signUpSection .signUPInner .signUpForm .form-control {
  width: 100%;
  padding: 5px 0;
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #fff;
  font-size: 16px;
  color: #fff;
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none
}

section.signUpSection .signUPInner .signUpForm .form-control:focus {
  border-color: #d40b2e
}

section.signUpSection .signUPInner .signUpForm .form-control.error {
  border-color: #ff5f5f
}

section.signUpSection .signUPInner .signUpForm .form-control.error+.error {
  font-size: 12px;
  font-weight: 500;
  padding-top: 2px;
  color: #ff5f5f;
  position: absolute;
  margin: 0
}

section.signUpSection .signUPInner .signUpForm select option {
  color: #2b2b2b;
  padding: 6px 15px
}

section.signUpSection .signUPInner .signUpForm input:focus+.label,
section.signUpSection .signUPInner .signUpForm input:not(:placeholder-shown)+.label,
section.signUpSection .signUPInner .signUpForm select:focus+.label,
section.signUpSection .signUPInner .signUpForm select:not(:placeholder-shown)+.label {
  font-size: 13px;
  opacity: 1;
  transform: translateY(-100%) translateX(0)
}

section.signUpSection .signUPInner .signUpForm input:focus+.label,
section.signUpSection .signUPInner .signUpForm input:not(:-ms-input-placeholder)+.label {
  font-size: 13px;
  opacity: 1;
  transform: translateY(-100%) translateX(0)
}

section.signUpSection .signUPInner .signUpForm .agreeRow {
  text-align: center;
  margin-top: 40px
}

section.signUpSection .signUPInner .signUpForm .agreeRow input[type="checkbox"] {
  opacity: 0;
  filter: opacity(0)
}

section.signUpSection .signUPInner .signUpForm .agreeRow input[type="checkbox"]:checked~label.customCheckbox::before {
  background-color: #fff
}

section.signUpSection .signUPInner .signUpForm .agreeRow input[type="checkbox"]:checked~label.customCheckbox::after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 9px;
  width: 5px;
  height: 12px;
  border: solid #1e5591;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg)
}

section.signUpSection .signUPInner .signUpForm .agreeRow input[type="checkbox"].error {
  border-color: #ff5f5f
}

section.signUpSection .signUPInner .signUpForm .agreeRow input[type="checkbox"].error+.error {
  font-size: 12px;
  font-weight: 500;
  padding-top: 30px;
  color: #ff5f5f;
  position: absolute;
  margin: 0;
  text-align: center;
  width: 100%;
  left: 0
}

section.signUpSection .signUPInner .signUpForm .agreeRow .customCheckbox {
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  font-weight: 300
}

section.signUpSection .signUPInner .signUpForm .agreeRow .customCheckbox::before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  padding: 10px;
  display: inline-block;
  position: relative;
  top: -1px;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px
}

section.signUpSection .signUPInner .signUpForm .agreeRow .customCheckbox a {
  color: #f0ff00
}

section.signUpSection .signUPInner .signUpForm .button:hover {
  border-color: #fff
}

section.BannerMain .BannerInner {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerInner {
    height: 300px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .BannerInner {
    height: 240px
  }
}

section.BannerMain .BannerInner .Title {
  text-align: center
}

section.BannerMain .BannerInner .Title h2 {
  font-family: "Cambria-Regular";
  font-size: 64px;
  color: #fff;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  margin: 0
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .BannerInner .Title h2 {
    font-size: 56px
  }
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerInner .Title h2 {
    font-size: 48px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .BannerInner .Title h2 {
    font-size: 32px
  }
}

section.BannerMain .BannerInner .Title p {
  font-size: 20px;
  color: #fff;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .BannerInner .Title p {
    font-size: 16px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .BannerInner .Title p {
    font-size: 14px
  }
}

section.BannerMain .BannerInner .Title p span {
  color: #f0ff00
}

section .ServicesDetails {
  margin-top: 50px
}

section .ServicesDetails .List .ListInner {
  padding: 80px 0
}

@media only screen and (max-width: 1024px) {
  section .ServicesDetails .List .ListInner {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

section .ServicesDetails .List .ListInner .images {
  width: 37.5%;
  padding: 0 50px;
  text-align: center
}

@media only screen and (max-width: 1024px) {
  section .ServicesDetails .List .ListInner .images {
    width: 100%;
    margin-bottom: 20px
  }
}

@media only screen and (max-width: 767px) {
  section .ServicesDetails .List .ListInner .images {
    padding: 0 30px
  }
}

@media only screen and (max-width: 600px) {
  section .ServicesDetails .List .ListInner .images {
    padding: 0 15px
  }
}

section .ServicesDetails .List .ListInner .images img {
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  max-width: 420px
}

section .ServicesDetails .List .ListInner .Content {
  width: calc(100% - 37.5%)
}

@media only screen and (max-width: 1024px) {
  section .ServicesDetails .List .ListInner .Content {
    width: 100%;
    text-align: center
  }
}

section .ServicesDetails .List .ListInner .Content .Title {
  padding-bottom: 32px;
  margin-bottom: 50px;
  position: relative
}

@media only screen and (max-width: 767px) {
  section .ServicesDetails .List .ListInner .Content .Title {
    margin-bottom: 30px
  }
}

section .ServicesDetails .List .ListInner .Content .Title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 3px;
  background-color: #1e5591
}

@media only screen and (max-width: 1024px) {
  section .ServicesDetails .List .ListInner .Content .Title:after {
    margin: auto;
    right: 0
  }
}

section .ServicesDetails .List .ListInner .Content .Title h3 {
  font-family: "Cambria-Bold";
  font-size: 32px;
  color: #2b2b2b;
  margin-top: 0px;
  margin-bottom: 0px
}

@media only screen and (max-width: 767px) {
  section .ServicesDetails .List .ListInner .Content .Title h3 {
    font-size: 28px
  }
}

@media only screen and (max-width: 600px) {
  section .ServicesDetails .List .ListInner .Content .Title h3 {
    font-size: 24px
  }
}

section .ServicesDetails .List .ListInner .Content .Title p {
  font-size: 16px;
  color: rgba(43, 43, 43, 0.7);
  margin: 0
}

@media only screen and (max-width: 767px) {
  section .ServicesDetails .List .ListInner .Content .Title p {
    font-size: 14px
  }
}

section .ServicesDetails .List .ListInner .Content .Text p {
  font-size: 17px;
  color: #2b2b2b;
  line-height: 35px;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section .ServicesDetails .List .ListInner .Content .Text p {
    font-size: 15px;
    line-height: 28px
  }
}

section .ServicesDetails .List:hover .ListInner .images img {
  transform: translateY(-10px)
}

section.BannerMain .contactusBanner {
  height: 500px
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .contactusBanner {
    height: 400px
  }
}

@media only screen and (max-width: 767px) {
  section.BannerMain .contactusBanner {
    height: 300px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .contactusBanner {
    height: 240px
  }
}

section.BannerMain .contactusBanner .Wrapper {
  height: 100%
}

section.BannerMain .contactusBanner .PageHeading {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
  -webkit-box-pack: Flex-start;
  -ms-flex-pack: Flex-start;
  -webkit-justify-content: Flex-start;
  -moz-justify-content: Flex-start;
  justify-content: Flex-start;
  height: 100%
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .contactusBanner .PageHeading {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

section.BannerMain .contactusBanner .PageHeading .heading {
  margin-bottom: 0 !important;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: flex-Start;
  -ms-flex-align: flex-Start;
  -webkit-align-items: flex-Start;
  -moz-align-items: flex-Start;
  align-items: flex-Start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  height: 100%
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .contactusBanner .PageHeading .heading {
    width: 100%;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center
  }
}

section.BannerMain .contactusBanner .PageHeading .heading h2 {
  font-family: "Cambria-Regular";
  font-size: 64px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  text-align: left;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .contactusBanner .PageHeading .heading h2 {
    text-align: left;
    font-size: 45px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .contactusBanner .PageHeading .heading h2 {
    font-size: 34px;
    line-height: 34px;
    text-align: center !important
  }
}

section.BannerMain .contactusBanner .PageHeading .heading p {
  font-size: 20px;
  color: #fff;
  font-weight: 200;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .contactusBanner .PageHeading .heading p {
    font-size: 16px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .contactusBanner .PageHeading .heading p {
    font-size: 14px
  }
}

section.BannerMain .contactusBanner .PageHeading .socialMedia {
  height: 145px;
  position: absolute;
  left: 0
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .contactusBanner .PageHeading .socialMedia {
    width: 100%;
    height: auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    padding: 20px 0;
    bottom: 0
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .contactusBanner .PageHeading .socialMedia {
    justify-content: center
  }
}

section.BannerMain .contactusBanner .PageHeading .socialMedia a {
  width: 40px;
  height: 40px;
  background-color: #f6f6f6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .contactusBanner .PageHeading .socialMedia a {
    width: 34px;
    height: 34px;
    margin-right: 5px
  }
}

section.BannerMain .contactusBanner .PageHeading .socialMedia a svg {
  width: 16px;
  height: 16px
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .contactusBanner .PageHeading .socialMedia a svg {
    width: 13px;
    height: 13px
  }
}

section.BannerMain .contactusBanner .PageHeading .socialMedia a svg path {
  fill: #fff
}

section.BannerMain .contactusBanner .PageHeading .socialMedia a:hover {
  background: #d40b2e !important;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

section.ContactForm {
  padding: 80px 0;
  background-color: #f2f2f2
}

section.ContactForm .contactDetails {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center
}

@media only screen and (max-width: 1024px) {
  section.ContactForm .contactDetails {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

section.ContactForm .contactDetails .leftDetails {
  width: 40%;
  padding-right: 40px
}

@media only screen and (max-width: 1024px) {
  section.ContactForm .contactDetails .leftDetails {
    width: 100%;
    padding-right: 0
  }
}

section.ContactForm .contactDetails .leftDetails h3 {
  font-family: "Cambria-Regular";
  font-size: 52px;
  color: #000;
  line-height: 52px;
  text-align: center;
  margin-bottom: 20px
}

@media only screen and (max-width: 1024px) {
  section.ContactForm .contactDetails .leftDetails h3 br {
    display: none
  }
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .leftDetails h3 {
    font-size: 45px;
    line-height: 45px
  }
}

@media only screen and (max-width: 600px) {
  section.ContactForm .contactDetails .leftDetails h3 {
    font-size: 34px;
    line-height: 34px
  }
}

section.ContactForm .contactDetails .leftDetails>p {
  font-size: 26px;
  color: #2b2b2b;
  margin: 0;
  text-align: center
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .leftDetails>p {
    font-size: 22px
  }
}

@media only screen and (max-width: 600px) {
  section.ContactForm .contactDetails .leftDetails>p {
    font-size: 18px
  }
}

section.ContactForm .contactDetails .leftDetails .details {
  margin-top: 50px
}

section.ContactForm .contactDetails .leftDetails .details ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: Flex-start;
  -ms-flex-pack: Flex-start;
  -webkit-justify-content: Flex-start;
  -moz-justify-content: Flex-start;
  justify-content: Flex-start;
  -webkit-box-align: Flex-start;
  -ms-flex-align: Flex-start;
  -webkit-align-items: Flex-start;
  -moz-align-items: Flex-start;
  align-items: Flex-start;
  margin-bottom: 30px;
  position: relative
}

section.ContactForm .contactDetails .leftDetails .details ul li:after {
  content: '';
  position: absolute;
  top: 35px;
  left: 15px;
  width: 4px;
  height: 100%;
  background-color: #96b2c5
}

section.ContactForm .contactDetails .leftDetails .details ul li:last-child::after {
  display: none
}

section.ContactForm .contactDetails .leftDetails .details ul li span {
  width: 35px;
  height: 35px;
  min-width: 35px;
  background-color: #d40b2e;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  position: relative;
  margin-right: 20px
}

section.ContactForm .contactDetails .leftDetails .details ul li span:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #d40b2e;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border: 3px solid #fff
}

section.ContactForm .contactDetails .leftDetails .details ul li .text h4 {
  font-family: "Cambria-Regular";
  font-size: 24px;
  color: #000;
  margin-bottom: 20px
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .leftDetails .details ul li .text h4 {
    font-size: 22px;
    margin-bottom: 15px
  }
}

@media only screen and (max-width: 600px) {
  section.ContactForm .contactDetails .leftDetails .details ul li .text h4 {
    font-size: 20px;
    margin-bottom: 10px
  }
}

section.ContactForm .contactDetails .leftDetails .details ul li .text p {
  font-size: 16px;
  color: #2b2b2b;
  line-height: 30px;
  margin: 0
}

section.ContactForm .contactDetails .leftDetails .details ul li .text p a {
  color: #2b2b2b
}

section.ContactForm .contactDetails .leftDetails .details ul li .text p a:hover {
  color: #d40b2e
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .leftDetails .details ul li .text p {
    font-size: 14px;
    line-height: 22px
  }
}

section.ContactForm .contactDetails .RightForm {
  width: 60%
}

@media only screen and (max-width: 1024px) {
  section.ContactForm .contactDetails .RightForm {
    width: 100%
  }
}

section.ContactForm .contactDetails .RightForm .FormBox {
  background-color: #fff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: -225px
}

@media only screen and (max-width: 1024px) {
  section.ContactForm .contactDetails .RightForm .FormBox {
    margin-top: 40px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .heading {
  background-color: #f2f2f2;
  padding: 35px
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .RightForm .FormBox .heading {
    padding: 15px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .heading span {
  width: 75px;
  height: 75px;
  min-width: 75px;
  background-color: #d40b2e;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  margin-right: 30px;
  box-shadow: 0px 4px 9px 0px rgba(212, 11, 46, 0.2)
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .RightForm .FormBox .heading span {
    width: 60px;
    height: 60px;
    min-width: 60px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .heading span svg {
  width: 34px
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .RightForm .FormBox .heading span svg {
    width: 30px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .heading h3 {
  font-family: "Cambria-Regular";
  font-size: 30px;
  color: #2b2b2b;
  line-height: 30px;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .RightForm .FormBox .heading h3 {
    font-size: 28px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner {
  padding: 30px
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .RightForm .FormBox .FormInner {
    padding: 0 15px 15px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner input:-webkit-autofill,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner input:-webkit-autofill:hover,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner input:-webkit-autofill:focus,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner textarea:-webkit-autofill,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner textarea:-webkit-autofill:hover,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner textarea:-webkit-autofill:focus,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner select:-webkit-autofill,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner select:-webkit-autofill:hover,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner select:-webkit-autofill:focus {
  -webkit-text-fill-color: #2b2b2b;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .row {
  margin-left: -15px;
  margin-right: -15px
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form ::-webkit-input-placeholder {
  color: #2b2b2b
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form ::-moz-placeholder {
  color: #2b2b2b
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form :-ms-input-placeholder {
  color: #2b2b2b
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form :-moz-placeholder {
  color: #2b2b2b
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-group {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  width: 100%;
  position: relative
}

@media only screen and (max-width: 600px) {
  section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-group {
    margin-bottom: 15px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-group .label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 72px;
  font-size: 20px;
  color: #2b2b2b;
  padding: 5px 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 20px;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 1
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-control {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #f2f2f2;
  height: 72px;
  background-color: #f2f2f2;
  font-size: 20px;
  color: #2b2b2b;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1)
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-control {
    height: 64px;
    font-size: 18px
  }
}

@media only screen and (max-width: 600px) {
  section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-control {
    height: 54px;
    font-size: 16px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-control:focus {
  background-color: #fff;
  border-color: #dcdcdc
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-control.error {
  border-color: #ff5f5f
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .form-control.error+.error {
  font-size: 12px;
  font-weight: 500;
  padding-top: 2px;
  color: #ff5f5f;
  position: absolute;
  margin: 0
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .dropArrow {
  position: absolute;
  top: 25px;
  right: 30px;
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent #2b2b2b #2b2b2b transparent
}

@media only screen and (max-width: 600px) {
  section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .dropArrow {
    top: 19px;
    right: 35px;
    width: 10px;
    height: 10px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form textarea.form-control {
  height: 240px;
  resize: none
}

@media only screen and (max-width: 767px) {
  section.ContactForm .contactDetails .RightForm .FormBox .FormInner form textarea.form-control {
    height: 140px;
    font-size: 18px
  }
}

@media only screen and (max-width: 600px) {
  section.ContactForm .contactDetails .RightForm .FormBox .FormInner form textarea.form-control {
    font-size: 16px
  }
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form input:focus+.label,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner form input:not(:placeholder-shown)+.label,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner form textarea:focus+.label,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner form textarea:not(:placeholder-shown)+.label {
  font-size: 14px;
  height: auto;
  left: 0;
  opacity: 1;
  transform: translateY(-100%) translateX(0)
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form input:focus+.label,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner form input:not(:-ms-input-placeholder)+.label,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner form textarea:focus+.label,
section.ContactForm .contactDetails .RightForm .FormBox .FormInner form textarea:not(:-ms-textarea-placeholder-shown)+.label {
  font-size: 14px;
  height: auto;
  left: 0;
  opacity: 1;
  transform: translateY(-100%) translateX(0)
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .buttonRow {
  width: 100%
}

section.ContactForm .contactDetails .RightForm .FormBox .FormInner form .buttonRow .button {
  width: 100%
}

section.LocationMap {
  padding: 80px 0;
  background-color: #fff
}

section.LocationMap .BranchMapList {
  margin-top: 60px
}

@media only screen and (max-width: 767px) {
  section.LocationMap .BranchMapList {
    margin-top: 20px
  }
}

section.LocationMap .BranchMapList .item {
  margin-bottom: 30px
}

@media only screen and (max-width: 767px) {
  section.LocationMap .BranchMapList .item {
    margin-top: 20px
  }

  section.LocationMap .BranchMapList .item:last-child {
    margin-bottom: 0
  }
}

section.LocationMap .BranchMapList .item .MapBox .GoogleMapFrame {
  width: 100%;
  height: 360px;
  border: 3px solid #f2f2f2;
  background: rgba(0, 0, 0, 0.1)
}

@media only screen and (max-width: 767px) {
  section.LocationMap .BranchMapList .item .MapBox .GoogleMapFrame {
    height: 280px
  }
}

section.LocationMap .BranchMapList .item .MapBox .GoogleMapFrame iframe {
  width: 100%;
  height: 100%
}

section.LocationMap .BranchMapList .item .Text {
  padding: 30px 0
}

@media only screen and (max-width: 767px) {
  section.LocationMap .BranchMapList .item .Text {
    padding: 15px 0
  }
}

section.LocationMap .BranchMapList .item .Text h3 {
  font-family: "Cambria-Regular";
  font-size: 34px;
  color: #000;
  margin-bottom: 20px
}

@media only screen and (max-width: 767px) {
  section.LocationMap .BranchMapList .item .Text h3 {
    font-size: 30px;
    margin-bottom: 10px
  }
}

@media only screen and (max-width: 600px) {
  section.LocationMap .BranchMapList .item .Text h3 {
    font-size: 24px
  }
}

section.LocationMap .BranchMapList .item .Text h3 a {
  color: #2b2b2b;
  text-decoration: none
}

section.LocationMap .BranchMapList .item .Text p {
  font-size: 18px;
  color: #2b2b2b;
  line-height: 32px;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.LocationMap .BranchMapList .item .Text p {
    font-size: 16px
  }
}

section.OurPartnersSection.contactPage {
  background-color: #f2f2f2
}

section.BannerMain .contactusBanner .Wrapper {
  height: 100%
}

section.BannerMain .contactusBanner .PageHeading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
  -webkit-box-pack: Flex-start;
  -ms-flex-pack: Flex-start;
  -webkit-justify-content: Flex-start;
  -moz-justify-content: Flex-start;
  justify-content: Flex-start;
  height: 100%
}

section.BannerMain .contactusBanner .PageHeading .heading {
  margin-bottom: 20px
}

section.BannerMain .contactusBanner .PageHeading .heading h2 {
  font-family: "Cambria-Regular";
  font-size: 64px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  text-align: left;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .contactusBanner .PageHeading .heading h2 {
    text-align: left;
    font-size: 45px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .contactusBanner .PageHeading .heading h2 {
    font-size: 34px;
    line-height: 34px
  }
}

section.BannerMain .contactusBanner .PageHeading .heading p {
  font-size: 20px;
  color: #fff;
  font-weight: 200;
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.BannerMain .contactusBanner .PageHeading .heading p {
    font-size: 16px
  }
}

@media only screen and (max-width: 600px) {
  section.BannerMain .contactusBanner .PageHeading .heading p {
    font-size: 14px
  }
}

section.BannerMain .contactusBanner .PageHeading .heading .socialMedia {
  margin-top: 20px;
  margin-bottom: 20px
}

section.BannerMain .contactusBanner .PageHeading .heading .socialMedia a {
  width: 40px;
  height: 40px;
  background-color: #f6f6f6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .contactusBanner .PageHeading .heading .socialMedia a {
    width: 34px;
    height: 34px;
    margin-right: 5px
  }
}

section.BannerMain .contactusBanner .PageHeading .heading .socialMedia a svg {
  width: 16px;
  height: 16px
}

@media only screen and (max-width: 1024px) {
  section.BannerMain .contactusBanner .PageHeading .heading .socialMedia a svg {
    width: 13px;
    height: 13px
  }
}

section.BannerMain .contactusBanner .PageHeading .heading .socialMedia a svg path {
  fill: #fff
}

section.BannerMain .contactusBanner .PageHeading .heading .socialMedia a:hover {
  background: #d40b2e !important;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

section.AboutContent {
  padding: 80px 0;
  background-color: #fff
}

section.AboutContent .innerContent {
  margin: 40px 0 0;
  padding: 0 120px
}

@media only screen and (max-width: 1024px) {
  section.AboutContent .innerContent {
    padding: 0 40px
  }
}

@media only screen and (max-width: 767px) {
  section.AboutContent .innerContent {
    padding: 0 0px
  }
}

section.AboutContent .innerContent p {
  font-size: 17px;
  color: #2b2b2b;
  margin-bottom: 30px;
  text-align: center
}

@media only screen and (max-width: 767px) {
  section.AboutContent .innerContent p {
    font-size: 16px;
    line-height: 24px;
    text-align: justify
  }
}

section.AboutContent .innerContent p:last-child {
  margin-bottom: 0
}

section.AboutContent .innerContent p span {
  color: #d40b2e
}

section.AboutContent .innerContent p i {
  font-weight: 600
}

section.OurValuePurpose {
  position: relative
}

section.OurValuePurpose:before,
section.OurValuePurpose:after {
  content: '';
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  border-bottom: 2px solid #fff;
  z-index: 1
}

@media only screen and (max-width: 767px) {

  section.OurValuePurpose:before,
  section.OurValuePurpose:after {
    display: none
  }
}

section.OurValuePurpose:before {
  left: 0;
  background-color: #ff6633;
  border-right: 1px solid #fff
}

section.OurValuePurpose:after {
  right: 0;
  background-color: #f9a03f;
  border-left: 1px solid #fff
}

section.OurValuePurpose .ValuePurposeSectionInner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: Flex-start;
  -ms-flex-align: Flex-start;
  -webkit-align-items: Flex-start;
  -moz-align-items: Flex-start;
  align-items: Flex-start;
  position: relative;
  z-index: 2
}

@media only screen and (max-width: 767px) {
  section.OurValuePurpose .ValuePurposeSectionInner {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox {
  width: 50%;
  padding: 80px;
  text-align: center;
  position: relative
}

@media only screen and (max-width: 1024px) {
  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox {
    padding: 60px 30px
  }
}

@media only screen and (max-width: 767px) {
  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox {
    padding: 60px 0;
    width: 100%;
    background-color: #ff6633;
    border-bottom: 2px solid #fff
  }

  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox:before {
    content: '';
    position: absolute;
    top: 0;
    right: -20px;
    left: -20px;
    width: auto;
    height: 100%;
    background-color: #ff6633;
    z-index: -1
  }

  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox:last-child {
    background-color: #f9a03f
  }

  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox:last-child:before {
    background-color: #f9a03f
  }
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .icon {
  margin-bottom: 20px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .icon svg {
  width: 85px;
  height: 85px
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text h3 {
  font-family: "Cambria-Bold";
  font-size: 30px;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 20px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 767px) {
  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text h3 {
    font-size: 26px
  }
}

@media only screen and (max-width: 600px) {
  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text h3 {
    font-size: 24px
  }
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text p {
  font-size: 17px;
  color: #fff;
  margin: 0;
  margin-bottom: 10px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 767px) {
  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text p {
    font-size: 16px
  }
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text ul {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text ul li {
  display: inline-block;
  padding: 0 8px
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text ul li p {
  font-size: 17px;
  color: #fff;
  margin: 0px;
  position: relative;
  padding-left: 8px
}

@media only screen and (max-width: 767px) {
  section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text ul li p {
    font-size: 16px
  }
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox .text ul li p:before {
  content: '';
  position: absolute;
  top: 10px;
  left: -5px;
  width: 6px;
  height: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  background-color: #fff
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox:hover .icon {
  transform: translateY(-15px)
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox:hover .text h3 {
  transform: translateY(-10px)
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox:hover .text p {
  transform: translateY(-5px)
}

section.OurValuePurpose .ValuePurposeSectionInner .SectionBox:hover .text ul {
  transform: translateY(-3px)
}

section.ourJourny {
  padding: 260px 0 80px;
  background: url(https://www.masterpay.pro/../img/building-about.svg) no-repeat;
  background-position: center top;
  background-size: 100%
}

@media only screen and (max-width: 1024px) {
  section.ourJourny {
    padding: 180px 0 80px
  }
}

@media only screen and (max-width: 767px) {
  section.ourJourny {
    background-size: 200%;
    padding: 180px 0 60px
  }
}

section.ourJourny .OurJournySlider {
  margin-top: 60px;
  position: relative
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider {
    margin-top: 40px
  }
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner {
  padding: 30px 15px
}

@media only screen and (max-width: 1024px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner {
    padding: 30px 10px
  }
}

@media only screen and (max-width: 600px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: Flex-start;
    -ms-flex-align: Flex-start;
    -webkit-align-items: Flex-start;
    -moz-align-items: Flex-start;
    align-items: Flex-start
  }
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title {
  padding: 0 10px;
  margin-bottom: 20px
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-icon svg {
  width: 100px;
  height: 100px
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date {
  margin-left: 30px
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date {
    margin-left: 20px
  }
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date p {
  font-size: 26px;
  color: #2b2b2b;
  line-height: 26px;
  margin: 0;
  margin-bottom: 0px
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date p {
    font-size: 22px
  }
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date h3 {
  font-family: "Cambria-Bold";
  font-size: 90px;
  color: #2b2b2b;
  line-height: 90px;
  margin: 0
}

@media only screen and (max-width: 1024px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date h3 {
    font-size: 70px;
    line-height: 70px
  }
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date h3 {
    font-size: 55px;
    line-height: 55px
  }
}

@media only screen and (max-width: 600px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date h3 {
    font-size: 40px;
    line-height: 40px
  }
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Title .jrny-date h3 span {
  color: #d40b2e
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: Column;
  -moz-flex-direction: Column;
  -ms-flex-direction: Column;
  flex-direction: Column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  width: 70%;
  padding-left: 15px;
  text-align: center;
  margin-top: 15px
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Content {
    width: 100%
  }
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Content h3 {
  font-family: "Cambria-Bold";
  font-size: 32px;
  color: #2b2b2b;
  margin-top: 0px;
  margin-bottom: 15px
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Content h3 {
    font-size: 28px
  }
}

@media only screen and (max-width: 600px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Content h3 {
    font-size: 22px
  }
}

section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Content p {
  font-size: 20px;
  color: rgba(43, 43, 43, 0.7);
  margin: 0
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Content p {
    font-size: 18px
  }
}

@media only screen and (max-width: 600px) {
  section.ourJourny .OurJournySlider .OurJnr-Content .swiper-slide .slideInner .Content p {
    font-size: 16px
  }
}

section.ourJourny .OurJournySlider .OurJnr-List {
  position: relative;
  width: 100%;
  margin-top: 40px
}

@media only screen and (max-width: 600px) {
  section.ourJourny .OurJournySlider .OurJnr-List {
    margin-top: 10px
  }
}

section.ourJourny .OurJournySlider .OurJnr-List:before {
  content: '';
  position: absolute;
  top: 20px;
  width: 100%;
  height: 3px;
  background-color: #aeacac
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper {
  width: 100%;
  padding-top: 11px
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper.transformNone {
  transform: none !important
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide {
  width: 6%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: Column;
  -moz-flex-direction: Column;
  -ms-flex-direction: Column;
  flex-direction: Column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 0 7px
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide {
    width: 100px
  }
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide .cirl {
  margin: 0 auto 15px auto;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  border: 1px solid #cfcfcf;
  background-color: #fff
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide .cirl:after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #aeacac;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide p {
  font-size: 18px;
  color: #aeacac;
  margin: 0;
  text-align: center
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide p span {
  display: block
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide.swiper-slide-active .cirl {
  border-color: #d40b2e
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide.swiper-slide-active .cirl:after {
  background-color: #d40b2e
}

section.ourJourny .OurJournySlider .OurJnr-List .swiper-wrapper .swiper-slide.swiper-slide-active p {
  color: #d40b2e
}

section.ourJourny .OurJournySlider .SliderArrow {
  margin-top: 60px
}

section.ourJourny .OurJournySlider .SliderArrow .ArrowSlide {
  width: 66px;
  height: 66px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  border: 1px solid #d40b2e;
  padding: 10px !important;
  outline: none;
  margin: 0 25px;
  position: relative;
  top: auto;
  background-image: none
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .SliderArrow .ArrowSlide {
    width: 45px;
    height: 45px
  }
}

@media only screen and (max-width: 767px) {
  section.ourJourny .OurJournySlider .SliderArrow .ArrowSlide svg {
    transform: scale(0.7)
  }
}

section.ourJourny .OurJournySlider .SliderArrow .ArrowSlide svg path {
  fill: #d40b2e
}

section.ourJourny .OurJournySlider .SliderArrow .ArrowSlide.swiper-button-disabled {
  border-color: #cfcfcf
}

section.ourJourny .OurJournySlider .SliderArrow .ArrowSlide.swiper-button-disabled svg path {
  fill: #cfcfcf
}

section.OurScoreCard {
  padding: 80px 0;
  background-color: #f6f6f6
}

section.OurScoreCard .ScoreCardInner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: Flex-start;
  -ms-flex-align: Flex-start;
  -webkit-align-items: Flex-start;
  -moz-align-items: Flex-start;
  align-items: Flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  margin-top: 60px
}

@media only screen and (max-width: 1024px) {
  section.OurScoreCard .ScoreCardInner {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

section.OurScoreCard .ScoreCardInner .item {
  flex-basis: 16.67%;
  max-width: 16.67%;
  padding: 10px 15px
}

@media only screen and (max-width: 1024px) {
  section.OurScoreCard .ScoreCardInner .item {
    flex-basis: 33.333%;
    max-width: 33.33%;
    margin-bottom: 20px
  }
}

@media only screen and (max-width: 600px) {
  section.OurScoreCard .ScoreCardInner .item {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 20px
  }
}

section.OurScoreCard .ScoreCardInner .item .itemInner {
  text-align: center
}

section.OurScoreCard .ScoreCardInner .item .itemInner .icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

section.OurScoreCard .ScoreCardInner .item .itemInner .icon svg {
  width: 62px;
  height: 62px
}

@media only screen and (max-width: 1024px) {
  section.OurScoreCard .ScoreCardInner .item .itemInner .icon svg {
    width: 55px;
    height: 55px
  }
}

@media only screen and (max-width: 600px) {
  section.OurScoreCard .ScoreCardInner .item .itemInner .icon svg {
    width: 50px;
    height: 50px
  }
}

section.OurScoreCard .ScoreCardInner .item .itemInner h3 {
  font-family: "Cambria-Regular";
  font-size: 45px;
  color: #000;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 1400px) {
  section.OurScoreCard .ScoreCardInner .item .itemInner h3 {
    font-size: 40px
  }
}

@media only screen and (max-width: 1024px) {
  section.OurScoreCard .ScoreCardInner .item .itemInner h3 {
    font-size: 36px;
    margin-bottom: 6px
  }
}

@media only screen and (max-width: 767px) {
  section.OurScoreCard .ScoreCardInner .item .itemInner h3 {
    font-size: 29px
  }
}

@media only screen and (max-width: 600px) {
  section.OurScoreCard .ScoreCardInner .item .itemInner h3 {
    font-size: 24px
  }
}

section.OurScoreCard .ScoreCardInner .item .itemInner p {
  font-size: 18px;
  color: #2b2b2b;
  text-align: center;
  margin: 0;
  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

@media only screen and (max-width: 767px) {
  section.OurScoreCard .ScoreCardInner .item .itemInner p {
    font-size: 16px
  }
}

@media only screen and (max-width: 600px) {
  section.OurScoreCard .ScoreCardInner .item .itemInner p {
    font-size: 14px
  }
}

section.OurScoreCard .ScoreCardInner .item .itemInner:hover .icon {
  transform: translateY(-15px)
}

section.OurScoreCard .ScoreCardInner .item .itemInner:hover h3 {
  transform: translateY(-10px)
}

section.OurScoreCard .ScoreCardInner .item .itemInner:hover p {
  transform: translateY(-5px)
}

@media only screen and (max-width: 600px) {
  .ContainerMain {
    padding: 40px 0
  }
}

.cms-content {
  padding-bottom: 70px;
  position: relative;
  text-align: justify
}

@media only screen and (max-width: 600px) {
  .cms-content {
    padding-top: 00px
  }
}

.cms-content::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  width: 100px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.4)
}

.cms-content h1 {
  font-family: "Cambria-Regular";
  font-size: 40px;
  color: #000;
  margin-bottom: 50px;
  text-align: center
}

@media only screen and (max-width: 600px) {
  .cms-content h1 {
    font-size: 36px;
    margin-bottom: 30px
  }
}

.cms-content h3 {
  font-family: "Cambria-Bold";
  font-size: 22px;
  color: #000;
  margin: 40px 0 20px 0
}

@media only screen and (max-width: 600px) {
  .cms-content h3 {
    font-size: 20px
  }
}

.cms-content h4 {
  font-family: "Cambria-Bold";
  font-size: 20px;
  color: #2b2b2b;
  font-weight: 500;
  margin: 30px 0
}

@media only screen and (max-width: 600px) {
  .cms-content h4 {
    font-size: 18px
  }
}

.cms-content h5 {
  font-size: 17px;
  color: rgba(43, 43, 43, 0.8);
  font-weight: 500;
  margin: 20px 0
}

@media only screen and (max-width: 600px) {
  .cms-content h5 {
    font-size: 16px
  }
}

.cms-content p {
  font-size: 17px;
  color: rgba(43, 43, 43, 0.8);
  line-height: 30px;
  font-weight: 500;
  margin: 20px 0
}

@media only screen and (max-width: 600px) {
  .cms-content p {
    font-size: 15px;
    line-height: 24px
  }
}

.cms-content ul {
  margin: 0;
  padding: 0
}

.cms-content ul li {
  font-size: 17px;
  color: rgba(43, 43, 43, 0.8);
  line-height: 30px;
  font-weight: 500;
  margin: 20px 0;
  padding-left: 20px;
  position: relative
}

@media only screen and (max-width: 600px) {
  .cms-content ul li {
    font-size: 15px;
    line-height: 24px
  }
}

.cms-content ul li::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  width: 6px;
  height: 6px;
  background: #d40b2e;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%
}

.cms-content ol {
  margin: 0;
  padding: 0;
  counter-reset: item
}

.cms-content ol>li {
  font-size: 17px;
  color: rgba(43, 43, 43, 0.8);
  line-height: 30px;
  font-weight: 500;
  margin: 20px 0;
  padding-left: 25px;
  position: relative;
  list-style: none;
  display: block
}

@media only screen and (max-width: 600px) {
  .cms-content ol>li {
    font-size: 15px;
    line-height: 24px
  }
}

.cms-content ol>li::before {
  content: counter(item) ". ";
  counter-increment: item;
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "Cambria-Bold";
  font-size: 22px;
  color: #d40b2e
}

.tableui {
  border: 1px solid #ccc;
  background: #fff;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
  min-width: 1000px
}

.tableui thead tr td,
.tableui tbody tr td {
  padding: 12px
}

.tableui thead tr td {
  font-weight: 600;
  border-bottom: 2px solid #ccc;
  border-left: 1px solid #ccc
}

.tableui thead tr td:first-child {
  border-left: none
}

.tableui tbody tr:nth-child(odd) {
  background: #f7f3f3
}

.tableui tbody tr:nth-child(even) {
  background: #ffffff
}

.tableui tbody tr td {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc
}

.tableui tbody tr td:first-child {
  border-left: none
}

.nowrap {
  white-space: nowrap
}

#SigninMain {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: none;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s
}

/* .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6)
} */

.PopupMain {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto
}

.PopupMain .PopupCenter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
  max-width: 730px;
  margin: 1.75rem auto;
  min-height: calc(100% - (1.75rem * 2));
  transform: translate(0, 0)
}

@media only screen and (max-width: 767px) {
  .PopupMain .PopupCenter {
    width: 94%
  }
}

.PopupMain .PopupCenter .PopupBox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: nowrap;
  position: relative;
  width: 100%;
  background-clip: padding-box;
  border: 10px solid rgba(255, 255, 255, 0.2);
  outline: 0;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.4)
}

@media only screen and (max-width: 767px) {
  .PopupMain .PopupCenter .PopupBox {
    overflow: hidden
  }
}

.PopupMain .PopupCenter .PopupBox .InnerBox {
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid rgba(255, 255, 255, 0);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: nowrap
}

@media only screen and (max-width: 600px) {
  .PopupMain .PopupCenter .PopupBox .InnerBox {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.PopupMain .PopupCenter .PopupBox .CloseIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  background: #d40b2e;
  z-index: 99
}

@media only screen and (max-width: 767px) {
  .PopupMain .PopupCenter .PopupBox .CloseIcon {
    top: 15px;
    right: 15px
  }
}

.PopupMain .PopupCenter .PopupBox .CloseIcon svg {
  width: 9px;
  height: 9px
}

.PopupMain .PopupCenter .PopupBox .CloseIcon svg path {
  fill: #fff
}

.PopupMain .PopupCenter .PopupBox .PopupLeft {
  position: relative;
  z-index: 1;
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  padding: 20px 30px;
  text-align: left
}

@media only screen and (max-width: 767px) {
  .PopupMain .PopupCenter .PopupBox .PopupLeft {
    padding: 20px 20px;
    width: 60%
  }
}

@media only screen and (max-width: 600px) {
  .PopupMain .PopupCenter .PopupBox .PopupLeft {
    padding: 20px 30px;
    width: 100%
  }
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalLogo {
  width: 100%;
  margin-top: 20px
}

@media only screen and (max-width: 600px) {
  .PopupMain .PopupCenter .PopupBox .PopupLeft .ModalLogo {
    text-align: center;
    margin-top: 30px
  }
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalLogo svg {
  width: 200px
}

.PopupMain .PopupCenter .PopupBox .PopupLeft h3 {
  font-family: "Cambria-Regular";
  font-size: 24px;
  color: #2b2b2b;
  margin: 10px 0 20px;
  width: 100%
}

@media only screen and (max-width: 600px) {
  .PopupMain .PopupCenter .PopupBox .PopupLeft h3 {
    text-align: center
  }
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm {
  width: 100%
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm input:-webkit-autofill,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm input:-webkit-autofill:hover,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm input:-webkit-autofill:focus,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm textarea:-webkit-autofill,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm textarea:-webkit-autofill:hover,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm textarea:-webkit-autofill:focus,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm select:-webkit-autofill,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm select:-webkit-autofill:hover,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm select:-webkit-autofill:focus {
  -webkit-text-fill-color: #2b2b2b;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group {
  position: relative
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group span {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  margin: 0
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group span svg {
  width: 18px;
  height: 18px
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group span svg path {
  fill: #2b2b2b
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group .form-control {
  height: 52px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
  padding-left: 50px;
  font-size: 18px;
  color: #2b2b2b
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group .form-control:focus {
  border-color: #d40b2e;
  box-shadow: none
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group .form-control:focus+label svg path,
.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group .form-control:focus span svg path {
  fill: #d40b2e
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group .form-control.error {
  border-color: #ff5f5f
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .form-group .form-control.error+.error {
  font-size: 12px;
  font-weight: 500;
  padding-top: 2px;
  color: #ff5f5f;
  position: absolute;
  margin: 0
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .FormButtons {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .FormButtons .forgotlink {
  font-size: 14px;
  color: #d40b2e;
  margin-bottom: 20px
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ModalForm .FormButtons .button {
  width: 100%;
  padding: 12px 15px;
  white-space: nowrap
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .BottomLine {
  margin-top: 30px;
  width: 100%;
  flex-shrink: 0;
  font-size: 14px;
  color: #2b2b2b;
  font-weight: 400
}

@media only screen and (max-width: 600px) {
  .PopupMain .PopupCenter .PopupBox .PopupLeft .BottomLine {
    text-align: center
  }
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .BottomLine a {
  color: #d40b2e;
  text-decoration: underline
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .BottomLine a:hover {
  text-decoration: none
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ForgotPasswordBox .ModalForm {
  height: 224px
}

.PopupMain .PopupCenter .PopupBox .PopupLeft .ForgotPasswordBox .BottomLine {
  text-align: center
}

.PopupMain .PopupCenter .PopupBox .PopupRight {
  position: relative;
  z-index: 1;
  width: 50%;
  height: 100%;
  background: url(https://www.masterpay.pro/../img/signIn-img.png) no-repeat;
  background-position: left;
  background-size: cover;
  overflow: hidden
}

@media only screen and (max-width: 767px) {
  .PopupMain .PopupCenter .PopupBox .PopupRight {
    width: 40%
  }
}

@media only screen and (max-width: 600px) {
  .PopupMain .PopupCenter .PopupBox .PopupRight {
    display: none
  }
}

.PopupMain .PopupCenter .PopupBox .PopupRight:before {
  content: '';
  width: 100px;
  height: 140%;
  background-color: #fff;
  position: absolute;
  top: -10%;
  left: -70px;
  transform: rotate(6deg)
}


.BannerMain-2 {
  margin-top: 0px !important;
}

.FooterBottom {
  background-color: #0b2541;
}

.LayoutMain .BodyArea .SidebarMain {
  width: 224px;
}

/* aside.SidebarMain .MenuItem.active {
  position: relative;
  background: rgba(0, 0, 0, .3);
} */

aside.SidebarMain .MenuItem {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  position: relative;
}

aside.SidebarMain .MenuItem.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: #1e5591;
}

aside.SidebarMain .MenuItem::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background: rgba(255, 255, 255, .02);
}

aside.SidebarMain .MenuItem .card-header {
  padding: 0;
  border: none;
  background: none;
}

aside.SidebarMain .MenuItem .card-header {
  position: relative;
  overflow: hidden;
}

aside.SidebarMain .MenuItem.active .card-header a {
  color: #fff;
}

aside.SidebarMain .MenuItem .card-header a {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  padding: 17px;
}

aside.SidebarMain .MenuItem .card-header a,
.HeaderRight .RightActions .NotificationsMain>a,
.HeaderRight,
.UserDetails .UserInfo {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

header.HeaderMain,
table.dataTable>tbody>tr.child ul.dtr-details>li,
header.HeaderMain .HeaderContainer,
.SidebarTriggerMobile .TriggerButton,
.HeaderRight .RightActions .NotificationsMain .dropdown-menu ul li.all-link a,
.HeaderRight .RightActions .NotificationsMain .dropdown-menu ul li,
.HeaderRight .RightActions,
.HeaderRight .UserWallet,
.UserDetails .UserDropdown ul li a,
.dropdown-menu ul li a,
.optr-td,
.dtr-details .dtr-data,
.top-link-ic,
aside.SidebarMain .MenuItem .card-header a,
.HeaderRight .RightActions .NotificationsMain>a,
.HeaderRight,
.UserDetails .UserInfo,
.LayoutMain .BodyArea {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

aside.SidebarMain .MenuItem .card-header a>i {
  margin-right: 10px;
}

aside.SidebarMain .MenuItem .card-header a>i svg {
  width: 15px;
  height: 15px;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

aside.SidebarMain .MenuItem .card-header a>span {
  flex: 2;
}

aside.SidebarMain {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
}

aside.SidebarMain .MenuItem .card-header a {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  padding: 17px;
}

aside.SidebarMain .MenuItem .card-header a>i svg path {
  fill: #000;
}

.new-label {
  color: #fff;
  padding: 0 14px;
  background-color: #1e5591;
  display: inline-block;
  transform: rotate(-45deg);
  padding-top: 9px;
  position: absolute;
  left: -18px;
  top: -6px;
  animation: changeBackgroundColor 1s infinite;
}

.new-label span {
  font-size: 8px;
  line-height: 14px;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  z-index: 99;
}

.LayoutMain header.HeaderMain {
  flex-shrink: 0;
  height: 50px;
}

header.HeaderMain {
  width: 100%;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, .05);
  position: relative;
  z-index: 99;
}

header.HeaderMain .logo {
  /* width: 215px; */
  width: 15rem;
  height: 100%;
  /* background: #1e5591; */
}

header.HeaderMain .logo svg {
  width: 200px;
  height: 60px;
}

header.HeaderMain .HeaderContainer {
  flex: 2;
}

header.HeaderMain .HeaderContainer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  /* justify-content: space-between; */
}

.UserDetails {
  height: 100%;
}

.UserDetails .UserInfo {
  margin: 0 10px;
  cursor: pointer;
  height: 100%;
}

.SidebarTriggerMobile svg path {
  fill: #004085 !important;
}

.UserDetails .UserInfo .UserPic {
  width: 36px;
  height: 36px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  font-size: 15px;
  color: #d5d5d5;
  font-weight: 400;
  background: #fbfbfb;
  border: 1px solid #e4e4e4;
  margin-right: 7px;
}

.HeaderRight {
  padding-right: 20px;
}

aside.SidebarMain .MenuItem .card-header a,
.HeaderRight .RightActions .NotificationsMain>a,
.HeaderRight,
.UserDetails .UserInfo {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  /* align-items: center; */
  /* -webkit-box-pack: center; */
  -ms-flex-pack: center;
  /* -webkit-justify-content: center; */
  -moz-justify-content: center;
  /* justify-content: center; */
}

.headerQr {
  text-align: center;
  height: 38px;
  margin-right: 10px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.tab-lable {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #d16666;
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 8px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  opacity: .9;
}

.new-service-blinking {
  background: #ff0000;
  animation-name: ServiceBlinking;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes ServiceBlinking {
  0% {
    opacity: 1
  }

  80% {
    opacity: 0
  }
}

.headerQr {
  text-align: center;
  height: 58px;
  margin-right: 10px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.headerQr .tab-lable {
  top: 0;
  right: 0;
}

.headerQr a>span {
  display: block;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
}

.headerQr svg {
  height: 25px;
  display: inline-block;
  margin-bottom: 5px;
}

.generateQr {
  position: relative;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  padding: 10px;
  font-weight: 500;
}

.generateQr>a {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generateQr>a i svg {
  width: 28px;
  height: 28px;
}

.generateQr>a i svg path,
.generateQr>a i svg rect,
.generateQr>a i svg polygon {
  fill: #555;
}

.generateQr>a div {
  flex: 2;
  margin-left: 10px;
}

.generateQr>a span {
  display: block;
  font-size: 12px;
  color: #000;
}

.generateQr .tab-lable {
  top: 0;
  right: 0;
}

.new-service-blinking {
  background: #ff0000;
  animation-name: ServiceBlinking;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.HeaderRight .UserWallet {
  /* min-height: 57px; */
}

.HeaderRight .UserWallet {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 5px 10px;
}

.HeaderRight .UserWallet i {
  margin-right: 10px;
}

.HeaderRight .UserWallet i svg {
  width: 28px;
  height: 28px;
}

.SidebarTriggerMobile .TriggerButton,
.HeaderRight .RightActions .NotificationsMain .dropdown-menu ul li.all-link a,
.HeaderRight .RightActions .NotificationsMain .dropdown-menu ul li,
.HeaderRight .RightActions,
.HeaderRight .UserWallet,
.UserDetails .UserDropdown ul li a,
.dropdown-menu ul li a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

header.HeaderMain,
table.dataTable>tbody>tr.child ul.dtr-details>li,
header.HeaderMain .HeaderContainer,
.SidebarTriggerMobile .TriggerButton,
.HeaderRight .RightActions .NotificationsMain .dropdown-menu ul li.all-link a,
.HeaderRight .RightActions .NotificationsMain .dropdown-menu ul li,
.HeaderRight .RightActions,
.HeaderRight .UserWallet,
.UserDetails .UserDropdown ul li a,
.dropdown-menu ul li a,
.optr-td,
.dtr-details .dtr-data,
.top-link-ic,
aside.SidebarMain .MenuItem .card-header a,
.HeaderRight .RightActions .NotificationsMain>a,
.HeaderRight,
.UserDetails .UserInfo,
.LayoutMain .BodyArea {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.HeaderRight .UserWallet .WalletDetails {
  flex: 2;
}

.HeaderRight .UserWallet .WalletDetails h3 {
  font-size: 13px;
  color: #464646;
  font-weight: 400;
  margin: 0;
}

.HeaderRight .UserWallet .WalletDetails h2 {
  font-size: 17px;
  color: #1e5591;
  font-weight: 300;
  margin: 0;
}

.UserWallet {
  height: 40px !important;
}


.mm-show {
  display: block !important;
}

.extra {
  display: none !important;
}

.walletDropdown ul li a svg {
  font-size: 30px;
}

.LayoutMain .BodyArea .ContainerMain .ContentArea {
  padding: 30px;
}

.LayoutMain .BodyArea {
  flex: 1 0 auto;
  height: calc(100vh - 50px);
  overflow: auto;
}

.paytm-charges h3 {
  font-size: 15px;
  font-weight: 700;
  color: #1e5591;
}

.PageHeading {
  background: #ffff;
  padding: 20px 30px;
}

.PageHeading h1 {
  font-size: 24px;
  color: #1e1e1e;
  font-weight: 300;
}

/* .BannerSlider .slick-slider {
  width: 1199px !important;
} */

.BannerSlider {
  padding: 1.2rem;
  padding-bottom: 0;
}

.BannerSlider .slick-slider .slick-prev,
.slick-next {
  display: none !important;
}

.top-services .service-block.firstblock {
  background-image: -webkit-linear-gradient(135deg, rgb(126, 56, 150) 0%, rgb(136, 65, 160) 100%);
}

.top-services .service-block.secondblock {
  background-image: -webkit-linear-gradient(135deg, rgb(72, 50, 144) 0%, rgb(85, 62, 159) 100%);
}

.top-services .service-block.fifthblock {
  background: #ab012e;
}

.top-services .service-block.forthblock {
  background-image: -webkit-linear-gradient(135deg, rgb(22, 149, 206) 0%, rgb(33, 161, 219) 100%);
}

#ServicesTab.nav-tabs {
  background: #f8f8f8;
  padding: 0 1.2rem;
}

.link-main {
  padding: 0px;
  display: flex;
  align-items: center;
}

.link-main .nav-Item-sec {
  flex-grow: 1;
  text-align: center;
  flex-basis: 0;
}

.link-main .nav-Item-sec a {
  color: black;
  font-size: 12px;
  text-decoration: none;
  height: 100%;
  border: none;
  font-size: 13px;
  color: #1e1e1e;
  font-weight: 500;
}

.ServicesParent #ServicesTab {
  background: #f8f8f8;
}

.ServicesParent {
  background: #fff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, .05);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 20px;
}

.nav-Item-sec a div svg {
  font-size: 25px;
}

#ServicesTab {
  background: #f8f8f8;
}

.mCustomScrollbar {
  position: sticky !important;
  top: 0px;
}

.tab-pane {
  padding: 0px 30px;
}

.row-row {
  align-items: center;
}

.block-seprator2 {
  margin-top: 30px !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}

.heading {
  padding: 15px 0;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background-color: #1e5591;
  text-align: center;
}

.roundblock {
  width: 100%;
  border: 1px solid #d1d1d1;
  padding: 20px 10px 7px 10px;
  position: relative;
  border-radius: 10px;
}

.roundblock .blocklegend {
  position: absolute;
  left: 10px;
  top: -11px;
  background: #fff;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1e5591;
}

.roundblock .legendcontainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

aside.SidebarMain .MenuItem .SubmenuItems ul li a {
  display: block;
  position: relative;
  padding: 10px 10px 10px 60px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}

.SubmenuItems ul {
  padding: 0px !important;
}

aside.SidebarMain .MenuItem .SubmenuItems ul li a::before {
  content: "";
  position: absolute;
  top: 19px;
  left: 40px;
  width: 6px;
  height: 6px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background: #000;
}

/* .hide-sidebar {
  margin-left: -300px !important;
} */

@media (min-width: 769px) {
  aside.hide-sidebar {
    margin-left: -223px !important;

  }

  .BannerSlider .slick-slider {
    width: 100% !important;
  }
}

.LayoutMain .BodyArea .ContainerMain {
  flex: 2;
  height: 100%;
  overflow: auto;
}

.aeps-block {
  background: #f9f9f9;
  border: 1px solid #e8e8e8;
  min-height: 250px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  padding: 20px;
}

#rechargehistorytbl {
  width: 100% !important;
}

.HeaderContainer {
  background: #000;
}

.invalid {
  border: 1px solid red !important;
}

.valid {
  border: 1px solid green !important;
}


/* .LayoutMain .BodyArea .ContainerMain {
  flex: 2;
  height: 100%;
  overflow: auto;
} */

/*# sourceMappingURL=main.css.map */


.LayoutMain .BodyArea .ContainerMain .ContentArea {
  padding: 30px;
}

.MerchantLoginAreaBanner {
  /* background-image: url('../asesets/banner/Rectangle\ 168.png'); */
  /* background-color: rgba(248, 247, 216, 0.7); */
  background-color: #2E3191;
  /* width: 1920px; */
  height: 299px;
  top: 138px;
}

.buttonArea .login-area {
  width: 103px;
  height: 37px;
  border: 1px solid #8BC540;
  border-radius: 1111px;
  color: #8BC540;
}

.buttonArea .sign-area {
  width: 103px;
  height: 37px;
  border-radius: 1111px;
  background-color: #2E3191;
  color: #fff;
}

.header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-banner h1 {
  color: #fff;
  top: 112px;
  position: absolute;
}

.position-reative {
  position: relative;
}

.login-area-sec {
  /* width: 680px; */
  margin: auto;
  margin-bottom: 5%;
}

.email-mobile-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileitab {
  width: 205px;
  height: 40px;
  border-radius: 10px;
  background-color: #2E3191;
  color: #fff;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.emailtab {
  width: 205px;
  height: 40px;
  border-radius: 10px;
  /* background-color: #2E3191; */
  border: 1px solid #8BC540;
  /* color: #fff; */
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.mobile-login-phone {
  margin-top: 20px;
}

.input-group-text svg {
  color: #8BC540;
}

.btn-login {
  width: 100%;
  background: -webkit-linear-gradient(right, #2227cd, #a1f733, #1015bf, #a5ef44);
  padding: 10px;
  text-align: center;
  border-radius: 13px !important;
  color: #fff !important;
  font-weight: 700 !important;
  border: none !important;
  height: 40px !important;
}

.disableebuttoncls {
  width: 100% !important;
  cursor: not-allowed !important;
  background-color: #d3d3d3 !important;
  color: #6c757d !important;
  border-color: #d3d3d3 !important;
  padding: 10px !important;
  text-align: center !important;
  border-radius: 13px !important;
  color: #fff !important;
  font-weight: 700 !important;

  height: 40px !important;

}

.commonbotton_disable {

  cursor: not-allowed !important;
  background-color: #d3d3d3 !important;
  color: #6c757d !important;
  border: 0 !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-center a {
  color: black;
}

.login-area-sec h1 {
  font-weight: 900;
  font-size: 39px;
  line-height: 55px;
}

.login-area-regis {
  text-align: center;
}

.login-area-regis>h4 {
  font-size: 33px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;

}

.login-area-regis>div {
  display: flex;
  justify-content: center;
}

.login-area-regis>div>p {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 416px;
  color: grey;
}

.firstFor {
  width: 650px;
  margin: 30px auto;
}

.pancars {
  color: #2E3191;

}

.pancars>label {
  margin: 0;
}

.pancars>div {
  color: #9F9F9F;
}

.clrelative {
  position: relative;
}

.clrelative>input {
  padding: 0 26px;
}

.clApsulute {
  position: absolute;
  top: 12px;
  color: #8BC540;
  font-size: 19px;
  left: 17px;
}


.ant-upload {
  width: 100% !important;
}

.kycCall {
  width: 280px;
  height: 170px;
  border: 2px solid #8BC540;
  border-radius: 7px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.kycicon {
  font-size: 55px;
}

.perakyc {
  width: 416px;
  margin: auto;
  display: flex;
  text-align: center;
  font-size: 19px;
}

.mainant>span>div>div {
  width: 100%;
}

.varifyd {
  font-size: 13px;
  color: green;
  font-weight: 600;
}

.laste {
  font-size: 17px;
  margin-top: 7px;
  margin-bottom: 15px;
}

.input-group-text-2 {
  padding: 0px !important;
}

.nav-link {
  padding-left: var(--bs-navbar-nav-link-padding-x);
  padding-right: var(--bs-navbar-nav-link-padding-x);
}

.nav-link {
  background: none;
  border: 0;
  color: var(--bs-nav-link-color);
  display: block;
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.align-items-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
}

.header-right .nav-item .nav-link {
  color: #fff;
  font-size: 1.125rem;
}

.header-media {
  /* height: 1.875rem; */
  margin-right: 7px;
  /* width: 1.875rem; */
}

.header-media img {
  border-radius: 100%;
  width: 100%;
  width: 40px;
  height: 40px;
}

.header-info h6 {
  font-size: .813rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 4px;
}

.header-info p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 4px;
  color: #fff;
}

.dropdown-menu-2 {
  display: block !important;
  right: -18px !important;
  top: 116% !important;
}

.dropdown-menu-2 {
  position: absolute;
}

.dropdown-menu-2 {
  border-radius: 0 0 0.375rem 0.375rem;
  margin: 0;
  width: 275px;
}

.card {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0.375rem;
  height: calc(100% - 25px);
  margin-bottom: 1.563rem;
  position: relative;
  transition: all .5s ease-in-out;
}

.card-header {
  align-items: center;
  background: #0000;
  border-color: #e6e6e6;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.25rem 1.25rem;
  position: relative;
}

.products {
  align-items: center;
  display: flex;
}

.avatar.avatar-md {
  height: 2.813rem;
  width: 2.813rem;
  border-radius: 100%;
}

.products img {
  margin-right: 10px;
}

.avatar {
  border-radius: 0.375rem;
  display: inline-block;
  height: 1.875rem;
  object-fit: cover;
  position: relative;
  width: 1.875rem;
}

.products h6 {
  font-size: 14px;
  margin-bottom: 0;
  white-space: nowrap;
}

.sidebar-right {
  background-color: #fff;
  border-radius: 0.375rem;
  margin-top: 3.5rem;
  position: fixed;
  right: -50rem;
  top: 0;
  transition: all .5s ease-in-out;
  width: 50rem;
  z-index: 9999;
}

.sidebar-right-trigger {
  background-color: #0d99ff;
  background-color: #2c2c2c;
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0 #77777726;
  color: #fff;
  display: inline-block;
  font-size: 1.75rem;
  height: 3rem;
  line-height: 3rem;
  position: absolute;
  right: 100%;
  text-align: center;
  top: 5.75rem;
  width: 3rem;
  z-index: 9;
}

.wave-effect-x svg {
  margin-bottom: 8px;
}

.dilog-class {
  height: 500px !important;
  margin: auto !important;
  width: 500px !important;
  z-index: 11111;
}

.them h3 {
  font-size: 19px;
}

.color-picker-container input {
  border-radius: 5%;
  height: 30px;
  width: 60%;
}

.btn-danger-2 {
  margin-bottom: 20px;
  margin-left: 5px !important;
}

.active-projects h3 {
  font-size: 19px;
}

.dt-button-2 {
  background: #4ee021 !important;
  color: #fff !important;
  font-weight: 900;
  width: 23%;
  margin-right: 12px;
}

.ContentAreaa {
  padding-top: 0px !important;
}

.customerApi {
  border: 2px dotted #161984;
  padding: 26px;
  width: 100%;
  border-radius: 10px;
}

.customerName,
.accountStatus,
.Avilavel-limit {
  font-weight: 700;
}

.Beneficiary-List {
  border: 2px dotted #161984;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}

.BeneficiaryHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #161984;
}

.bank-icon svg {
  font-size: 60px;

}

.banckCustomerName {
  font-weight: 500;
}

.back-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.customer-area,
.account-area {
  margin-bottom: 10px;
}

.banck-sec {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  padding: 6px;
}

.Beneficiary-List a {
  color: #000;
}

.Beneficiary-List a:hover {
  text-decoration: none;
}

.modal-dialog-modal .modal-dialog {
  width: 500px !important;
}

.modal-dialog-modal .modal-dialog .modal-content .modal-header {
  display: block;
}

.head-modal h2 {
  font-size: 20px;
  color: #161984;
  font-weight: 800;
}

.head-modal p {
  color: greenyellow;
  font-weight: 600;
  font-size: 20px;
}

.send-money-name,
.send-money-Mobile,
.send-money-accNum,
.send-money-bnkName {
  display: flex;

  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.send-money-name h2,
.send-money-Mobile h2,
.send-money-accNum h2,
.send-money-bnkName h2 {
  font-size: 17px;
}

.send-money-name p,
.send-money-Mobile p,
.send-money-accNum p,
.send-money-bnkName p {
  margin: 0px;
}

.selctMode {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check-input-2 {
  color: #000;
  border: 1px solid #000;
  width: 20px;
  height: 20px;
}

.text-align-label {
  text-align: center;
  display: block;
}

.text-align-label-2 {
  color: green;
}

.btn-cnf {
  width: 100%;
}

.modalNotExit .modal-dialog {
  width: 350px;
}

.icon-set svg {
  font-size: 70px;
}

/* .icon-set {
  width: 100px;
} */

/* .icon-set::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 80px;
  height: 80px;
  border: solid 2px #000;
  transform: translateX(-50%);
  animation: border_anim 3s linear forwards;
}

@keyframes border_anim {
  0% {
    width: 80px;
  }

  100% {
    width: 100%;
  }
} */
.SecTitle-2 {
  margin: 0px;
  padding-bottom: 15px;
  /* max-width: 0px; */
  /* position: relative; */
  text-align: center;
}

.otp-customer .modal-dialog {
  width: 400px;
}

.banckCustomer-delete svg {
  font-size: 50px;
  cursor: pointer;
}

.dataTables_info_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

table thead tr .sorting:after {
  content: "\f0dc";
  opacity: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background-image: none;
  margin-left: 10px;
  font-size: calc(100% - 4px);
}

/* table thead tr .sorting::before {
  content: "\f0dd";
  opacity: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background-image: none;
  margin-left: 10px;
  font-size: calc(100% - 4px);
} */
.total-txn {
  padding: 25px;
  background-color: #2e3191;
  border-radius: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.font-extrabold {
  font-weight: 800;
  background-color: #ffffff42;
  border-radius: 9999px;
}

.transition-all {
  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.total-txn span svg {
  font-size: 23px;
}

.money_in {
  background-color: #80cb1f;
}

.Money-out {
  background-color: #66209b;
}

.project-names h6 {
  /* width: 38px;
  height: 38px; */
  border-radius: 50%;
  color: #fff;
  line-height: 2.5;
  margin-bottom: 0;
  font-weight: 600;
  padding: 3px;
  background-color: #d9e8fe !important;
  color: #3858f9 !important;
  width: 100%;
}

.badge-2 {
  margin-top: 12px;
  box-shadow: 0 7px 30px rgba(11, 163, 96, 0.3);
}

.card-card {
  height: 100% !important;
}

/* .active {
  background-color: #004085 !important;
  color: #fff !important;
} */

.profile-container {
  position: relative;
  display: inline-block;
}

.profile-image {
  width: 150px;
  /* Adjust according to your needs */
  height: 150px;
  /* Adjust according to your needs */
  border-radius: 50%;
}

.edit-options {
  position: absolute;
  /* bottom: 0; */
  top: 67px;
  right: 59px;
  transform: translate(50%, 50%);
}

.edit-icon {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 8px 12px 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-icon:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.edit-icon i {
  color: white;
}

.edit-options>.fa-pencil-alt {
  font-size: 20px;
}

.profile-container img {
  width: 125px;
  border-radius: 100%;
}

.billing-address-set {
  display: flex;
  align-items: center;
  justify-content: center;
}

.editBoxCanten {
  position: relative;
}

.editBox {
  font-size: 28px;
  position: absolute;
  top: 40px;
  right: 3px;
  cursor: pointer;
}

.perentEye {
  position: relative;
  cursor: pointer;
  height: 185px;
  overflow: hidden;
  border: 2px solid green;
  margin: 2px;
}

.perentEye img {
  margin: 0 !important;
}

.eyeV {
  position: absolute;
  top: 0;
  /* right: 48%; */
  /* top: 45%; */
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #000000a3;
  align-items: center;
  justify-content: center;
}

.eyeV {
  display: none;
}

.perentEye:hover .eyeV {
  display: flex;
}

.spanNext {
  cursor: pointer;
  color: green;
}

.cusmoal>.modal-dialog {
  max-width: 900px !important;
}

.css-b62m3t-container>.select_menu a {
  color: red !important;
  background-color: white !important;
}

.css-1nmdiq5-menu>div>div {
  background: white;
  color: black;
}

.deletbtnIco {
  padding: 5px;
  border: 1px solid #ff6464;
  border-radius: 8px;
  background-color: #ff6464;
  color: white;
  margin-right: -30px;
}

.deletbtnIco1 {
  margin-right: -8px !important;
}

.button-fit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-center-set {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bankResiter-head h2 {
  text-align: center;
  font-size: 25px !important;
  text-align: center;
  font-weight: 700;
  /* border-bottom: 2px solid green; */
}

.bankResiter {
  text-align: center;
}

.bankResiter button svg {
  font-size: 34px !important;
}

@media (min-width: 600px) and (max-width: 1024px) {

  .login-area-sec {
    margin-left: 7% !important;
  }

}


@media (min-width: 1025px) and (max-width: 1440px) {
  .login-area-sec {
    margin-left: 7%;
  }
}